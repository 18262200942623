<template>
  <div ref="main" :style="{width:mapWidth+'%', height:'100%'}"></div>
</template>

<script>
  import * as echarts from "echarts";
  let myChart;

  export default {
    name: "ChartArea",
    props:{
      allData: {
          type: Array, //接收类型
          required: true, //必填
          default: [] //默认值
      }
    },
    data(){
      return{
          // data: this.allData
      }
    },
    computed:{
      mapWidth(){
        return (this.allData.length/288)*100;
      }
    },
    methods:{
      myEcharts(){
        if(myChart) myChart.dispose();
        // 基于准备好的dom，初始化echarts实例
        myChart = echarts.init(this.$refs.main);

        // 指定图表的配置项和数据
        let option = {
          xAxis: {
            type: 'category',
            show: false, //不显示坐标轴线、坐标轴刻度线和坐标轴上的文字
            data: []
          },
          grid: {
            top: 0,
            bottom: 0,
            right: 0,
            left: 0
          },
          yAxis: {
            axisTick:{
              show: false //不显示坐标轴刻度线
            },
            axisLine: {
              show: false //不显示坐标轴线
            },
            axisLabel: {
              show: false //不显示坐标轴上的文字
            },
            splitLine: {  //网格线
              show: false
            }
          },
          series: [{
            type: 'line',
            data: this.allData,
            itemStyle: {
              color: 'rgba(112,164,54, 0.8)', //改变折线点的颜色
            },
            lineStyle: {
              color: 'rgba(112,164,54, 0.8)' //改变折线颜色
            },
            areaStyle: {
              color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                      offset: 0, color: 'rgba(112,164,54, 0.8)' // 0% 处的颜色
                  }, {
                      offset: 1, color: 'rgba(112,164,54, 0.4)' // 100% 处的颜色
                  }],
                  global: false // 缺省为 false
              }
            }
          }]
        };

        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(option);

        myChart.resize();
      }
    },
    mounted() {
      // this.myEcharts();
    },
    watch: {
      allData(newVal, oldVal) {
        setTimeout(()=>{
          this.myEcharts();
        },2)
      }
    }
  }
</script>

<style>
</style>
