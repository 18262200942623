/*
 * @Author: jixuyu
 * @Date: 2021-09-14 20:49:23
 * @LastEditTime: 2022-09-09 10:58:58
 * @LastEditors: JIXUYU
 * @Description:
 * @FilePath: \front\src\utils\validate.js
 * 记得注释
 */
/**
 * Created by PanJiaChen on 16/11/18.
 */
import i18n from "@/lang";
/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export const validUsername = [
  {
    required: true,
    message: i18n.t('validate.TheInputContentCannotBeEmpty'),
    trigger: ["blur", "change"]
  }
];

export const RulesPower = [
  {
    required: true,
    message: i18n.t('validate.PleaseEnterThePlantName'),
    trigger: ["blur", "change"],
    pattern: /^(?![0-9]+$)(?![` ~!@#$%^&*()\+=<>?:"、{}|,.\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】；‘'，。]+$)/
  }
];
export const RuleCountry = [
  {
    required: true,
    message: i18n.t('validate.PleaseEnterCountry'),
    trigger: ["blur", "change"]
  }
];
export const Ruledistrict = [
 {
   required: true,
   message: i18n.t('validate.PleaseEnterRegion'),
   trigger: ["blur", "change"]
 }
];


export const RuleProvice = [
  {
    required: true,
    message: i18n.t('validate.PLeaseEnterProvince'),
    trigger: ["blur", "change"],
    // pattern: /^[a-zA-Z0-9_\-\u4e00-\u9fa5]+$/
  }
];
export const RuleCity = [
  {
    required: true,
    message: i18n.t('validate.PleaseEnterTheCity'),
    trigger: ["blur", "change"],
    // pattern: /^[a-zA-Z0-9_\-\u4e00-\u9fa5]+$/
  }
];
export const RuleStreet = [
  {
    required: true,
    message: i18n.t('validate.PleaseEnterStreet'),
    trigger: ["blur", "change"],

  }
];
export const RuleCommon = [
  {
    required: true,
    message: i18n.t('validate.TheInputContentCannotBeEmpty'),
    trigger: ["blur", "change"]
  }
];
export const RuleLength20 = [
  {
    required: true,
    min:1,
    max:22,
    message: i18n.t('rules.TheContentCannotExceed20Characters'),
    trigger: ["blur", "change"]
  }
];
export const RulesNum = [
  {
    required: true,
    pattern: /^\d{1,}$/g,
    message: i18n.t('validate.PleaseEnterANumber'),
    trigger: "change"
  }
];
export const RulePowerNum5 = [
  {
    required: true,
    pattern: /^(([1-9]\d*)|([0][.]{1}[0-9]{0,2}[1-9]+)|([1-9]\d*[.]{1}[0-9]+))$/g, //大于0正整数或正小数，如果是小数，小数位前三必须有一个不是0的数
    message: i18n.t('validate.PleaseEnterA5Number'),
    trigger: ["blur", "change"]
  }
];

export const ModulePower = [
  {
    required: true,
    pattern: /^([2-9]\d{2}|1000)$/g, //200-1000
    message: i18n.t('validate.PleaseEnter200to1000Number'),
    trigger: ["blur", "change"]
  }
];

export const RuleManufacturer =[
  {
    required: true,
    min:1,
    max:20,
    message: i18n.t('validate.PleaseEnterManufacturer'),
    trigger: ["blur", "change"]
  }
];

export const RuleElectrovalence=[
  {
    required: true,
    pattern: /^-?\d{1,3}(,\d{3})*(\.\d{1,})?$/,
    message: i18n.t('validate.PleaseEnterANumber'),
    trigger: ["blur", "change"]
  }
];
