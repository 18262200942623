/*
 * @Author: sun.mengmeng
 * @Date: 2021-09-28 14:04:53
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-04-16 13:01:20
 * @Description: file content
 */
import request from "@/utils/request";
//周电量统计
export function getPowerLineData(params) {
 return request({
   url: "/home/getPowerLineData",
   method: "get",
   params: params
 });
}


//周电量统计
export function weekTotal(params) {
  return request({
    url: "/home/getWeekData",
    method: "get",
    params: params
  });
}

//获取首页头部显示统计数据
export function headerTotal(params) {
  return request({
    url: "/home/getHeaderData",
    method: "get",
    params: params
  });
}

//年电量统计
export function yearTotal(params) {
  return request({
    url: "/home/getYearData",
    method: "get",
    params: params
  });
}
//年电量统计
export function MonthTotal(params) {
 return request({
   url: "/home/getMonthData",
   method: "get",
   params: params
 });
}



//获取报警信息
export function getWarn(params) {
  return request({
    url: "/stationalert/list",
    method: "get",
    params: params
  });
}

//年电量比较统计
export function contrastData(params) {
  return request({
    url: "/home/getYearContrastData",
    method: "get",
    params: params
  });
}

//年份数据获取

export function getYearList(params) {
 return request({
   url: "/home/getYearList",
   method: "get",
   params: params
 });
}