var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "addForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.ruleForm,
            "label-width": "150px",
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "oldPassword",
                label: _vm.$t("PersonalMsg.initalPasw"),
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  autocomplete: "off",
                  placeholder: _vm.$t("PersonalMsg.Pinit"),
                },
                model: {
                  value: _vm.ruleForm.oldPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "oldPassword", $$v)
                  },
                  expression: "ruleForm.oldPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "newPassword",
                label: _vm.$t("PersonalMsg.NewPasw"),
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  autocomplete: "off",
                  placeholder: _vm.$t("PersonalMsg.PnewPasw"),
                },
                model: {
                  value: _vm.ruleForm.newPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "newPassword", $$v)
                  },
                  expression: "ruleForm.newPassword",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                prop: "checkPassword",
                label: _vm.$t("PersonalMsg.CPasw"),
              },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  autocomplete: "off",
                  placeholder: _vm.$t("PersonalMsg.PCword"),
                },
                model: {
                  value: _vm.ruleForm.checkPassword,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "checkPassword", $$v)
                  },
                  expression: "ruleForm.checkPassword",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "cancel",
              on: {
                click: function ($event) {
                  _vm.props.visible = false
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("PersonalMsg.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "confirm",
              attrs: { loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("ruleForm")
                },
              },
            },
            [_vm._v(" " + _vm._s(_vm.$t("PersonalMsg.confirm")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }