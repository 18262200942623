/*
 * @Author: sun.mengmeng
 * @Date: 2021-09-28 15:23:27
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-09-19 09:47:17
 * @Description: file content
 */
import request from "@/utils/request"
const baseUrl = process.env.VUE_APP_BASE_API;

//电站信息保存
export function saveConfig(params) {
  return request({
    url: "/station/save",
    method: "post",
    data: params
  });
}

//电站信息修改
export function editConfig(params) {
  return request({
    url: "/station/update",
    method: "post",
    data: params
  });
}
//电站信息详情
export function detailConfig(params) {
  return request({
    url: "/station/detail",
    method: "get",
    params: params
  });
}

//获取货币种类
export function getMoneyKey() {
  return request({
    url: `/dist/list/currency`,
    method: "get",
  });
}

//设置逆变器与组件
export function setPv(params) {
  return request({
    url: `/stationlayout/setInverterPVModuleType`,
    method: "post",
    data: params
  });
}

//设置汇总器
export function setCollect(params) {
  return request({
    url: `/station/submitBeehive`,
    method: "post",
    data: params
  });
}

//电站信息详情
export function stationDetail(params) {
  return request({
    url: `/stationlayout/getLayoutDetailByStationId`,
    method: "get",
    params: params
  });
}
export function stationDetailAwait(params) {
  return new Promise((resolve, reject)=>{
    resolve(request({
      url: `/stationlayout/getLayoutDetailByStationId`,
      method: "get",
      params: params
    }))
  });
}
//验证优化器是否可使用
export function testMacId(params) {
  return request({
    url: `/stationlayout/checkUsable`,
    method: "get",
    params: params
  });
}

//设置布局信息
export function setLayoutInfo(params) {
  return request({
    url: `/stationlayout/setLayoutAssist`,
    method: "post",
    data: params
  });
}

//设置优化器
export function setGroup(params) {
  return request({
    url: `/stationlayout/setGroupPVpanel`,
    method: "post",
    data: params
  });
}

//添加组串及设置优化器
export function setComponent(params) {
  return request({
    url: `/stationlayout/saveGroupPVpanel`,
    method: "post",
    data: params
  });
}

//根据优化器id 查询 组串和优化器
export function getComponent(params) {
  return request({
    url: `/stationlayout/getGroupHoneyooByInverterId`,
    method: "get",
    params: params
  });
}
//删除优化器
export function delHoney(params) {
  return request({
    url: `/stationlayout/delHoney`,
    method: "post",
    data: params
  });
}

//删除组串
export function delComponent(params) {
  return request({
    url: `/stationlayout/delGroupById`,
    method: "post",
    data: params
  });
}

//电站逆变器组串关系tree
export function getLayoutTree() {
  return request({
    url: `/stationlayout/tree`,
    method: "get",
  });
}

//电站详情逆变器组串关系tree
export function getStationTree(params) {
  return request({
    url: `/stationlayout/detailByStationId`,
    method: "get",
    params:params
  });
}

//电站列表
export function stationList(params) {
  return request({
    url: `/station/list`,
    method: "get",
    params:params
  });
}

//验证汇总器(Mac ID)是否可使用
export function testUnitId(params) {
  return request({
    url: `/station/checkUsable`,
    method: "get",
    params:params
  });
}

export function delBeehiveWithLayoutById(params) {
 return request({
   url: `/station/delBeehiveWithLayoutById`,
   method: "post",
   params:params
 });
}
//beehive status
export function getBeehiveStatus(params) {
  return request({
    url: `/status/beehive`,
    method: "get",
    params:params
  });
}

//下载honeybee模板
export function downloadTemplate(stationId){
  const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
  window.open(`${baseUrl}/stationlayout/downloadTemplate2?l=${lang}&stationId=${stationId}`);
}

//上传honeybee
export function uploadHoneybee(stationId){
  const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en';
  return `${baseUrl}/stationlayout/uploadHoneybee2?l=${lang}&stationId=${stationId}`;
}

// 电站图片上传
export function uploadStationPic(params) {
  return request({
    url: `/station/savePhotos`,
    method: "post",
    data: params,
    headers: {
      "Content-Type": "multipart/form-data;charset=utf-8",
    },
  });
}
