<!--
 * @Author: jixuyu
 * @Date: 2021-09-26 21:59:08
 * @LastEditTime: 2022-06-14 14:27:01
 * @LastEditors: JIXUYU
 * @Description: 
 * @FilePath: \front\src\views\device\components\dateFilter.vue
 * 记得注释
-->
<template>
  <div class="device-search">
    <div class="search-top">
      <div class="main-right">
        <el-date-picker
          v-model="dataValue"
          type="daterange"
          :range-separator="$t('device.to')"
          :start-placeholder="$t('device.startTime')"
          :end-placeholder="$t('device.endTime')"
        >
        </el-date-picker>
        <p class="right-btn" @click="searchDate">{{$t('device.search')}}</p>
      </div>
    </div>
    <Gn-Tabs :datas="tabList" :firstTab="component" @changeTabs="changeTabs" />
    <transition name="fade-transform" mode="out-in">
      <component :is="component" :gridOptions="gridOptions"></component>
    </transition>
  </div>
</template>

<script>
import readyMsg from "./readyMsg.vue";
import historyMsg from "./historyMsg.vue";
export default {
  components: { historyMsg, readyMsg },
  // 定义属性
  data() {
    const date = new Date();
    const pre = date.getTime() - 3600 * 1000 * 27 * 3;
    const cur = this.$moment().format("YYYY-MM-DD");
    const threePre = this.$moment(date.getTime() - 3600 * 1000 * 27 * 3).format(
      "YYYY-MM-DD"
    );
    return {
      dataValue: [pre, date.getTime()],
      component: "readyMsg",
      gridOptions: {
        alerttime_dategt: threePre,
        alerttime_datelt: cur,
        status: 0
      },
  
    };
  },
  computed: {

     tabList(){
         return [
        {
          name: this.$t('device.Unhandled'),
          component: "readyMsg"
        },
        {
          name:this.$t('device.Historical'),
          component: "historyMsg"
        }
      ]

      } 





  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    changeTabs(val) {
      if (val === 'readyMsg') {
        this.gridOptions.status = 0
      }else {
         this.gridOptions.status = 1
      }
      this.component = val;
    },
    //选择日期
    searchDate() {
      const map = this.dataValue.map(v =>
        this.$moment(v).format("YYYY-MM-DD")
      );
      this.gridOptions.alerttime_dategt = map[0]
      this.gridOptions.alerttime_datelt = map[1]
    }
  },
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
