<!--
 * @Author: jixuyu
 * @Date: 2021-09-13 21:12:55
 * @LastEditTime: 2021-10-23 14:40:33
 * @LastEditors: JIXUYU
 * @Description:
 * @FilePath: \front\src\views\status\index.vue
 * 记得注释
-->
<template>
  <div class="status-list">
    <Gn-Tabs :datas="tabList" :firstTab="component" @changeTabs="changeTabs" />
    <transition name="fade-transform" mode="out-in">
      <component :is="component"></component>
    </transition>
  </div>
</template>

<script>
import beeComponent from "./components/beeComponent.vue";
import honeyComponent from "./components/honeyComponent.vue";
import swarmComponent from "./components/swarmComponent.vue";
export default {
  components: { beeComponent, honeyComponent, swarmComponent },
  data() {
    return {
      component: "beeComponent",

      tabList: [
        {
          name: this.$t("common.beehive"),
          component: "beeComponent"
        },

        {
          name: this.$t("common.swarm"),
          component: "swarmComponent"
        },
        {
          name: this.$t("common.honeybee"),
          component: "honeyComponent"
        },

      ]
    };
  },
  methods: {
    /**
     * @description:
     * @param {*} val
     * @return {*}
     * @author: JIXUYU
     */
    changeTabs(val) {

      this.component = val;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "./style/index";
</style>
