<!--
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-09 16:57:21
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-09 17:39:21
-->

<script>
import roleManagement from "@/views/roleManagement";
import addUserDialog, { InterfaceParams } from "./components/addUserDialog";
import { deleteUser } from "@/api/userManagement";
export default {
  name: "userManagement",
  extends: roleManagement,
  data() {
    return {
      searchName: "",
      idList: [],
      width: "40%",
      addRole: this.$t("userManagement.creatUser"),
      rolesName: this.$t("userManagement.Penter"),
      userDetail: null,
      bodyParser: false,
      getRoleListsAPI: "/sys/user/list",
      gridOptions: {
        username: "",
      },
      dialog: {
        title: this.$t("userManagement.addUser"),
        visible: false,
        type: "ADD",
        data: Object.assign({}, InterfaceParams),
      },
    };
  },
  components: {
    addUserDialog,
    // editPsw,
  },
  methods: {
    /**
     * 表格数据渲染
     */
    gridRenderContent() {
      return [
        { label: this.$t("userManagement.selectAll"), type: "selection" },
        { label: this.$t("userManagement.userName"), prop: "username" },
        { label: this.$t("userManagement.Email"), prop: "email" },
        // { label: this.$t("userManagement.userStatus"), prop: "statusName" },
        {
          label: this.$t("userManagement.action"),
          render: (scope) => (
            <div>
              <el-button
                type="primary"
                onClick={() => this.openEditorDialog(scope)}
                value="edit"
              >
                {this.$t("userManagement.edit")}
              </el-button>
              <el-button
                type="danger"
                onClick={() => this.deleteGridItem(scope.row.id)}
                value="del"
              >
                {this.$t("userManagement.detete")}
              </el-button>
            </div>
          ),
        },
      ];
    },
    /**
     * @description: 用户选择
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    tableSelectionChange() {
      this.idList = this.$refs["grid"].getSelectedIds();
    },
    /**
     * @description: 用户新增
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    openAddDialog() {
      this.componentName = "addUserDialog";
      this.MixinsDialogLogic(
        this.dialog,
        this.$t("userManagement.addUser"),
        "ADD",
        {}
      );
    },
    /**
     * @description: 用户编辑
     * @param {*} row
     * @return {*}
     * @author: JIXUYU
     */
    openEditorDialog({ row }) {
      this.componentName = "addUserDialog";
      this.MixinsDialogLogic(
        this.dialog,
        this.$t("userManagement.editUser"),
        "MODIFY",
        row
      );
    },
    /**
     * @description: 搜搜查询
     * @param {type}
     * @return {type}
     */
    handlerSearch(flag = true) {
      this.gridOptions.username = this.searchName;
      this.$refs["grid"].tableInitial(flag);
    },
    /**
     * @description: 批量删除
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    handleDel() {
      if (!this.idList.length) {
        this.$message.success(this.$t("userManagement.PSelect"));
      } else {
        let roleIds = this.idList.join(",");
        this.deleteGridItem(roleIds);
      }
    },

    /**
     * @description: 删除用户
     * @param {type}
     * @return {type}
     */

    async deleteGridItem(data) {
      const results = await this.MixinsConfirm();
      if (results) {
        deleteUser({ ids: data }).then((resp) => {
          if (resp.code === 200) {
            this.handlerSearch(false);
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wy_action {
  padding-right: 200px;
}
</style>
