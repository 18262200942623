<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-27 23:06:07
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-07 11:01:17
-->
<template>
 <div class="stationTree">
  <el-input :placeholder="$t('curve.Pfliter')" v-model="filterText" style="margin-bottom:10px"> 
  </el-input>
  <el-tree class="filter-tree" :data="treeData" :render-content="renderContent"  :filter-node-method="filterNode"  ref="tree"   :expand-on-click-node='false' 
  show-checkbox 
  default-expand-all
  node-key="name"
  :default-checked-keys="honeybeeList"
  @check="handleCheckChange"
  :check-strictly='true'
  >
    
    </el-tree> 
 </div>
</template>

<script>
import {stationlayout} from '@/api/curve'
 import * as util from "@/utils";

 /**
  * @author: JIXUYU
  * @since: 2021-09-27
  * @router('/stationTree')
  */
 export default {
  name: "stationTree",
  watch: {
   filterText(val) {
    this.$refs.tree.filter(val);
   }
  },
  data() {
   return {
    filterText: '',
    treeData: [],
    honeybeeId:[],//优化器id
    groupId:[],//组串id
    honeybeeList:[]
   };
  
  },
  methods: {
     /**
      * @description: 
      * @param {*}
      * @return {*}
      * @author: JIXUYU
      */   
     handleCheckChange(nodeObj, SelectedObj){
      if(SelectedObj.checkedNodes.length>7){
       this.$message.error(this.$t('curve.PSelect'))
       return false
      }
      if(SelectedObj.checkedNodes.length==0){
        util.writeSession('honeybeeId', null)
      }
     let honeybeeId=this.$refs['tree'].getCheckedKeys()
      util.writeSession('honeybeeId', honeybeeId)
      this.$baseEventBus.$emit("checkTree",{honeybeeId:honeybeeId})


     },
     /**
      * @description: 
      * @param {*} value
      * @param {*} data
      * @return {*}
      * @author: JIXUYU
      */   
     filterNode(value, data) {
        if (!value) return true;
        return data.name.indexOf(value) !== -1;
      },
     /**
      * @description: 
      * @param {*} h
      * @param {*} node
      * @param {*} data
      * @param {*} store
      * @return {*}
      * @author: JIXUYU
      */   
     renderContent(h, { node, data, store }) {
        return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
            <span style='margin-left:30px'>
             <span  on-click={ () => this.searchStation(data) }>{data.name}</span>
            </span>
          </span>)
    },
   /**
    * @description: 节点数据状态禁止
    * @param {*} data
    * @return {*}
    * @author: JIXUYU
    */    
   setTreeRecursion(data) {
    data.map((item,index)=>{
        if(item.type!=3){
          item.disabled=true
        }
        if(item.children){
            this.setTreeRecursion(item.children);
        }
    });
    return data;
  },

   /**
    * @description: 电站树数据获取
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */   
   getTreeList(){
    stationlayout().then((res)=>{
     if(res.code==200&&res.data){
      let result=res.data
      this.setTreeRecursion(result)
      this.treeData=result
     }

    })
   },
   /**
    * @description: 图表数据展示
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */   
   searchStation(){

   },

  },
  created(){
     this.getTreeList()
  },
  mounted(){
   if(util.readSession('honeybeeId')){
      this.honeybeeList=util.readSession('honeybeeId')
   }

   

  },

 }
</script>

<style lang="scss" scoped>
 @import '../style/index';
</style>