<!--
import { import } from '@babel/types';
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-28 00:05:38
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-07 10:59:13
-->
<template>
 <div class="stationChart" ref="station">
  <div class="station_top">
   <el-date-picker class="date" v-model="dateTime" :type="dateType" 
    :value-format="formateValue"
    :picker-options="expireTimeOption"
   :placeholder="this.$t('curve.choiceDate')">
   </el-date-picker>
  </div>
  <Gn-Tabs :datas='tabList' :firstTab='component' @changeTabs='changeTabs' />
  <transition name="fade-transform" mode="out-in">
   <component :is="component" :dateTime='dateTime' ref="childName" :childName='component'></component>
  </transition>
 </div>
</template>

<script>
 import chartLeft from './chartLeft'
 import chartRight from './chartRight'
 import * as util from "@/utils";
 /**
  * @author: JIXUYU
  * @since: 2021-09-28
  * @router('/stationChart')
  */
 export default {
  name: "stationChart",
  components: {
   chartLeft,
   chartRight
  },
  data() {
   return {
    component: 'chartLeft',
    dateType: "date",
    formateValue: "yyyy-MM-dd",
    dateTime: this.$moment().format("YYYY-MM-DD"),
    expireTimeOption: {
        disabledDate (date) {
          return date.getTime() >= Date.now()
        }
      },
   }
  },
  computed: {
   tabList() {
    return [{
      name: this.$t('curve.power'),
      component: "chartLeft"
     },
     {
      name: this.$t('curve.electricity'),
      component: "chartRight"
     },
    ]
   }
  },
  methods: {
   changeTabs(val) {
    this.component = val
    if(val=='chartLeft'){
     util.writeSession('beShow',true)
    }else{
     util.writeSession('beShow',false)
    }
   },
  },
  mounted(){
   util.writeSession('beShow',true)
  },
  destroyed() {
   util.writeSession('honeybeeId', null)
  }
 }
</script>

<style lang="scss" scoped>
 @import '../style/index';
</style>