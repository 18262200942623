/*
 * @Author: sun.mengmeng
 * @Date: 2021-09-14 08:52:16
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-09-20 22:22:46
 * @Description: file content
 */
import Vue from "vue";
import Vuex from "vuex";
import getters from "./getters";
import actions from './action';

Vue.use(Vuex);
const files = require.context("./modules", false, /\.js$/);

let modules =Object.assign({},actions) ;


files.keys().forEach((key) => {
  modules[key.replace(/(\.\/|\.js)/g, "")] = files(key).default;
});
Object.keys(modules).forEach((key) => {
  modules[key]["namespaced"] = true;
});
const store = new Vuex.Store({
 modules,
 getters

});
export default store;

