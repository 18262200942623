// import API from '@/utils/request';
import { API } from "@/api/api";
import "../style/index.scss";
import {
 addResizeListener,
 removeResizeListener,
} from "element-ui/lib/utils/resize-event";

export default {
 name: "GnGrid",
 props: {
  // 表格的api接口--异步
  url: [String],
  // 表格传递的参数--异步
  params: [Object],
  props: {
   type: Object,
   default () {
    return {
     records: "content", // 数据
     current: "current", // 当前页码
     size: "size", // 当前页码条数
     pages: "pages", // 总共页码数
     total: "total", //总共条数
    };
   },
  },
  // 静态表格数据--同步(注:之后会根据data计算分页)
  data: Array,
  // 是否初始化加载表格
  isInitial: {
   type: Boolean,
   default: true,
  },
  // 是否用默认固定高度
  defaultHeight: {
   type: Number,
   default: 0,
  },
  // 自适应高度(当设置autoHeight属性，所有高度无效)
  autoHeight: {
   type: Boolean,
   default: false,
  },
  // false: params方式 true: data方式
  bodyParser: {
   type: Boolean,
   default: false,
  },
  // 是否显示阴影
  isShadow: {
   type: Boolean,
   default: false,
  },
  method: {
   type: String,
   default: "GET",
  },
  renderContent: [Function, Array],
  keyword: {
   type: String,
   default: "id",
  },
  pageStyle: {
   type: Object,
   default () {
    return {
     textAlign: "right",
    };
   },
  },
  tableStyle: {
   type: Object,
   default () {
    return {};
   },
  },
 },

 data () {
  return {
   tableData: [],
   loading: false,
   total: 0,
   pages: 1,
   current: 1,
   size: 10,
   maxHeight: 440,
   tableBody: null,
   tableHeader: null,
   // 是否重置pagination
   repeat: true,
   slotChilds: [],
   // 存储已经勾选的 library
   cacheSelected: {},
  };
 },

 computed: {
  _grid () {
   return this.$refs["grid"];
  },
  table () {
   return this.$refs["table"];
  },
 },

 watch: {
  data: {
   immediate: true,
   handler (val) {
    if (Array.isArray(val)) {
     this.tableData = val;
    }
   },
  },
  tableData (newVal) {
   if (newVal.length == 0) {
    if (this.pages < this.current && this.current != 1) {
     this.current--;
     this.tableInitial(false);
    }
   }
  },
 },

 render (h) {
  return (
   <div
    ref="grid"
    v-loading={this.loading}
    class={["gn-grid", this.isShadow ? "gn-grid-shadow" : ""].join(" ")}
   >
    {/* <!-- table表格 --> */}
    <el-table
     ref="table"
     row-class-name={this.tableRowClassName}
     width="100%"
     style={this.tableStyle}
     data={this.tableData}
     highlight-current-row={false}
     header-cell-class-name="gn-grid-header"
     on-select={this.tableHandlerSelect}
     span-method={this.spanMethod}
     on-select-all={this.tableHandlerSelectAll}
     on-selection-change={this.tableSelectionChange}
     on-row-click={this.tableRowClick}
     on-current-change={this.tableCurrentChange}
     on-sort-change={this.sortChange}
    >
     {this.renderContent
      ? this.renderContentParse(h)
      : this.renderSlotParse()}
     <div slot="append">{this.$slots.append}</div>
    </el-table>

    {this.repeat ? (
     ""
    ) : (
     <el-pagination
      style={this.pageStyle}
      total={this.total}
      background
      v-show={this.total == 0 ? false : true}
      class="gn-grid-pagination"
      layout="total, sizes, prev, pager, next, jumper"
      on-current-change={this.handlerCurrentChange.bind(this)}
      on-size-change={this.handlerSizeChange.bind(this)}
      page-sizes={[10, 20, 30, 40, 50]}
     >
      {/* <span class="el-pagination__total">
              {this.current} / {this.pages}
            </span> */}
     </el-pagination>
    )}
   </div>
  );
 },

 beforeDestory () {
  removeResizeListener(this._grid, this.realCalcTableBody);
 },

 mounted () {
  this.isInitial && !this.data && this.tableInitial();
  this.calcTableInitialSize();
  addResizeListener(this._grid, this.realCalcTableBody);
 },

 methods: {
  //排序
  sortChange (data) {
   this.$emit("sortChange", data);
  },
  renderContentParse (h) {
   const renderContent = this.renderContent.call(this, h);
   return renderContent.map((child) => {
    const props = {
     ...child,
     align: "center",
     showOverflowTooltip: true,
    };
    if (child.prop || child.type) {
     return h("el-table-column", { props });
    } else {
     return h("el-table-column", {
      props,
      scopedSlots: {
       default: (props) => {
        return child.render && child.render(props);
       },
      },
     });
    }
   });
  },

  renderSlotParse () {
   const slotContents = this.$slots.default ? this.$slots.default : [];
   return slotContents.map((child) => {
    if (child.componentOptions) {
     const { propsData } = child.componentOptions;
     propsData.align = "center";
     propsData.showOverflowTooltip = true;
    }

    return child;
   });
  },
  /**
   * @description:  初始化表格的尺寸
   * @param {type}
   * @return:
   * @author: JIXUYU
   */
  calcTableInitialSize () {
   this.tableBody = this._grid.querySelector(".el-table__body-wrapper");
   this.tableHeader = this._grid.querySelector(".el-table__header-wrapper");
  },
  /**
   * @description: 实时计算表格的body最大高度
   * @param {type}
   * @return:
   * @author: JIXUYU
   */
  realCalcTableBody () {
   if (this.autoHeight) return;

   if (!this.defaultHeight) {
    this.maxHeight =
     window.innerHeight - this._grid.getBoundingClientRect().top - 90;
    // 实时减去当前的头部高度（默认为40）
    this.tableBody.style.maxHeight =
     this.maxHeight -
     (this.tableHeader ? this.tableHeader.offsetHeight : 40) +
     "px";
   } else {
    this.tableBody.style.height =
     this.defaultHeight -
     (this.tableHeader ? this.tableHeader.offsetHeight : 40) +
     "px";
   }
   this.tableBody.style.overflowY = "auto";
  },
  /**
   * @description: 表格的加载方法
   * @param {Boolean} repeat true:表格重新加载，size重置10，current重置1
   * @return:
   * @author: JIXUYU
   */
  async tableInitial (repeat = true) {
   if (repeat) {
    this.current = 1;
    this.size = 10;
    this.repeat = true;

    var timer = setTimeout((_) => {
     clearTimeout(timer);
     timer = null;
     this.repeat = false;
    });
   }
   const params = {
    [this.props.current]: this.current,
    [this.props.size]: this.size,
    ...this.params,
   };
   try {
    this.loading = true;
    const request = this.bodyParser
     ? { url: this.url, data: params, method: this.method }
     : { url: this.url, params, method: this.method };
    const { data, code } = await API(request);
    const { records, total, pages, size } = data;
    let allList = [];
    if (!records) {
     allList = data;
    } else {
     allList = records;
    }
    this.loading = false;
    if (code == 200 && _.isObject(data) && _.isArray(allList)) {
     this.setLoadedData(allList, total, pages);
    } else {
     this.setLoadedData();
    }
   } catch (e) {
    this.setLoadedData();
    this.loading = false;
   }
  },
  /**
   * @description: 设置table加载完成后的数据
   * @param {*} data
   * @param {*} total
   * @param {*} pages
   * @return:
   * @author: JIXUYU
   */
  setLoadedData (data = [], total = 0, pages = 1) {
   this.tableData = data;
   this.total = total;
   this.pages = pages === 0 ? 1 : pages;

   this.selectedCurrentTable();
  },

  selectedCurrentTable (prop = this.keyword) {
   const data = this.tableData;
   const currentSelected = this.cacheSelected[this.current];
   if (!currentSelected) {
    return;
   }

   // 先清除本页所有勾选
   this.table.clearSelection();
   // 再勾选对应的选项
   this.$nextTick(() => {
    currentSelected
     .map((row) => row[prop])
     .forEach((id) => {
      const index = data.findIndex((value) => value[prop] === id);
      index >= 0 && this.table.toggleRowSelection(data[index], true);
     });
   });
  },

  /**
   * @description:  currentPage 改变时会触发
   * @param {Number} page: 当前页currentPage
   * @return:
   * @author: JIXUYU
   */
  handlerCurrentChange (page) {
   this.current = page;
   this.tableInitial(false);
  },

  /**
   * @description:  pageSize 改变时会触发
   * @param {Number} size: 页条数size
   * @return:
   * @author: JIXUYU
   */
  handlerSizeChange (size) {
   this.size = size;
   this.tableInitial(false);
  },

  tableRowClassName ({ row, rowIndex }) {
   return rowIndex % 2 === 1 ? "stripe-background" : "";
  },
  /**
   * @description: 当用户手动勾选数据行的 Checkbox 时触发的事件
   *@param {*} selection: 当前所有勾选的数组
   * @param {*} row
   * @return:
   * @author: JIXUYU
   */
  tableHandlerSelect (selection, row) {
   this.$emit.apply(this, ["select", ...arguments]);
  },
  /**
   * @description: 表格行合并
   * @param {type}
   * @return {type}
   * @author: JIXUYU
   */
  spanMethod ({ rowIndex, columnIndex }) {
   this.$emit.apply(this, ["span-method", ...arguments]);
  },

  /**
   * @description: 当用户手动勾选全选 Checkbox 时触发的事件
   * @param {*} selection: 当前所有勾选的数组
   * @return:
   * @author: JIXUYU
   */
  tableHandlerSelectAll (selection) {
   this.$emit.apply(this, ["select-all", ...arguments]);
  },

  /**
   * @description: 当选择项发生变化时会触发该事件
   * @param {*} selection
   * @return:
   * @author: JIXUYU
   */
  tableSelectionChange (selection) {
   this.cacheSelected[this.current] = selection;
   this.$emit.apply(this, ["selection-change", ...arguments]);
  },

    /**
   * @description: 当选择项发生变化时会触发该事件
   * @param {*} 
   * @return:
   * @author: JIXUYU
   */
 toggleRowSelection (val) {
  this.table.toggleRowSelection(val)
  },
  /**
   * @description: 取消勾选状态
   * @param {*} val
   * @return {*}
   * @author: JIXUYU
   */  
  clearSelection (val) {
   this.table.clearSelection(val)
  },

  /**
   * @description: 获取所有已经勾选的id数组
   * @param {string} default: id 筛选的key值
   * @return: {Array}
   * @author: JIXUYU
   */
  getSelectedIds (prop = this.keyword) {
   return this.getSelected().map((val) => val[prop]);
  },

  /**
   * @description: 获取所有已经勾选的信息数组
   * @param {type}
   * @return { Array }
   * @author: JIXUYU
   */
  getSelected () {
   let lists = [];
   Object.keys(this.cacheSelected).forEach((key) => {
    const rowList = this.cacheSelected[key];
    lists = lists.concat(rowList);
   });
   return lists;
  },

  clearSelected (select, prop = "id") {
   if (!select) throw "select argument must be a Object!";
   //非本页面
   Object.keys(this.cacheSelected).find((key) => {
    const rowList = this.cacheSelected[key];
    const index = rowList.findIndex(
     (value) => value[this.keyword] === select[this.keyword]
    );
    if (index >= 0) {
     rowList.splice(index, 1);
     return true;
    }
    return false;
   });
   // 本页面
   this.selectedCurrentTable();
  },

  clearAllSelected () {
   this.cacheSelected = {};
   this.table.clearSelection();
  },
  /**
   * @description: 当某一行被点击时会触发该事件
   * @param {*} row
   * @param {*} event
   * @param {*} column
   * @return:
   * @author: JIXUYU
   */
  tableRowClick (row, event, column) {
   this.$emit.apply(this, ["row-click", ...arguments]);
  },
  /**
   * @description: 当表格的当前行发生变化的时候会触发该事件
   * @param {*} currentRow
   * @param {*} oldCurrentRow
   * @return:
   * @author: JIXUYU
   */
  tableCurrentChange (currentRow, oldCurrentRow) {
   this.$emit.apply(this, ["current-change", ...arguments]);
  },
 },
};
