<!--
 * @Author: sun.mengmeng
 * @Date: 2020-09-11 16:37:08
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-08 16:45:00
 * @Description: file content
-->
<template>
  <section>
    <el-form :rules="rules" ref="ruleForm" class="addForm" :model="ruleForm" label-width="150px">
      <el-form-item prop="oldPassword" :label="$t('PersonalMsg.initalPasw')">
        <el-input
          type="password"
          v-model="ruleForm.oldPassword"
          autocomplete="off"
          :placeholder="$t('PersonalMsg.Pinit')"
        ></el-input>
      </el-form-item>
      <el-form-item prop="newPassword" :label="$t('PersonalMsg.NewPasw')">
        <el-input
          type="password"
          v-model="ruleForm.newPassword"
          autocomplete="off"
          :placeholder="$t('PersonalMsg.PnewPasw')"
        ></el-input>
      </el-form-item>
      <el-form-item prop="checkPassword" :label="$t('PersonalMsg.CPasw')">
        <el-input
          type="password"
          v-model="ruleForm.checkPassword"
          autocomplete="off"
          :placeholder="$t('PersonalMsg.PCword')"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="props.visible = false" class="cancel">{{$t('PersonalMsg.cancel')}}</el-button>
      <el-button :loading="loading" @click="handlerSubmit('ruleForm')" class="confirm">
        {{$t('PersonalMsg.confirm')}}
      </el-button>
    </div>
  </section>
</template>

<script>
import { RULESREQUIRE ,RulesPassword} from "@/mixins/Rules";
import Load from "@/mixins/Load";
// import md5 from "js-md5";
import { editPassowrd } from "@/api/userManagement";
import * as util from "@/utils";
export default {
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mixins: [Load],
  data() {
    var validateNewPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('addRules.Powassd')));
      } else {
        if (this.ruleForm.checkPassword !== "") {
          this.$refs.ruleForm.validateField("checkPassword");
        }
        if (value.length < 6) {
          callback(new Error(this.$t('addRules.entSix')));
        }
        const match = RulesPassword[0].pattern;
        if (match.test(value)) {
          callback();
        } else {
          callback(new Error(this.$t('addRules.nextNum')));
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('addRules.PowassdAgen')));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error(this.$t('addRules.NoSure')));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      userData: util.readSession("UserInfo"),
      rules: {
        newPassword: [{ required: true, validator: validateNewPass, trigger: "blur" }],
        checkPassword: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: this.$t('PersonalMsg.Pinit'), trigger: "blur" },
        ],
      },
      ruleForm: {
        newPassword: "",
        checkPassword: "",
        oldPassword: "",
      },
    };
  },

  methods: {
    /**
     * @description: 重置密码
     * @param {type}
     * @return {type}
     * @author: JIXUYU
     */
  handlerSubmit(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.loading=true
          let params=this.ruleForm
          const results = await this.MixinsLoading('loading', editPassowrd, params);
            if (results.code === 200) {
              this.$message.success(this.$t('addRules.setLogo'));
              this.props.visible = false;
              this.$refs["ruleForm"].resetFields();
              const fullPath = this.$route.fullPath;
              setTimeout(()=>{
            this.$router.push(`/login?redirect=${fullPath}`);
              },1000)


            }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-footer {
  text-align: center;
}
</style>
