<!--
 * @Author: jixuyu
 * @Date: 2021-09-13 21:08:47
 * @LastEditTime: 2021-09-28 23:05:14
 * @LastEditors: jixuyu
 * @Description: 
 * @FilePath: \front\src\views\device\index.vue
 * 记得注释
-->
<template>
  <div class="device-page">
    <date-filter></date-filter>
  </div>
</template>

<script>
import dateFilter from "./components/dateFilter.vue";

export default {
  components: { dateFilter},
  data() {
    return {
   
    };
  },
  methods: {


  }
};
</script>

<style scoped lang="scss">
@import "./style/index.scss";
</style>
