<template>
 <div class="addRule">
  <el-form label-width="90px" :rules="rules" ref="form" :model="props.data">
   <el-form-item :label="$t('roleManagement.roleName')" prop="roleName">
    <el-input v-model="props.data.roleName" />
   </el-form-item>
   <el-form-item :label="$t('roleManagement.roleNotice')" prop="description">
    <el-input v-model="props.data.description" type="textarea" />
   </el-form-item>
   <el-form-item :label="$t('roleManagement.Resource')" prop="tree">
    <el-scrollbar tag="ul">
     <el-tree style="height: 400px; overflow-y: scroll;" ref="tree" node-key="id" @check="handlerCheck" :data="allResource" :props="defaultProps" :default-checked-keys="defaultChecked" default-expand-all show-checkbox />
    </el-scrollbar>
   </el-form-item>
  </el-form>
  <div class="dialog-footer">
   <el-button @click="props.visible = false" class="cancel">{{this.$t('roleManagement.cancel')}}</el-button>
   <el-button :loading="loading" @click="handlerSubmit('form')" class="confirm">
   {{this.$t('roleManagement.submit')}}
   </el-button>
  </div>
 </div>
</template>

<script>
 import {
  mapState,
  mapActions
 } from 'vuex'
 import {
  RULESREQUIRE,
  // RULESREQUIRENUMBER,
  // RULESREQUIREMIN,
  RulesProjectTypeName
 } from '@/mixins/Rules'
 import Load from '@/mixins/Load'
 export const InterfaceParams = {
  id: null,
  description: '',
  roleName: '',
 };
 export default {
  props: {
   props: {
    type: Object,
    default () {
     return {};
    }
   }
  },
  mixins: [Load],
  data() {
   return {
    loading: false,
    companyName: '',
    defaultProps: {
     children: 'children',
     label: 'name'
    },
    current: 1,
    menuIds: '',
    companyNameList: [],
    defaultChecked: [],
    rules: {
     roleName: RulesProjectTypeName,
     companyId: RULESREQUIRE,
     type: RULESREQUIRE,
     isProjectManagement: RULESREQUIRE,
    }
   }
  },
  computed: {
   ...mapState('UserRoles', ['allResource'])
  },
  watch: {
   'props.visible': {
    immediate: true,
    async handler(val) {
     if(val===true){
     this.getAllResourceAPI();
     // 清空已存在
     this.$refs['tree'] && this.$refs['tree'].setCheckedKeys([]);
    if(this.props.data.id){
     //权限资源回显
     let {
      menuIds
     } = await this.getRoleResourceAPI(this.props.data.id);
     let menuList = menuIds&&menuIds.split(',')
     setTimeout(()=>{this.getDefaultData(menuList)},500)
       }
     
    }
    }
   },

  },
  mounted() {
  },
  methods: {
   ...mapActions('UserRoles', [
    'getAllResourceAPI',
    'getRoleResourceAPI',
    'addRoleAPI',
    'updateRolesAPI'
   ]),
   /**
    * @description: 数组滤重，去除重复数据
    * @param {type}
    * @return {type}
    */
   filterArray(arr) {
    let brokeData = {};
    const filter = arr.reduce((cur, next) => {
     brokeData[next.id] ? "" : (brokeData[next.id] = true && cur.push(next));
     return cur;
    }, []);
    return filter;
   },
   /**
    * @description: 默认勾选项数据
    * @param {type} 
    * @return {type} 
    * @author: JIXUYU
    */
   getDefaultData(val) {
    val&&val.map((list, i) => {
     this.$refs.tree.setChecked(list, true)
     list.children ?
      this.getDefaultData(list.children) :
      null;
    });
   },
   async handlerSubmit(formName) {
    const formModule = this.$refs[formName];
    const checkedKeys = this.$refs['tree'].getCheckedKeys();
    this.$refs[formName].validate(valid => {
     if (valid) {
      if ((Array.isArray(checkedKeys) && checkedKeys.length > 0)) {
       formModule.clearValidate(['tree']);
       let checkList = checkedKeys.concat(this.$refs['tree'].getHalfCheckedKeys())
       this.menuIds = checkList.join(',')
      } else {
       formModule.validateField('tree');
      }
      this.submitCallback(
       this.props.type === 'ADD' ?
       this.addRoleAPI :
       this.updateRolesAPI
      );
     }
    })
   },
   /**
    * 弹框点击确认的回调事件
    * @param {String} api: api接口方法名
    */
   async submitCallback(api) {
    this.props.data.menuIds = this.menuIds
    const results = await this.MixinsLoading('loading', api, this.props.data);
    if (results) {
     this.props.visible = false;
     this.$emit('confirm');
    };
   },
   handlerCheck() {
    // this.$refs['form'].clearValidate(['tree']);
   },
  }
 }
</script>

<style lang="scss" scoped>
 @import "../style/index.scss";
 .dialog-footer {
  text-align: center;
 }
</style>

