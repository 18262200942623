<!--
 * @Author: jixuyu
 * @Date: 2021-09-20 15:48:34
 * @LastEditTime: 2022-04-26 17:22:46
 * @LastEditors: JIXUYU
 * @Description:
 * @FilePath: \front\src\views\config\components\categoryTop.vue
 * 记得注释
-->
<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    label-width="200px"
    class="category-top"
  >
    <div class="top-item">
      <el-form-item :label="$t('config.Tags')" prop="Label" class="item-flex">
        <el-input v-model="ruleForm.Label" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item :label="$t('config.InverterMan')" class="item-flex">
        <el-input v-model="ruleForm.Manufacturer"></el-input>
      </el-form-item>
    </div>
    <div class="top-item">
      <el-form-item :label="$t('config.modelType')" class="item-flex">
        <el-input v-model="ruleForm.TypeInfo"></el-input>
      </el-form-item>
      <el-form-item :label="$t('config.RatedPower')" prop="MaxPower" class="item-flex">
        <el-input v-model="ruleForm.MaxPower" maxlength="5"></el-input>
      </el-form-item>
    </div>
    <!-- <div class="top-item">
      <el-form-item label="组串数：" prop="num" class="item-flex">
        <el-input v-model="ruleForm.ListCount"></el-input>
      </el-form-item>
      <el-form-item
        label="每串组件数："
        prop="PreListMoudler"
        class="item-flex"
      >
        <el-input v-model="ruleForm.PreListMoudler"></el-input>
      </el-form-item>
    </div> -->
  </el-form>
</template>

<script>
import { RuleLength20 ,RulePowerNum5} from "@/utils/validate";
export default {
  components: {},
  props: {
    childrenData: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  // 定义属性
  data() {
    return {
      rules: {
        Label: RuleLength20,
        MaxPower: RulePowerNum5
        // PreListMoudler: RulesNum
      },
      ruleForm: {
        Id:"",
        Label: "",
        // ListCount: "",
        Manufacturer: "",
        TypeInfo: "",
        MaxPower: "",
        // PreListMoudler: ""
      }
    };
  },
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    handleInitial(val) {
      if (val.row) {
        for (let key of Object.keys(this.ruleForm)) {
          this.ruleForm[key] = val.row[key];
        }
      }
    },
    //校验
    validateForm() {
      let boolean = false
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          boolean = true
        } else {
          boolean = false;
        }
      });
      return boolean
    }
  },
  created() {
    if (this.childrenData.row) {
      for (let key of Object.keys(this.ruleForm)) {
        this.ruleForm[key] = this.childrenData.row[key];
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
