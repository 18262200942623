var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-wrapper", class: _vm.classObj }, [
    _c("div", { staticClass: "wrapper-top" }, [_c("top-bar")], 1),
    _c(
      "div",
      { staticClass: "wrapper-main x-scroll" },
      [
        _vm.device === "mobile" && _vm.sidebar.opened
          ? _c("div", {
              staticClass: "drawer-bg",
              on: { click: _vm.handleClickOutside },
            })
          : _vm._e(),
        _c("sidebar", { staticClass: "sidebar-container" }),
        _c(
          "div",
          { staticClass: "main-container x-scroll" },
          [
            _c(
              "div",
              { class: { "fixed-header": _vm.fixedHeader } },
              [_vm.activeMenu !== "/design" ? _c("navbar") : _vm._e()],
              1
            ),
            _c("app-main"),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }