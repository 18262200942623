<!--
 * @Author: jixuyu
 * @Date: 2021-10-08 21:06:21
 * @LastEditTime: 2022-04-26 17:33:23
 * @LastEditors: JIXUYU
 * @Description: 
 * @FilePath: \front\src\views\config\components\configNb.vue
 * 记得注释
-->
<template>
  <div>
    <Gn-Tab
      ref="firstTab"
      :tabsList="tabsList"
      :isAdd="true"
      @addPane="createComponent"
    >
    </Gn-Tab>
    <!-- <div class="info-bottom info-save">
      <p class="info-btn" @click="getSingleForm">保存</p>
    </div> -->
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    detailData: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  // 定义属性
  data() {
    return {
      currentId: 1,
      component: "configForm",
      // tabsList: [
      //   {
      //     title: "组串",
      //     name: "1",
      //     content: "configForm",
      //     id: ""
      //   }
      // ],

      currentIndex: 1,
      map: new Map(),
      currentTab: 0
    };
  },
  computed: {
     tabsList(){
      return 
       [
        {
          title: $t('config.string'),
          name: "1",
          content: "configForm",
          id: ""
        }
      ]
     }





  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //切换逆变器
    doTab(item) {
      this.currentId = item.id;
    },
    //新增优化器表单
    addForm() {
      const ref = this.$refs["firstTab"];
      ref && ref.addConfigForm();
    },
    //创建组串
    createComponent() {
      this.currentIndex += 1;
      const obj = {
        title: this.$t('config.string'),
        content: "configForm",
        id: this.currentIndex,
        row: null
      };
      this.$refs["firstTab"].addTab(obj);
    },
    handleInitial(item) {
      this.currentTab += 1;
    },
    //获取对应tab的表单数据
    getSingleForm() {
      const map = this.$refs["firstTab"].getSingleForm("configForm");
      const arr = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L"];
      let groups = [];
      map.forEach((v, index) => {
        const json = {
          Id: `${index}`,
          InverterId: this.currentId,
          Label: this.$t('config.string')+`${arr[index]}`
        };
        groups.push(json);
      });
      return map;
    }
  },

  beforeDestroy() {}
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
