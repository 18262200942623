/*
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-03 11:26:59
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-09-20 21:31:48
 */

import GnGrid from './src/grid.js'
export default GnGrid;