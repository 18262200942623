<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-11-05 13:47:47
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-05-22 15:20:47
-->


<template>
  <echarts :option="option" v-bind="$attrs" v-on="$listeners"></echarts>
</template>
<script>
import echarts from "./base-chart";
export default {
  name: "wy-chart-self",
  components: {
    echarts,
  },
  props: {
    barWidth: {
      type: Number,
      default: 14,
    },
    interval: {
      type: Number,
      default: 0,
    },
    datas: {
      type: Object,
      default() {
        return {};
      },
    },
    titles: {
      type: Array,
      default() {
        return [];
      },
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    animation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    option() {
      const self = this;
      const _option = {
        color: ['rgba(80,135,236, 0.74)', 'rgba(104,187,196, 0.74)', 'rgba(88,165,92, 0.74)','rgba(255,211,6, 0.74)','rgba(255,88,9, 0.74)','rgba(146,26,255, 0.74)','rgba(40,255,40, 0.74)'],
        tooltip: self.datas.tooltip
          ? self.datas.tooltip
          : {
              trigger: "axis",
              axisPointer: {
                // 坐标轴指示器，坐标轴触发有效
                // type: "shadow" // 默认为直线，可选为：'line' | 'shadow'
              },
            },
        // animation: self.datas.animation,
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
         dataZoom: [
          //滑动条
                {
                  show: true,
                  type: 'slider',// 这个 dataZoom 组件是 slider 型 dataZoom 组件
                  xAxisIndex: 0, //这里是从X轴的0刻度开始
                  bottom: 0,
                  zoomLock:true,
                  start: 0,// 从头开始。
                  end: 10,// 一次性展示10个。
                  handleStyle: {
                    color: 'rgba(0,172,210,0.2)',
                  },
                  textStyle: {
                    color: 'rgba(204,187,225,0.8)',
                  },
                  fillerColor: 'rgba(0,172,210,0.2)',
                  borderColor: 'rgba(204,187,225,0.6)',
                },
             
      ],
        legend: {
          data: self.datas.legendData,
        },
        xAxis: {
          data: self.datas.xData,
          // x轴标题间隔
          axisLabel: {
            inside: false,
            interval: self.interval, // 标题间隔
            fontSize: 12,
            margin: 12,
            fontWeight: "normal",
            fontFamily: "MicrosoftYaHei",
            textStyle: {
              color: "#666",
            },
            // rotate: 40,
          },
          axisTick: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          z: 10,
          boundaryGap: false,
        },

        yAxis: self.datas.yData
          ? self.datas.yData
          : [
              {
                axisLabel: { formatter: "{value}" },
                type: "value",
                stack: true,
                splitLine: {
                  show: false,
                },
                name:self.datas.name,
                axisTick: {
                  show: true,
                },
                axisLine: {
                  show: true,
                },
              },
            ],

        series: self.datas.seriesData
      };
      // 配置合并
      return Object.assign(_option, self.options);
    },
  },
};
</script>
