var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "box", staticClass: "design-layout" }, [
    _c("div", { staticClass: "desing-tool" }, [
      _c("ul", { staticClass: "tool-type" }, [
        _c(
          "li",
          {
            staticClass: "coord-gird",
            staticStyle: { width: "230px", display: "flex" },
          },
          _vm._l(_vm.coordData, function (item) {
            return _c(
              "el-input",
              {
                key: item.id,
                staticStyle: { width: "150px", "margin-right": "20px" },
                attrs: {
                  value: item.value ? item.value.toFixed(0) : "",
                  disabled: "",
                },
              },
              [
                _c("template", { slot: "prepend" }, [
                  _vm._v(_vm._s(item.title)),
                ]),
              ],
              2
            )
          }),
          1
        ),
        _c(
          "li",
          {
            staticClass: "coord-gird",
            staticStyle: { width: "270px", display: "flex" },
          },
          [
            _c(
              "el-input",
              {
                staticStyle: { width: "155px", "margin-right": "10px" },
                on: { change: _vm.canvasSizeChange },
                model: {
                  value: _vm.canvasWidth,
                  callback: function ($$v) {
                    _vm.canvasWidth = $$v
                  },
                  expression: "canvasWidth",
                },
              },
              [_c("template", { slot: "prepend" }, [_vm._v("Width")])],
              2
            ),
            _c(
              "el-input",
              {
                staticStyle: { width: "155px", "margin-right": "10px" },
                on: { change: _vm.canvasSizeChange },
                model: {
                  value: _vm.canvasHeight,
                  callback: function ($$v) {
                    _vm.canvasHeight = $$v
                  },
                  expression: "canvasHeight",
                },
              },
              [_c("template", { slot: "prepend" }, [_vm._v("Height")])],
              2
            ),
          ],
          1
        ),
        _c(
          "li",
          [
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.setZoom(0.1)
                  },
                },
              },
              [_vm._v("+")]
            ),
            _c("el-button", [_vm._v(_vm._s(_vm.getZoom))]),
            _c(
              "el-button",
              {
                on: {
                  click: function ($event) {
                    return _vm.setZoom(-0.1)
                  },
                },
              },
              [_vm._v("-")]
            ),
          ],
          1
        ),
        _c("li", { staticClass: "type-item" }, [
          _c("i", {
            staticClass: "el-icon-full-screen iconfont item-icon",
            on: {
              click: function ($event) {
                return _vm.isScreenFull()
              },
            },
          }),
        ]),
        _c(
          "li",
          {
            staticClass: "type-item",
            on: {
              click: function ($event) {
                _vm.startPanning = !_vm.startPanning
                _vm.canvas.selection = !_vm.canvas.selection
              },
            },
          },
          [
            _c("i", {
              staticClass: "el-icon-thumb iconfont item-icon",
              class: { active: _vm.startPanning },
            }),
          ]
        ),
        _c(
          "li",
          { staticClass: "type-grid" },
          [
            _c(
              "el-radio-group",
              {
                on: { change: _vm.gridOpen },
                model: {
                  value: _vm.girdVisible,
                  callback: function ($$v) {
                    _vm.girdVisible = $$v
                  },
                  expression: "girdVisible",
                },
              },
              [
                _c("el-radio", { attrs: { label: true } }, [
                  _vm._v("Show grid"),
                ]),
                _c("el-radio", { attrs: { label: false } }, [
                  _vm._v("Snap to grid"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "li",
          { staticClass: "type-item type-color" },
          [
            _c("span", { class: ["iconfont", "item-icon", "icon-color_lens"] }),
            _c("colorPicker", {
              staticClass: "item-color",
              attrs: { size: "medium" },
              on: { change: _vm.headleChangeColor },
              model: {
                value: _vm.backgroundColor,
                callback: function ($$v) {
                  _vm.backgroundColor = $$v
                },
                expression: "backgroundColor",
              },
            }),
          ],
          1
        ),
        _c("li", { staticClass: "type-item" }, [
          _c("i", {
            staticClass: "el-icon-delete-solid type-delete",
            on: { click: _vm.doDelete },
          }),
        ]),
        _c(
          "li",
          {
            staticClass: "type-save",
            class: { disabled: !_vm.canUndo },
            on: {
              click: function ($event) {
                return _vm.historyState("Undo")
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("config.undo")) + " ")]
        ),
        _c(
          "li",
          {
            staticClass: "type-save",
            class: { disabled: !_vm.canRedo },
            on: {
              click: function ($event) {
                return _vm.historyState("Redo")
              },
            },
          },
          [_vm._v(" " + _vm._s(_vm.$t("config.redo")) + " ")]
        ),
        _c("li", { staticClass: "type-save", on: { click: _vm.saveObj } }, [
          _vm._v(" " + _vm._s(_vm.$t("config.save")) + " "),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "design-show" }, [
      _c("div", { staticClass: "show-left" }, [
        _c(
          "ul",
          { staticClass: "left-img" },
          _vm._l(_vm.imgList, function (item, index) {
            return _c("li", { key: index, staticClass: "left-item" }, [
              _c("img", {
                staticClass: "item-img",
                attrs: { src: item.url },
                on: {
                  click: function ($event) {
                    return _vm.addLayer(item, index)
                  },
                  mousedown: function ($event) {
                    return _vm.recordImg(item, index)
                  },
                },
              }),
              _c("br"),
              _c("span", [_vm._v(_vm._s(_vm.$t("config." + item.type)))]),
            ])
          }),
          0
        ),
      ]),
      _c(
        "div",
        {
          ref: "refName",
          staticClass: "show-canvas x-scroll",
          style: { overflow: "auto" },
        },
        [
          _c("canvas", {
            attrs: {
              id: "canvas",
              width: _vm.canvasWidth,
              height: _vm.canvasHeight,
            },
          }),
        ]
      ),
      _c("div", { staticClass: "show-right" }, [
        _vm.selectRect
          ? _c(
              "ul",
              { staticClass: "right-rect" },
              _vm._l(_vm.rectCoord, function (item, index) {
                return _c("li", { key: index, staticClass: "rect-input" }, [
                  _c("p", { staticClass: "input-name" }, [
                    _vm._v(_vm._s(item.name)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "input-text" },
                    [
                      item.disabled
                        ? _c("el-input", {
                            attrs: { disabled: item.disabled },
                            on: {
                              change: function (event) {
                                return _vm.inputChange(event, item)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          })
                        : _c("el-input-number", {
                            staticStyle: { width: "100%" },
                            attrs: { "controls-position": "right", min: 0 },
                            on: {
                              change: function (event) {
                                return _vm.inputChange(event, item)
                              },
                            },
                            model: {
                              value: item.value,
                              callback: function ($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value",
                            },
                          }),
                    ],
                    1
                  ),
                ])
              }),
              0
            )
          : _vm._e(),
        _vm.isGrouoShow.length > 0
          ? _c("ul", { staticClass: "right-rect" }, [
              _c("li", { staticClass: "rect-item" }, [
                _c("p", { staticClass: "item-label" }, [_vm._v("Horization")]),
                _c(
                  "p",
                  {
                    staticClass: "item-set",
                    on: {
                      click: function ($event) {
                        return _vm.setAlign("Horization")
                      },
                    },
                  },
                  [_vm._v("Set")]
                ),
              ]),
              _c("li", { staticClass: "rect-item" }, [
                _c("p", { staticClass: "item-label" }, [_vm._v("Vertical")]),
                _c(
                  "p",
                  {
                    staticClass: "item-set",
                    on: {
                      click: function ($event) {
                        return _vm.setAlign("Vertical")
                      },
                    },
                  },
                  [_vm._v("Set")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm.selectRect
          ? _c(
              "div",
              { staticClass: "right-update" },
              [
                _c(
                  "el-button",
                  {
                    staticStyle: { width: "100%" },
                    attrs: { type: "primary", round: "" },
                  },
                  [_vm._v("Update")]
                ),
              ],
              1
            )
          : _vm._e(),
        _c("canvas", { attrs: { id: "minimap", width: "200", height: "165" } }),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }