<!--
 * @Author: sun.mengmeng
 * @Date: 2021-09-15 14:10:22
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-31 14:21:29
 * @Description: file content
-->
<template>
  <div class="dateSelect">
    <category-item :cateList="cateList"></category-item>
    <category-echart
      :echartsList="echartsList"
      @dateChange="changeDate"
    ></category-echart>
    <div class="select-warn">
      <div class="warn-common warn-left">
       <div class="year_change">
       <el-date-picker
        class="sub-text"
        v-model="yearDate"
        @change="YearChange"
        :type="'year'"
        :value-format="'yyyy'"
        :placeholder="date"
      >
      </el-date-picker>
      </div>
         <chart-plot
          :chartType="'ChartBar'"
          :chartData="chartOptions"
        ></chart-plot>
      </div>
      <div class="warn-common">

        <div class="common-select">
           <el-select
            v-model="selectYear"
            @change="changeSelectYear"
            multiple
            :placeholder="$t('register.PSelect')"
            style="width:300px"
          >
            <el-option
              v-for="item in optionsYear"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="selectValue"
            @change="changeSelect"
            style="width:80px;margin-left:5px"
            :placeholder="$t('register.PSelect')"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <chart-plot
          :chartType="'ChartDouble'"
          :chartData="chartData"
        ></chart-plot>
      </div>
    </div>
  </div>
</template>

<script>
/**
 * @author: sunmengmeng
 * @since: 2021-09-15
 * @router('/dateSelect')
 */
import categoryItem from "./categoryItem.vue";
import categoryEchart from "./categoryEchart.vue";
import {
  weekTotal,
  getPowerLineData,
  headerTotal,
  yearTotal,
  MonthTotal,
  getWarn,
  contrastData,
  getYearList
} from "@/api/dashboard";
import * as util from "@/utils";
import { validUsername } from '@/utils/validate';
export default {
  name: "dateSelect",
  components: { categoryEchart, categoryItem },
  props: {},
  data() {
    const currentDay = this.$moment().format("YYYY-MM-DD");
    const currentYear = this.$moment().format("YYYY");
    const year = new Date().setFullYear(new Date().getFullYear() - 1);
    const preYear = this.$moment(year).format("YYYY");
    const nowYear = this.$moment(new Date().setFullYear(new Date().getFullYear())).format("YYYY");
    return {
      date: this.$t("curve.choiceDate"),
      selectValue: 0,
      yearDate:currentYear, //年电量中默认数据
      timeYear:[],//年电量对比选择的年份数据
      //年份对比 选择数据
      optionsYear:[

      ],
      selectYear:[],
      options: [
        {
          name: this.$t('dashbord.month'),
          value: 0,
          time: currentYear
        },
        {
          name: this.$t('dashbord.quarter'),
          value: 1,
          time: currentYear
        },
        {
          name: this.$t('dashbord.year'),
          value: 2,
          time: `${preYear},${currentYear}`
        }
      ],
      renderList: [],

      cateList: [
        {
          name: this.$t('dashbord.Daily'),
          icon: "offline_bolt",
          unit: "",
          value: 0,
          col:6
        },
          {
          name:this.$t('dashbord.Total'),
          icon: "request_quote",
          unit: "",
          value: 0,
          col:6
        },

        {
          name: this.$t('dashbord.NumberS'),
          icon: "workspaces_filled",
          unit: "",
          value: 0,
           col:6
        },

        {
          name: this.$t('dashbord.capacity'),
          icon: "workspaces_filled",
          unit: "",
          value: 0,
          col:6
        },
         {
          name: this.$t('dashbord.gross'),
          icon: "offline_bolt",
          unit: "",
          value: 0,
          col:6,
        },
          {
          name: this.$t('dashbord.health'),
          icon: "heart-ballon",
          unit: "%",
          value: 0,
          col:6,
        },{
          name: this.$t('dashbord.currentPower'),
          icon: "workspaces_filled",
          unit: "",
          value: 0,
          col:6,
        }
        //   {
        //   name: this.$t('dashbord.weather'),
        //   icon: "cloud_circle",
        //   unit: "",
        //   value: 0,
        //   col:12,
        // },
      ],
      dateType: "date",
      formateValue: "yyyy-MM-dd",
      dateTime: new Date(),
      showTime: true,
      echartsList: [
         {
          date: currentDay,
          show:true,
          data: {
            name: this.$t('dashbord.Power'),
            xAxis: [],
            yAxis: [],
            colors: ["#BEF4D0", "#70a436"]
          },
          type: "date",
          col:12,
          formate: "yyyy-MM-dd",
          chartType:'ChartLine'
        },
        {
          date: currentDay,
          show:false,
          data: {
            name: this.$t('dashbord.weekAmount'),
            xAxis: [],
            yAxis: [],
            colors: ["#BEF4D0", "#70a436"]
          },
          col:12,
          type: "week",
          formate: "yyyy-MM",
          chartType:'ChartBar'
        },
        {
          date: currentYear,
          show:true,
          data: {
            name: this.$t('dashbord.monthAmount'),
            xAxis: [],
            yAxis: [],
            colors: ["#BEF4D0", "#70a436"]
          },
          col:24,
          type: "month",
          formate: "yyyy-MM",
          chartType:'ChartBar'
        },


      ],
      chartData: {
        name: this.$t('dashbord.Charge'),
        xAxis: [],
        yAxis: []

      },
      chartOptions: {
        name: this.$t('dashbord.yearAmount'),
        xAxis: [],
        yAxis: [],
        colors: ["#BEF4D0", "#70a436"]
      },

    };
  },

  methods: {
   /**
    * @description: 获取年份数据
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   getYearList(){
   let param={
    stationId:util.readSession("userInfo"). stationId
   }
    getYearList(param).then((res)=>{
     if(res.code==200&&res.data){
      this.optionsYear=res.data
     let result=res.data
     if(this.optionsYear.length>1){
     this.selectYear=[result[result.length-2],result[result.length-1]]
      }else{
      this.chartData.current =result[0];
      this.chartData.pre = result[1];
      this.selectYear= result
      }
      this.changeSelect(0);

     }
    })
   },
   /**
    * @description: 年份选择
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   changeSelectYear(val){
    if(val.length>3){
     this.$message.error(this.$t('dashbord.max3Diff'))
     this.selectYear.splice(-1);
     return false
    }
    this.selectValue=0
    this.changeSelect(0)
   },
   /**
    * @description: 年总电量时间切换
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   YearChange(val){
    this.getYearTotal(val)
   },
   /**
    * @description:获取年电量数据
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   getYearTotal(date){
       const params = {
        time: date
      };
      yearTotal(params).then(resp => {
        if (resp.code === 200 && resp.data) {
          const { labels, records } = resp.data;
          this.chartOptions.xAxis = labels;
          this.chartOptions.yAxis = records;
        }
      });

   },

    /**
     * @description: 电量对比图 时间切换
     * @param {*} index
     * @return {*}
     * @author: JIXUYU
     */
    changeSelect(index) {
      const params = {
        paramType: index,
        time: this.selectYear.join(',')
      };
      contrastData(params).then(resp => {
        if (resp.code === 200&&resp.data) {
         this.chartData.xAxis = resp.data.labels;
         this.chartData.current = this.selectYear;
         //测试数据
         // this.chartData.yAxis = [{ name: '2021', data: ['123', '12', '0', '123', '12', '0'] }, { name: '2022', data: ['1233', '22', '0', '23', '12', '0'] }, { name: '2023', data: ['1233', '22', '0', '23', '12', '0'] }]
         //真实数据
         let reslut = resp.data.statisticeStationYearVo
         reslut.map((el) => {
          el.data=el.records
         })
         this.chartData.yAxis =reslut


        }
      });
    },
    gridRenderContent() {
      return [
        { label: this.$t('dashbord.serial'), prop: "alertconfigid" },
        { label: this.$t('dashbord.Device'), prop: "equipment" },
        { label: this.$t('dashbord.Alarm'), prop: "alertdetail" },
        { label: this.$t('dashbord.time'), prop: "alerttime" }
      ];
    },
    //获取报警信息
    getWarnList(currentDay, preDay) {
      const params = {
        alerttime_dategt: preDay,
        alerttime_datelt: currentDay,
        status: 0,
        current: 1,
        size: 8
      };
      getWarn(params).then(resp => {
        if (resp.code === 200) {
          this.renderList = resp.data.records;
        }
      });
    },
    //切换日期类型
    doDate(item) {
      this.dateTime = "";
      this.showTime = false;
      this.dateType = item.type;
      this.formateValue = item.formate;
      this.$nextTick(() => {
        this.showTime = true;
      });
    },
    dateChange() {
      this.getTotal(this.dateTime);
    },
    //获取总量
    getTotal(time) {
      const params = {
        time: time
      };
      headerTotal(params).then(resp => {
        if (resp.code === 200 && resp.data) {
          const {
            modules,
            modulesformat,
            income,
            monetaryunit,
            peakpower,
            peakpowerformat,
            energy,
            healthy,
            energyformat,
            totalenergy,
            weather,
            weatherUnit,
            totalIncome,
            currentPowerFormat,
            currentPower
          } = resp.data;
          this.cateList[0].value = energy;
          this.cateList[0].unit = energyformat;
          this.cateList[1].value = totalIncome;
          this.cateList[1].unit = monetaryunit;
          this.cateList[2].value = modules;
          this.cateList[2].unit = modulesformat;
          this.cateList[3].value = peakpower;
          this.cateList[3].unit = peakpowerformat;
          this.cateList[4].value = totalenergy;
          this.cateList[4].unit = energyformat;
          this.cateList[5].value = healthy;
          this.cateList[6].unit = currentPowerFormat;
          this.cateList[6].value = currentPower;
          // this.cateList[6].value = weather;
          // this.cateList[6].unit = weatherUnit;
          //  this.cateList[6].unit = energyformat;
        }
      });
    },

    /**
     * @description: //获取日功率图数据
     * @param {*} date
     * @return {*}
     * @author: JIXUYU
     */
    getDateTotal(date) {
     const stationId = util.readSession("userInfo"). stationId;
	 if(!stationId) return;
      const params = {
        time: date,
       stationId: stationId
      };
      getPowerLineData(params).then(resp => {
        if (resp.code === 200 && resp.data) {
          const { labels, records } = resp.data;
          this.echartsList[0].date = date;
          this.echartsList[0].data.xAxis = labels;
          this.echartsList[0].data.yAxis = records;
        }
      });
    },

    /**
     * @description: //获取周总量
     * @param {*} date
     * @return {*}
     * @author: JIXUYU
     */
    getWeekTotal(date) {
      const params = {
        time: date
      };
      weekTotal(params).then(resp => {
        if (resp.code === 200 && resp.data) {
          const { labels, records } = resp.data;
          this.echartsList[1].date = date;
          this.echartsList[1].data.xAxis = labels;
          this.echartsList[1].data.yAxis = records;
        }
      });
    },
    /**
     * @description: 获取月总量
     * @param {*} time
     * @return {*}
     * @author: JIXUYU
     */
    getMonthTotal(time) {
      const params = {
        time: time
      };
      MonthTotal(params).then(resp => {
        if (resp.code === 200 && resp.data) {
          const { labels, records } = resp.data;
          this.echartsList[2].date = time;
          this.echartsList[2].data.xAxis = labels;
          this.echartsList[2].data.yAxis = records;
        }
      });
    },
   /**
    * @description:
    * @param {*} item
    * @return {*}
    * @author: JIXUYU
    */
   changeDate(item) {
      const { date, type } = item;
      if (type === "month") {
        this.getMonthTotal(date);
      } else if(type === "date"){
        this.getDateTotal(date);
      }else if(type === "week"){
       this.getWeekTotal(date);
      }
    }
  },
  created() {
    const currentDay = this.$moment().format("YYYY-MM-DD");
    const currentMouth=this.$moment().format("YYYY-MM");
    const currentYear = this.$moment().format("YYYY");
    const year = new Date().setFullYear(new Date().getFullYear() - 1);
    const preYear = this.$moment(year).format("YYYY");
    const day = new Date().getTime() - 1000 * 60 * 60 * 24 * 7;
    const preDay = this.$moment(day).format("YYYY-MM-DD");
        //年份数据获取
    this.getYearList()
    this.getDateTotal(currentDay);
    this.getWeekTotal(currentDay);
    this.getTotal(currentDay);
    this.getYearTotal(currentYear);
    this.getMonthTotal(currentMouth);
    this.getWarnList(currentDay, preDay);

  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";

.dashboard {
  .dateSelect{
      .select-warn{
        div{
          top: 5px !important;
        }
      }
  }
}
</style>
