<!--
 * @Author: jixuyu
 * @Date: 2021-09-26 22:12:54
 * @LastEditTime: 2022-03-29 16:21:54
 * @LastEditors: JIXUYU
 * @Description: 
 * @FilePath: \front\src\views\device\components\historyMsg.vue
 * 记得注释
-->
<template>
  <div class="bee-component">
    <GnGrid
      ref="grid"
      :url="getRoleListsAPI"
      :params="gridOptions"
      style="width: 100%"
      :render-content="gridRenderContent"
      class="UserRoles-grid"
      :bodyParser="bodyParser"
    />
  </div>
</template>

<script>
export default {
  props: {
    gridOptions: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  components: {},
  // 定义属性
  data() {
    return {
      bodyParser: false,
      getRoleListsAPI: "/stationalert/list"
    };
  },

  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    gridRenderContent() {
      let detail = "alertdetailen";
      const lang = localStorage.getItem('lang')||'en';
      if(lang==='zh'){
        detail = "alertdetail";
      }
      return [
        { label: this.$t('device.detail'), prop: detail },
        { label: this.$t('device.time'), prop: "alerttime" }
      ];
    }
  },

};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
