var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "addUserDialog",
          attrs: {
            "label-width": "150px",
            rules: _vm.rules,
            model: _vm.ruleForm,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("register.userName"), prop: "username" } },
            [
              _c("el-input", {
                attrs: { readonly: _vm.props && _vm.props.type === "MODIFY" },
                model: {
                  value: _vm.ruleForm.username,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "username", $$v)
                  },
                  expression: "ruleForm.username",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("register.surname"), prop: "firstname" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.firstname,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "firstname", $$v)
                  },
                  expression: "ruleForm.firstname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("register.chanName"), prop: "lastname" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.lastname,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "lastname", $$v)
                  },
                  expression: "ruleForm.lastname",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("register.naromlRole"), prop: "roleId" } },
            [
              _c(
                "el-select",
                {
                  directives: [
                    {
                      name: "loadmore",
                      rawName: "v-loadmore",
                      value: _vm.loadMoreRole,
                      expression: "loadMoreRole",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: _vm.$t("register.Prole") },
                  model: {
                    value: _vm.ruleForm.roleId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "roleId", $$v)
                    },
                    expression: "ruleForm.roleId",
                  },
                },
                _vm._l(_vm.roleList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.roleName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("register.email"), prop: "email" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "email", $$v)
                  },
                  expression: "ruleForm.email",
                },
              }),
            ],
            1
          ),
          _vm.props && _vm.props.type === "ADD"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("PersonalMsg.initalPasw"),
                    prop: "password",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      "auto-complete": "new-password",
                    },
                    model: {
                      value: _vm.ruleForm.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "password", $$v)
                      },
                      expression: "ruleForm.password",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.props && _vm.props.type === "ADD"
            ? _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("register.ConfirmPassword"),
                    prop: "confimPassword",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "password",
                      "auto-complete": "new-password",
                    },
                    model: {
                      value: _vm.ruleForm.confimPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "confimPassword", $$v)
                      },
                      expression: "ruleForm.confimPassword",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.props && _vm.props.type === "MODIFY"
            ? _c(
                "el-form-item",
                { attrs: { label: _vm.$t("PersonalMsg.initalPasw") } },
                [
                  _c("el-input", {
                    attrs: { "auto-complete": "new-password" },
                    model: {
                      value: _vm.ruleForm.modifyPassword,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "modifyPassword", $$v)
                      },
                      expression: "ruleForm.modifyPassword",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("stationReport.stationList") } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    multiple: "",
                    filterable: "",
                    remote: "",
                    "reserve-keyword": "",
                    placeholder: _vm.$t("stationReport.SearchPowerStation"),
                    "remote-method": _vm.remoteMethod,
                    loading: _vm.loading1,
                  },
                  model: {
                    value: _vm.ruleForm.stationIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "stationIds", $$v)
                    },
                    expression: "ruleForm.stationIds",
                  },
                },
                _vm._l(_vm.stations, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.name + "|" + item.city,
                      value: item.id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "cancel",
              on: {
                click: function ($event) {
                  _vm.props.visible = false
                },
              },
            },
            [_vm._v(_vm._s(this.$t("stationReport.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "confirm",
              attrs: { loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("ruleForm")
                },
              },
            },
            [_vm._v(" " + _vm._s(this.$t("roleManagement.submit")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }