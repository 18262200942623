<!--
 * @Author: jixuyu
 * @Date: 2021-09-19 21:20:55
 * @LastEditTime: 2022-08-04 23:33:17
 * @LastEditors: jixuyu
 * @Description:
 * @FilePath: \front\src\views\config\components\inverterInfo.vue
 * 记得注释
-->
<template>
  <div class="inverterInfo" v-loading="loading" v-if="show">
    <div class="info-category">
      <div class="info-label">
        <p class="label-title">{{tabTitle}}</p>
        <p class="label-icon">
          <span
            @click="createComponent"
            class="iconfont icon-add_circle icon-plus"
          ></span>
        </p>
      </div>
      <Gn-Tab ref="firstTab" :tabsList="tabsList" @removeTab="removeTab" @changeTabs="changeTabs">
      </Gn-Tab>
    </div>
    <div class="info-category">
      <div class="info-label">
        <p class="label-title">{{secondTabTitle}}</p>
        <p class="label-icon">
          <span
            @click="createSecond"
            class="iconfont icon-add_circle icon-plus"
          ></span>
        </p>
      </div>
      <Gn-Tab ref="secondTab" :tabsList="secondList" @removeTab="removeTab" @changeTabs="changeSecondTabs"> </Gn-Tab>
    </div>
    <div class="info-bottom">
      <p class="info-btn" @click="saveInverter">{{ $t("config.save") }}</p>
    </div>
  </div>
</template>
// 第二步操作
<script>
import { setPv, stationDetail } from "@/api/config";
import * as util from "@/utils";
export default {
  components: {},
  props: {
    detailData: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  // 定义属性
  data() {
    return {
      currentIndex: 1,
      secondIndex: 1,
      isDeletedTab: false,
      loading: false,
      show: false,
      component: "categoryTop",
      tabTitle: this.$t("config.inverterName")+ 1,
      tabsList: [
        {
          title: this.$t("config.inverterName")+ 1,
          name: "1",
          content: "categoryTop",
          id: "",
          row: null,
        },
      ],
      secondComponent: "categoryBottom",
      secondTabTitle: this.$t("config.moduleName")+1,
      secondList: [
        {
          title: this.$t("config.moduleName")+1,
          name: "1",
          content: "categoryBottom",
          id: "",
          row: null,
        },
      ],
      secondFrom: {
        Modulepower: "",
        Connectortype: "",
        power: "",
      },
      ruleForm: {
        Label: "",
        ListCount: "",
        Manufacturer: "",
        TypeInfo: "",
        MaxPower: "",
        PreListMoudler: "",
      },
    };
  },
  // computed: {
  //   tabsList: {
  //     get () {
  //       return [
  //         {
  //           title: this.$t('config.inverterName'),
  //           name: "1",
  //           content: "categoryTop",
  //           id: "",
  //           row: null
  //         }
  //       ]
  //     },
  //     set (v) { }

  //   },
  //   secondList: {
  //     get () {
  //       return [
  //         {
  //           title: this.$t('config.Components'),
  //           name: "1",
  //           content: "categoryBottom",
  //           id: "",
  //           row: null
  //         }
  //       ]

  //     },
  //     set (v) { }

  //   }
  // },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    removeTab(data) {
      // console.log(data);
      if(data[0].row!==undefined && data[0].row.Id!==undefined)
        this.isDeletedTab = true;
    },
    changeTabs(val){
      this.tabTitle = val._props.label
    },
    changeSecondTabs(val){
      this.secondTabTitle = val._props.label;
    },
    //创建逆变器
    createComponent(item = null) {
      // this.$confirm(this.$t("config.inverterTips"), this.$t('common.hint'), {
      //       confirmButtonText: this.$t("config.confirm"),
      //       cancelButtonText: this.$t("config.cancel"),
      //       type: "info",
      //     })
      //       .then(() => {//确定
              this.currentIndex += 1;
              const obj = {
                title: this.$t("config.inverterName")+ this.currentIndex,
                content: "categoryTop",
                id: this.currentIndex,
                // name: this.currentIndex,
                row: item,
              };
              this.$refs["firstTab"].addTab(obj);
            // })
            // .catch(() => {});
    },
    //创建组件
    createSecond(item = null) {
      this.$confirm(this.$t("config.moduleTips"), this.$t('common.hint'), {
            confirmButtonText: this.$t("config.confirm"),
            cancelButtonText: this.$t("config.cancel"),
            type: "info",
          })
            .then(() => {//确定
              this.secondIndex += 1;
              const obj = {
                title: this.$t("config.moduleName")+this.secondIndex,
                content: "categoryBottom",
                id: this.secondIndex,
                // name: this.secondIndex,
                row: item,
              };
              this.$refs["secondTab"].addTab(obj);
            })
            .catch(() => {});
    },
    //保存逆变器
    saveInverter() {
      const mapTop = this.$refs["firstTab"].validForm("categoryTop");
      const mapBottom = this.$refs["secondTab"].validForm("categoryBottom");
      const booean = mapTop.every((item) => {
        return item === true;
      });
      const booean1 = mapBottom.every((item) => {
        return item === true;
      });
      if (booean && booean1) {
        const stationId = util.readSession("stationId");
        if (!stationId) return;
        const mapTop = this.$refs["firstTab"].getSingleForm("categoryTop");
        const mapBottom = this.$refs["secondTab"].getSingleForm("categoryBottom");
        const params = {
          inverters: mapTop,
          pvmoduletypes: mapBottom,
          stationId: stationId,
        };
        if(this.isDeletedTab){
        this.$confirm(this.$t("config.beDeleteInverterOrMoudel"), this.$t('common.warning'), {
          confirmButtonText: this.$t("config.confirm"),
          cancelButtonText: this.$t("config.cancel"),
            type: "warning",
          }).then(() => {
            setPv(params).then((resp) => {
              if (resp.code === 200) {
                if(params.inverters.length==0 || params.pvmoduletypes.length==0){
                  this.$emit("nextStep", 0);
                }else{
                  this.$emit("nextStep", 2);
                }
              }
            });
          }).catch(() => {});
        }else {
          setPv(params).then((resp) => {
            if (resp.code === 200) {
              this.$emit("nextStep", 2);
            }
          });
        }
      }
    },
    //回显电站设置
    getStationDetail() {
      const stationId = util.readSession("stationId");
      if (!stationId) return;
      // if (stationId === null) {
      //   const json = {
      //     title: this.$t("config.inverterName"),
      //     name: `1`,
      //     content: "categoryTop",
      //     id: 1,
      //     row: null,
      //   };
      //   this.tabsList.push(json);
      //   const json1 = {
      //     title: this.$t("config.Components"),
      //     name: `${1}`,
      //     content: "categoryBottom",
      //     id: 1,
      //     row: null,
      //   };
      //   this.secondList.push(json1);
      //   return;
      // }
      this.loading = true;
      const params = {
        stationId: stationId,
        step: 2,
      };
      stationDetail(params)
        .then((resp) => {
          if (resp.code === 200) {
            //逆变器-组串集合-组件集合
            if (resp.data) {
              const { inverters, pvmoduletypes } = resp.data;
              if (inverters!==undefined && inverters.length > 0) {
                this.tabsList = [];
                inverters.forEach((v, i) => {
                  const json = {
                    title: this.$t("config.inverterName")+v.Label,
                    name: `${i + 1}`,
                    content: "categoryTop",
                    id: i + 1,
                    row: v,
                  };
                  this.tabsList.push(json);
                  this.tabTitle = json.title;
                });
                this.currentIndex = inverters.length;
              }
              if (pvmoduletypes!==undefined && pvmoduletypes.length > 0) {
                this.secondList = [];
                pvmoduletypes.forEach((v, i) => {
                  const json = {
                    title: this.$t("config.moduleName")+(v.Manufacturer===undefined?"":(v.Manufacturer+"-"))+v.Modulepower,
                    name: `${i + 1}`,
                    content: "categoryBottom",
                    id: i + 1,
                    row: v,
                  };
                  this.secondList.push(json);
                  this.secondTabTitle = json.title;
                });
                this.secondIndex = pvmoduletypes.length;
              }
            }
            this.loading = false;
            this.show = true;
          }
        })
        .catch((err) => {
          this.loading = false;
          this.show = true;
        });
    },
  },
  created() {
    this.getStationDetail();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
