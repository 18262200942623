/*
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-09 16:57:20
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-09-20 22:16:06
 */


import actions from './actions.js'
import { ALLRESOURCE } from './action-type'

export default {
  namespaced: true,

    state: {
        //获取角色列表信息
        getRoleListsAPI: '',
        // 所有资源信息数据
        allResource: []
    },

    mutations: {
        [ALLRESOURCE](state, results) {
            state.allResource = results;
        }
    },

    actions
}
