var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "design" },
    [
      _c(
        "div",
        { staticClass: "design-step" },
        [
          _c(
            "el-steps",
            {
              attrs: {
                active: 1,
                "finish-status": "success",
                "align-center": true,
              },
            },
            _vm._l(_vm.stepList, function (item, index) {
              return _c("el-step", {
                key: index,
                class: [index === _vm.activeIndex ? "step-active" : ""],
                attrs: { status: item.status, title: item.name },
                nativeOn: {
                  click: function ($event) {
                    return _vm.changeComponent(item, index)
                  },
                },
              })
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(_vm.component, {
            tag: "component",
            attrs: { stationId: _vm.stationId, detailData: _vm.detailData },
            on: { nextStep: _vm.nextStep },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }