<!--
import { import } from '@babel/types';
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-28 20:50:40
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-04-26 15:36:40
-->

<template>
   <div class="control">
    <Gn-Tabs :datas="tabList" :firstTab="component" @changeTabs="changeTabs" />
     <transition name="fade-transform" mode="out-in">
      <component :is="component"></component>
    </transition> 

   </div>
</template>

<script>
import allControl from './components/allControl'
import childControl from './components/childControl'

/**
  * @author: JIXUYU
  * @since: 2021-11-07
  * @router('/control')
  */
export default {
  name:"control",
  components : {
   allControl,
   childControl
  },
  props : {},
  data () {
    return {
      component:'allControl',

    }
  },
  computed: {
   tabList() {
    return [
        {
          name:this.$t('control.MainSwitch'),
          component: "allControl"
        },
        {
          name:this.$t('control.SeparateSwitch'),
          component: "childControl"
        }
      ]
   }
  },

  methods : {
    /**
     * @description: 
     * @param {*} val
     * @return {*}
     * @author: JIXUYU
     */   
    changeTabs(val) {
      this.component = val;
    },
  },
  created () {},
  mounted () {}
}
</script>

<style lang="scss" scoped>
@import './style/index';
</style>