<!--
 * @Author: jixuyu
 * @Date: 2021-09-19 21:13:46
 * @LastEditTime: 2022-09-09 10:56:49
 * @LastEditors: JIXUYU
 * @Description:
 * @FilePath: \front\src\views\config\components\powerInfo.vue
 * 记得注释
-->
<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    label-width="120px"
    class="powerInfo"
  >
    <div class="info-item">
      <el-form-item
        :label="$t('config.stationName')"
        prop="name"
        class="item-flex"
      >
        <el-input v-model="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('config.Countries')"
        class="item-flex"
        prop="country"
      >
        <!-- <el-autocomplete
          style="width:100%"
          v-model="ruleForm.country"
          @select="event => doSelect(event, 'countryName')"
          @change="doChange"
          :fetch-suggestions="
            (search, cb) => querySearch(search, cb, countryList, 'countryName')
          "
          value-key="countryName"
        ></el-autocomplete> -->
        <!-- <el-select
          style="width: 100%"
          v-model="ruleForm.countryCode3"
          remote
          filterable
          :remote-method="remoteCountry"
          @focus="remoteCountry('')"
        >
          <el-option
            v-for="(item, index) of countryList"
            :key="index"
            :label="item.countryName"
            :value="item.countryCode3"
          >
          </el-option>
        </el-select> -->
        <el-input v-model="ruleForm.country"></el-input>
      </el-form-item>
    </div>
    <div class="info-item">
      <el-form-item :label="$t('config.ProjectNo')" class="item-flex">
        <el-input v-model="ruleForm.projectnum"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('config.Provinces')"
        prop="province"
        class="item-flex"
      >
        <!-- <el-autocomplete
          style="width:100%"
          v-model="ruleForm.province"
          @select="event => doSelect(event, 'provinceName')"
          :fetch-suggestions="
            (search, cb) => querySearch(search, cb, proviceList, 'provinceName')
          "
          value-key="provinceName"
        ></el-autocomplete> -->
        <!-- <el-select
          style="width: 100%"
          v-model="ruleForm.provinceCode"
          remote
          filterable
          :remote-method="(query) => remoteProvince(query)"
          @focus="remoteAllProvince(ruleForm.countryCode3)"
        >
          <el-option
            v-for="(item, index) of proviceList"
            :key="index"
            :label="item.provinceName"
            :value="item.code"
          >
          </el-option>
        </el-select> -->
        <el-input v-model="ruleForm.province"></el-input>
      </el-form-item>
    </div>
    <div class="info-item">
      <el-form-item :label="$t('config.Company')" class="item-flex">
        <el-input v-model="ruleForm.installcomany"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('config.City')"
        class="item-flex"
        prop="city"
      >
        <!-- <el-autocomplete
          style="width:100%"
          v-model="ruleForm.city"
          @select="event => doSelect(event, 'cityName')"
          :fetch-suggestions="
            (search, cb) => querySearch(search, cb, cityList, 'cityName')
          "
          value-key="cityName"
        ></el-autocomplete> -->
        <!-- <el-select
          style="width: 100%"
          v-model="ruleForm.cityCode"
          remote
          filterable
          :remote-method="remoteCity"
          @focus="remoteAllCity(ruleForm.provinceCode)"
        >
          <el-option
            v-for="(item, index) of cityList"
            :key="index"
            :label="item.cityName"
            :value="item.code"
          >
          </el-option>
        </el-select> -->
        <el-input v-model="ruleForm.city"></el-input>
      </el-form-item>
    </div>
    <div class="info-item">
      <el-form-item :label="$t('config.chargePerson')" class="item-flex">
        <el-input v-model="ruleForm.projectmanager"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('config.Area')"
        class="item-flex"
        prop="district"
      >
        <!-- <el-autocomplete
          style="width:100%"
          v-model="ruleForm.district"
          @select="event => doSelect(event, 'district')"
          :fetch-suggestions="
            (search, cb) => querySearch(search, cb, areaList, 'district')
          "
          value-key="district"
        ></el-autocomplete> -->
        <!-- <el-select
          style="width: 100%"
          v-model="ruleForm.districtCode"
          remote
          filterable
          :remote-method="remoteArea"
          @focus="remoteAllArea(ruleForm.cityCode)"
        >
          <el-option
            v-for="(item, index) of areaList"
            :key="index"
            :label="item.district"
            :value="item.disttictCode"
          >
          </el-option>
        </el-select> -->
        <el-input v-model="ruleForm.district"></el-input>
      </el-form-item>
    </div>
    <div class="info-item">
      <el-form-item :label="$t('config.Telephone')" class="item-flex">
        <el-input v-model="ruleForm.phone"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('config.Street')"
        prop="street"
        class="item-flex"
      >
        <el-input v-model="ruleForm.street"></el-input>
      </el-form-item>
    </div>
    <div class="info-item">
      <!-- <el-form-item label="邮编：" class="item-flex">
        <el-input v-model="ruleForm.postcode"></el-input>
      </el-form-item> -->
      <el-form-item
        :label="$t('config.Time')"
        class="item-flex"
        prop="gTimezoneId"
      >
        <!-- <el-input v-model="ruleForm.name"></el-input> -->
        <el-select
          v-model="ruleForm.gTimezoneId"
          :placeholder="$t('config.PselectEnter')"
          style="width: 100%"
        >
          <el-option
            v-for="item in timeList"
            :key="item.timezoneId"
            :label="lange==='en'?item.enLabel:item.zhLabel"
            :value="item.timezoneId"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </div>
    <div class="info-item info-elect">
      <el-form-item
        :label="$t('config.Price')"
        class="item-flex"
        prop="electrovalence"
      >
        <el-input v-model="ruleForm.electrovalence"></el-input>
        <el-select
          v-model="ruleForm.monetaryunit"
          :placeholder="$t('config.PriceCode')"
          style="width: 100%"
        >
          <el-option
            v-for="item in unitList"
            :key="item.currencyId"
            :label="item.symbol"
            :value="item.symbol"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('config.pictures')" class="item-flex">
        <div class="item-upload">
          <el-upload
            action=""
            :limit="limitNum"
            accept=".jpg, .jpeg, .png, .JPG, .JPEG, .PNG"
            :auto-upload="false"
            :file-list="fileList"
            :on-change="handleChange"
            :on-remove="removeFiles"
            :on-exceed="exceed"
            :show-file-list="true"
            list-type="picture-card"
            style="width: 450px;"
            :class="{hide:hideUploadEdit}"
            ref="uploadElement">
            <i slot="trigger" class="el-icon-plus"></i>
            <!-- <el-button size="small" style="width: 150px" type="primary"
              v-show="hideUploadEdit">{{
              this.$t("config.Upload")
            }}</el-button> -->
            <div slot="tip" class="el-upload__tip">{{ this.$t("config.tips") }}</div>
          </el-upload>
        </div>
      </el-form-item>
    </div>
    <div class="info-bottom">
      <p class="info-btn" @click="saveInfo">{{ this.$t("config.save") }}</p>
    </div>
  </el-form>
</template>

<script>
import {
  RulesPower,
  RuleCountry,
  RuleProvice,
  RuleCity,
  RuleStreet,
  RuleCommon,
  Ruledistrict,
  RuleElectrovalence,
} from "@/utils/validate";
import {
  saveConfig,
  editConfig,
  getMoneyKey,
  detailConfig,
  uploadStationPic
} from "@/api/config";
import { getCountry, getProvince, getCity, getArea } from "@/api/common";
import { getTimeZone, getCurrency } from "@/api/register";
import * as util from "@/utils";
export default {
  components: {},
  props: {
    stationId: {
      type: String,
      default: "",
    },
  },
  // 定义属性
  data() {
    return {
      lange: localStorage.getItem('lang')||'en',
      fileList: [],
      hideUploadEdit: false, // 隐藏'上传按钮'
      limitNum: 1, // 图片数量
      ruleForm: {
        name: "",
        country:"",
        projectnum: "",
        province: "",
        installcomany: "",
        city: "",
        phone: "",
        street: "",
        district: "",
        gTimezoneId: "",
        // postcode: "",
        picname: "",
        picBase64: "",
        monetaryunit: "",
        electrovalence: "",
        projectmanager: "",
      },
      timeList: [],
      rules: {
        name: RulesPower,
        country: RuleCountry,
        district: Ruledistrict,
        projectnum: RulesPower,
        country: RuleCountry,
        province: RuleProvice,
        city: RuleCity,
        // street: RuleStreet,
        monetaryunit: RuleCommon,
        electrovalence: RuleElectrovalence,
        gTimezoneId: RuleCommon,
      },
      countryList: [],
      proviceList: [],
      cityList: [],
      areaList: [],
      unitList: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    getMoneyKey() {
      // getMoneyKey().then((resp) => {
      //   if (resp.code === 200) {
      //     this.unitList = resp.data;
      //   }
      // });
      getCurrency().then((resp) => {
        if (resp.code === 200) {
          this.unitList = resp.data;
        }
      })
   },
   /**
    * @description:
    * @return {*}
    * @author: JIXUYU
    */
   removeFiles(file, fileList) {
      this.hideUploadEdit = fileList.length >= this.limitNum;
      this.ruleForm.picname = '';
      this.ruleForm.picBase64 = '';
    },
    // 上传图片超出数量限制时的钩子
    exceed(files, fileList){
      this.$message.error(this.$t("config.tipsMaximumUploads"))
    },
    //上传图片
    handleChange(file, fileList) {
      const { raw, name } = file;
      const types = ['image/jpeg', 'image/jpg', 'image/png'];
      const isImage = types.includes(raw.type);
      if (!isImage) {
        this.$refs.uploadElement.clearFiles();
        this.$message.error(this.$t("config.tipsWrongFileTpye"));
      } else {
        if (raw.size > 0 && raw.size/1024/1024 <= 1) {

          // 传文件
          let formData = new FormData();
          let photosArray = [];
          fileList.forEach(f => {
            photosArray.push(f.raw);
            formData.append("photos", f.raw);
          })
          formData.set("stationId", this.stationId);
          uploadStationPic(formData).then((resp) => {
          });

          this.hideUploadEdit = fileList.length >= this.limitNum;
        }else{
          this.$refs.uploadElement.clearFiles();
          this.$message.error(this.$t("config.tipsOverSize"));
        }
      }
    },
    //选中搜索
    // doSelect(item, name) {
    //   if (name === "countryName") {
    //     const { countryCode3, countryName } = item;
    //     this.ruleForm.countryCode3 = countryCode3;
    //     this.getProvince(countryCode3);
    //   } else if (name === "provinceName") {
    //     const { code, provinceName } = item;
    //     this.ruleForm.provinceCode = code;
    //     this.getCity(code);
    //   } else if (name === "cityName") {
    //     const { code, cityName } = item;
    //     this.ruleForm.cityCode = code;
    //     this.getArea(code);
    //   } else if (name === "district") {
    //     const { disttictCode } = item;
    //     this.ruleForm.districtCode = disttictCode;
    //   } else {
    //   }
    // },
    remoteCountry(query) {
      const params = {
        countryName: query,
        size: 60,
        current: 1,
      };
      getCountry(params).then((resp) => {
        if (resp.code === 200) {
          this.countryList = resp.data.records;
        }
      });
    },
    remoteProvince(query) {
      if (query !== "") {
        this.proviceList = this.proviceList.filter((item) => {
          return (
            item.provinceName.toLowerCase().indexOf(query.toLowerCase()) > -1
          );
        });
      } else {
        this.remoteAllProvince(this.ruleForm.countryCode3);
      }
    },
    //请求省份全部数据
    remoteAllProvince(countryCode3) {
      const params = {
        countryCode3,
      };
      getProvince(params).then((resp) => {
        if (resp.code === 200) {
          this.proviceList = resp.data;
        }
      });
    },
    remoteCity(query) {
      if (query !== "") {
        this.cityList = this.cityList.filter((item) => {
          return item.cityName.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      } else {
        this.remoteAllCity(this.ruleForm.provinceCode);
      }
    },

    remoteAllCity(provinceCode) {
      const params = {
        provinceCode,
      };
      getCity(params).then((resp) => {
        if (resp.code === 200) {
          this.cityList = resp.data;
        }
      });
    },

    remoteArea(query) {
      if (query !== "") {
        this.areaList = this.areaList.filter((item) => {
          return item.district.toLowerCase().indexOf(query.toLowerCase()) > -1;
        });
      } else {
        this.remoteAllCity(this.ruleForm.cityCode);
      }
    },
    remoteAllArea(cityCode) {
      const params = {
        cityCode,
      };
      getArea(params).then((resp) => {
        if (resp.code === 200) {
          this.areaList = resp.data;
        }
      });
    },
    //选择国家
    handleCountry(data) {
      const { countryCode3 } = data;
      this.getProvince(countryCode3);
    },
    //选择国家
    handlePro(data) {
      const { code } = data;
      this.getCity(code);
    },
    //选择城市
    handleCity(data) {
      const { code } = data;
      this.getArea(code);
    },
    //保存
    saveInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (!this.stationId) {
            saveConfig(this.ruleForm).then((resp) => {
              if (resp.code === 200) {
                util.writeSession("stationId", resp.data);
                this.$emit("nextStep", 1);
              }
            });
          } else {
            const params = Object.assign(this.ruleForm, { id: this.stationId });
            saveConfig(params).then((resp) => {
              if (resp.code === 200) {
                util.writeSession("stationId", this.stationId);
                this.$emit("nextStep", 1);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    doChange() {
      console.log("[ this.ruleForm ]", this.ruleForm);
    },
    //搜索国家
    // async querySearch(queryString, cb, countryList, key) {
    //   if (key === "countryName") {
    //     const params = {
    //       countryName: queryString,
    //       size: 20,
    //       current: 1,
    //     };
    //     getCountry(params).then((resp) => {
    //       if (resp.code === 200) {
    //         this.countryList = resp.data.records;
    //         cb(resp.data.records);
    //       }
    //     });
    //   } else {
    //     const restaurants = countryList;
    //     const results = queryString
    //       ? restaurants.filter(this.createFilter(queryString, key))
    //       : restaurants;
    //     // 调用 callback 返回建议列表的数据
    //     cb(results);
    //   }
    // },
    createFilter(queryString, key) {
      return (restaurant) => {
        return (
          restaurant[key].toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    //获取国家列表
    async getCountry(searchKey) {
      const params = {
        countryName: searchKey,
        size: 20,
        current: 1,
      };
      getCountry(params).then((resp) => {
        if (resp.code === 200) {
          this.countryList = resp.data.records;
          return resp.data.records;
        }
      });
    },

    //获取省份列表
    getProvince(countryCode3) {
      const params = {
        countryCode3,
      };
      getProvince(params).then((resp) => {
        if (resp.code === 200) {
          this.proviceList = resp.data;
        }
      });
    },
    //获取城市列表
    getCity(provinceCode) {
      const params = {
        provinceCode,
      };
      getCity(params).then((resp) => {
        if (resp.code === 200) {
          this.cityList = resp.data;
        }
      });
    },
    //获取区列表
    getArea(cityCode) {
      const params = {
        cityCode,
      };
      getArea(params).then((resp) => {
        if (resp.code === 200) {
          this.areaList = resp.data;
        }
      });
    },
    //获取时区
    async getTimeList() {
      const { code, data } = await getTimeZone();
      if (code == 200 && data) {
        this.timeList = data;
      }
    },
    //获取电站详情
    getStatioDetail(id) {
      const params = {
        id: id,
      };
      detailConfig(params).then((resp) => {
        if (resp.code === 200) {
          const { district, province, city, country } = resp.data;
          // this.remoteCountry(country);
          // this.remoteAllProvince(countryCode3);
          // this.remoteAllCity(provinceCode);
          // this.remoteAllArea(cityCode);
          for (let key of Object.keys(this.ruleForm)) {
            this.ruleForm[key] = resp.data[key];
          }
         if (resp.data.picname == '') {
            this.fileList=[];
            this.hideUploadEdit=false;
         } else {
             this.fileList = [
            {
              name: resp.data.picname,
              url: this.$t("common.picUrl") + this.stationId + "/" + resp.data.picname,
            },
          ];
            this.hideUploadEdit=true;
          }
        }
      });
    },
  },
  created() {
    this.getTimeList();
    this.getMoneyKey();
    const id = util.readSession("stationId");
    if (id) {
      this.getStatioDetail(id);
    }
  },
  mounted() {},
};
</script>
<style>
  .hide .el-upload--picture-card {
    display: none;
  }
</style>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
