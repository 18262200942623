<!--
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-10-23 10:31:03
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-08 16:01:09
-->
<template>
 <div class="password">
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-position='top'>
   <el-form-item :label="$t('register.email')" prop="email">
    <el-input v-model="ruleForm.email" :placeholder="$t('register.setEmail')"></el-input>
   </el-form-item>
   <el-form-item :label="$t('stationReport.code')" prop="code">
    <el-input v-model="ruleForm.code" :placeholder="$t('stationReport.Penter')"></el-input>
    <el-button @click="sendCode">{{this.$t('register.setCode')}}</el-button>
   </el-form-item>
   <el-form-item :label="$t('PersonalMsg.NewPasw')" prop="password">
    <el-input type="password" v-model="ruleForm.password" :placeholder="$t('register.setPassword')" auto-complete="new-password"></el-input>
   </el-form-item>

   <el-form-item class="form-btn">
    <el-button @click="props.visible = false" type="primary" round>{{this.$t('stationReport.cancel')}}</el-button>
    <el-button @click="submitForm('ruleForm')" type="success" round>{{this.$t('stationReport.confirm')}}</el-button>
   </el-form-item>
  </el-form>
 </div>
</template>

<script>
 import {
  sendEmail,
  submitCode
 } from "@/api/user";
 /**
  * @author: JIXUYU
  * @since: 2021-10-23
  * @router('/children')
  */
 export default {
  name: "password",
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
   return {
    ruleForm: {
     email:'',
     password:'',
     code:''


    },
    rules: {
     password: [{
       required: true,
       message: this.$t('register.PSetPassword'),
       trigger: "blur"
      },
      {
       validator: this.validatePass,
       trigger: "blur"
      },
     ],
     email: [{
       required: true,
       message: this.$t('register.PCsetEmail'),
       trigger: "blur"
      },
      {
       validator: this.validateemail,
       trigger: "blur"
      },
     ],
     code: [{
       required: true,
       message: this.$t('stationReport.PVerification'),
       trigger: "blur"
      },
      {
       validator: this.validateCode,
       trigger: ["blur"]
      },
     ],
    },
   }
  },
  methods: {
   /**
    * @description:
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   handelCancel(){


   },
   /**
    * @description:
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   submitForm(formName){
       let _this = this;
       this.$refs[formName].validate(async(valid) => {
        if (valid) {
         const params = this.ruleForm
         await submitCode(params).then((res) => {
          if (res.code === 200 ) {
           _this.$message.success(this.$t('register.resert'));
           setTimeout(() => {
            _this.$router.push("/Login");
           }, 1000);
          }
         });
        } else {
         return false;
        }
       });
   },
   /**
    * @description: 密码验证规则
    * @param {type}
    * @return:
    */
   validatePass(rule, value, callback) {
    const re = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,16}$/;
    if (re.test(value)) {
     callback();
    } else {
     callback(this.$t('addRules.setCodeNum'));
    }
   },
   /**
    * @description:
    * @param {*} rule
    * @param {*} value
    * @param {*} callback
    * @return {*}
    * @author: JIXUYU
    */
   validateemail(rule, value, callback) {
    const re = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    if (re.test(value)) {
     callback();
    } else {
     callback(this.$t('addRules.EmailFormat'));
    }
   },
   /**
    * @description:
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   sendCode(){
    if(this.ruleForm.email==''){
     this.$message.error(this.$t('addRules.PleaseEnterYourEmailAddressFirst'))
     return
    }else{
     sendEmail({email:this.ruleForm.email}).then((res)=>{})
    }

   },

  },
  created() {},
  mounted() {}
 }
</script>

<style lang="scss" scoped>
 @import '../style/index';
  .form-btn {
            text-align: right;

      }
</style>
