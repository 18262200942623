<!--
 * @Author: jixuyu
 * @Date: 2021-09-13 21:09:35
 * @LastEditTime: 2022-08-04 23:35:11
 * @LastEditors: JIXUYU
 * @Description:
 * @FilePath: \front\src\views\config\index.vue
 * 记得注释
-->
<template>
  <div class="design">
    <!-- <common-search></common-search> -->
    <div class="design-step">
      <el-steps :active="1" finish-status="success" :align-center="true">
        <el-step
          v-for="(item, index) of stepList"
          :key="index"
          :status="item.status"
          :class="[index === activeIndex ? 'step-active' : '']"
          :title="item.name"
          @click.native="changeComponent(item, index)"
        ></el-step>
      </el-steps>
    </div>
    <transition name="fade-transform" mode="out-in">
      <component
        :is="component"
        @nextStep="nextStep"
        :stationId="stationId"
        :detailData="detailData"
      ></component>
    </transition>
  </div>
</template>

<script>
import powerInfo from "./components/powerInfo.vue";
import layoutInfo from "./components/layoutInfo2.vue";
import inverterInfo from "./components/inverterInfo.vue";
import configInfo from "./components/configInfo.vue";
import collectInfo from "./components/collectInfo.vue";
import * as util from "@/utils";
import { stationDetail, detailConfig } from "@/api/config";
export default {
  name: "design",
  components: { collectInfo, configInfo, inverterInfo, layoutInfo, powerInfo },
  props: {},
  data() {
    return {
      activeIndex: 0,
      detailData: null,
      stepList: [
        {
          name: this.$t("config.plant"),
          component: "electricInfo",
          status: "process",
          component: "powerInfo",
        },
        {
          name: this.$t("config.Inverter"),
          component: "electricInfo",
          status: "wait",
          component: "inverterInfo",
        },
        {
          name: this.$t("config.Summary"),
          component: "electricInfo",
          status: "wait",
          component: "collectInfo",
        },
        {
          name: this.$t("config.setOptimizer"),
          component: "electricInfo",
          status: "wait",
          component: "configInfo",
        },
        {
          name: this.$t("config.Layout"),
          component: "electricInfo",
          status: "wait",
          component: "layoutInfo",
        },
      ],
      component: "powerInfo",
      installstep: 1,
      installed: false,
      stationId: "",
    };
  },

  computed: {},
  methods: {
    // 1 电站回显示
    // 2 保存时无校验
    // 3 优化器删除后无法添加
    // 4 布局设置自动播放
    // 5 布局设置超过当前时间不能拖动
    //切换组件
    changeComponent(item, index) {
      const stationId = util.readSession("stationId"); //确保第一步新建电站执行，获取电站id
      if (!stationId) {
        this.$message.success(this.$t("config.PnewPower"));
        return;
      }
      const st = util.readSession("stepIndex");
      this.installstep = Math.max(this.installstep, Number(st));
      if (Number(index) > this.installstep) {
        this.$message.success(this.$t("config.Pcomplete"));
        return;
      }
      this.stepList.forEach((v, i) => {
        if (index >= i) {
          v.status = "process";
        } else {
          v.status = "wait";
        }
      });
      this.activeIndex = index;
      this.component = item.component;
      util.writeSession("stepIndex", index);
    },
    //切换步骤
    nextStep(number) {
      //已安装电站
      const index = Number(number);
      this.stepList.forEach((v, i) => {
        if (index >= i) {
          v.status = "process";
        } else {
          v.status = "wait";
        }
      });
      this.activeIndex = index;
      this.component = this.stepList[index].component;
      util.writeSession("stepIndex", index);
    },
    //获取电站详情
    getStatioDetail(id) {
      const params = {
        id: id,
      };
      detailConfig(params).then((resp) => {
        if (resp.code === 200) {
          const { installstep, installed } = resp.data;
          this.installstep = installstep - 1;
          this.installed = installed;
        }
      });
    },
    //回显电站设置
    getStationDetail() {
      const stationId = util.readSession("stationId");
      if (!stationId) return;
      const params = {
        stationId: stationId,
      };
      stationDetail(params).then((resp) => {
        console.log("[ resp ]", 565656);
        if (resp.code === 200) {
          //逆变器-组串集合-组件集合
          this.detailData = resp.data;
        }
      });
    },
    reshowStation(stationId) {
      const params = {
        stationId: stationId,
      };
      stationDetail(params).then((resp) => {
        if (resp.code === 200) {
          //逆变器-组串集合-组件集合
          this.detailData = resp.data;
        }
      });
    },
  },
  created() {
    if (this.$route.query.stationId) {
      if (this.$route.query.stationId == "new") {
        const step = util.readSession("stepIndex");
        this.stationId = "";
        util.removeSession("stationId");
        if (step) {
          this.installstep = Number(step) + 1;
        }
      } else {
        const { stationId } = this.$route.query;
        this.stationId = stationId;
        util.writeSession("stationId", stationId);
        this.reshowStation(stationId);
        this.getStatioDetail(stationId);
      }
    } else {
      const id = util.readSession("stationId");
      if (id) {
        this.stationId = id;
        this.getStatioDetail(id);
      } else {
        const userData = util.readSession("userInfo");
        if (userData && userData.stationId) {
          this.stationId = userData.stationId;
          util.writeSession("stationId", userData.stationId);
          this.getStatioDetail(userData.stationId);
        } else {
          const step = util.readSession("stepIndex");
          this.stationId = "";
          if (step) {
            this.installstep = Number(step) + 1;
          }
        }
      }
    }
    // this.getStationDetail();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "./style/index.scss";
</style>
