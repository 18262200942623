var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "password" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("register.email"), prop: "email" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("register.setEmail") },
                model: {
                  value: _vm.ruleForm.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "email", $$v)
                  },
                  expression: "ruleForm.email",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("stationReport.code"), prop: "code" } },
            [
              _c("el-input", {
                attrs: { placeholder: _vm.$t("stationReport.Penter") },
                model: {
                  value: _vm.ruleForm.code,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "code", $$v)
                  },
                  expression: "ruleForm.code",
                },
              }),
              _c("el-button", { on: { click: _vm.sendCode } }, [
                _vm._v(_vm._s(this.$t("register.setCode"))),
              ]),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("PersonalMsg.NewPasw"), prop: "password" },
            },
            [
              _c("el-input", {
                attrs: {
                  type: "password",
                  placeholder: _vm.$t("register.setPassword"),
                  "auto-complete": "new-password",
                },
                model: {
                  value: _vm.ruleForm.password,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "password", $$v)
                  },
                  expression: "ruleForm.password",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "form-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: {
                    click: function ($event) {
                      _vm.props.visible = false
                    },
                  },
                },
                [_vm._v(_vm._s(this.$t("stationReport.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success", round: "" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v(_vm._s(this.$t("stationReport.confirm")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }