<!--
import { import } from '@babel/types';
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-14 10:12:38
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-24 15:30:24
-->
<template>
  <div class="stationReport">
    <div class="sation_top">
      <el-input v-model="gridOptions.name" :placeholder="$t('stationReport.stationName')" class="text_ipt"></el-input>
      <el-input v-model="gridOptions.country" :placeholder="$t('stationReport.country')" class="text_ipt"></el-input>
        <el-input v-model="gridOptions.city" :placeholder="$t('stationReport.city')" class="text_ipt"></el-input>
      <el-input v-model="gridOptions.district" :placeholder="$t('stationReport.district')" class="text_ipt"></el-input>
      <el-input v-model="gridOptions.unitId" placeholder="beehive" class="text_ipt"></el-input>
      <el-button type="primary" @click="handelSearch">{{$t('stationReport.search')}}</el-button>
            <el-button type="primary" @click="setStation"><i class="el-icon-plus"></i>{{$t('stationReport.addStation')}}</el-button>
    </div>

    <Gn-Tabs :datas="tabList" :firstTab="component" @changeTabs="changeTabs" />
    <transition name="fade-transform" mode="out-in">
      <component :is="component" :gridOptions="gridOptions" ref="table"></component>
    </transition>
    <div class="report-world">
     <div id='myMap' style='width:100%; height: 400px;'></div>
      <!-- <chart-plot :chartType="'ChartWorld'" :datas="chartData"></chart-plot> -->
    </div>
  </div>
</template>

<script>
import initBingMap from '@/utils/initMap'
import station from './components/station';
import unStation from './components/unStation';
import { StationLatLonList } from '@/api/station'
import * as util from "@/utils";

/**
 * @author: JIXUYU
 * @since: 2021-09-20
 * @router('/stationReport')
 */
export default {
  name: "stationReport",
  components: {
   station,
   unStation


  },
  mixins:[initBingMap],
  props: {},
  data() {
    return {
     chartData:[],
     paramsData:{},
     gridOptions:{
      installed:true,
      country:'',//国家
      province:'',//省
      city:'',//市
      district:'',//区
      unitId:'',//beehive
      name:'',//电站名称
     },
     component:'station',
    };
  },
  computed:{
   tabList(){
    return  [
       {
          name: this.$t('stationReport.stationList'),
          component: "station"
        },
        {
          name: this.$t('stationReport.addList'),
          component: "unStation"
        }
      ]
   }
  },
  methods: {
    /**
     * @description: 电站坐标位置展示
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    getStation(){
     StationLatLonList().then((res)=>{
      if(res.code==200&&res.data){
       this.chartData=res.data
        this.loadMap();
      }
     })
    },
   /**
    * @description: 跳转电站新增
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   setStation () {
    util.removeSession("stationId");
    util.writeSession("stepIndex", 0);
      this.$router.push({path:'/config',query:{stationId:'new'}})
   },
   /**
    * @description: 列表&&安装列表切换
    * @param {*} val
    * @return {*}
    * @author: JIXUYU
    */
   changeTabs(val) {
      if (val === 'station') {
        this.gridOptions.installed = true
      }else {
        this.gridOptions.installed = false
      }
      this.component = val;
    },
    /**
     * @description: 列表数据查询
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    handelSearch(){
      this.$refs.table.$children[0].tableInitial()
    },
    /**
     * @description: 重置操作
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    handelResert(){
     this.gridOptions={
      installed:true,
      country:'',//国家
      province:'',//省
      city:'',//市
      district:'',//区
      unitId:'',//beehive
      name_like:'',//电站名称
     }

    },
    /**
     * @description:
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    loadMap(){
    let map = new Microsoft.Maps.Map('#myMap', {
     zoom:4
    });
    let that=this
    let infobox = new Microsoft.Maps.Infobox(map.getCenter(), {
            visible: false
        });
        infobox.setMap(map);
        if(that.chartData.length==0){
         return
        }

      let locList=[]
      that.chartData.forEach(item => {

     {
          	var loc = new Microsoft.Maps.Location(item.value[1], item.value[0])
           var pin = new Microsoft.Maps.Pushpin(loc);
         //信息框内容 单位调整
         locList.push(loc)
            pin.metadata = {
                title: item.name,
                description:that.$t('stationReport.peakPower')+':'+item.value[2]+'kW'
            };
            //图钉点击
            Microsoft.Maps.Events.addHandler(pin, 'click', pushpinClicked);

            //添加到地图

            map.entities.push(pin);

        }
        });


     function  pushpinClicked(e) {
        //Make sure the infobox has metadata to display.
        if (e.target.metadata) {
            //Set the infobox options with the metadata of the pushpin.
            infobox.setOptions({
                location: e.target.getLocation(),
                title: e.target.metadata.title,
                description: e.target.metadata.description,
                visible: true
            });
        }
    }


	},
  /**
   * 从地址栏获取要显示的tab
   */
   getTab(){
    let tab = "station";
    let url = window.location.href;
    if(url.indexOf('?')>-1){
      let queryUrl = url.split('?')[1];
      let params = new URLSearchParams('?'+queryUrl);  //将参数放在URLSearchParams函数中
      let str = params.get('tab');
      if(str && str.toUpperCase()==='UNSTATION') tab = "unStation";
	  }
    return tab;
   }
  },
  mounted() {

  this.$nextTick(()=>{
     initBingMap.init().then((Microsoft) => {
            this.getStation()
            console.log("加载成功...");

        })

  })
  }

};
</script>

<style lang="scss" scoped>
@import "./style/index";
</style>
