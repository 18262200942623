var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "powerInfo",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "120px" },
    },
    [
      _c(
        "div",
        { staticClass: "info-item" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.stationName"), prop: "name" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "name", $$v)
                  },
                  expression: "ruleForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.Countries"), prop: "country" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.country,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "country", $$v)
                  },
                  expression: "ruleForm.country",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info-item" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.ProjectNo") },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.projectnum,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "projectnum", $$v)
                  },
                  expression: "ruleForm.projectnum",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.Provinces"), prop: "province" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.province,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "province", $$v)
                  },
                  expression: "ruleForm.province",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info-item" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.Company") },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.installcomany,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "installcomany", $$v)
                  },
                  expression: "ruleForm.installcomany",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.City"), prop: "city" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.city,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "city", $$v)
                  },
                  expression: "ruleForm.city",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info-item" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.chargePerson") },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.projectmanager,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "projectmanager", $$v)
                  },
                  expression: "ruleForm.projectmanager",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.Area"), prop: "district" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.district,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "district", $$v)
                  },
                  expression: "ruleForm.district",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info-item" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.Telephone") },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "phone", $$v)
                  },
                  expression: "ruleForm.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.Street"), prop: "street" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.street,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "street", $$v)
                  },
                  expression: "ruleForm.street",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info-item" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.Time"), prop: "gTimezoneId" },
            },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: _vm.$t("config.PselectEnter") },
                  model: {
                    value: _vm.ruleForm.gTimezoneId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "gTimezoneId", $$v)
                    },
                    expression: "ruleForm.gTimezoneId",
                  },
                },
                _vm._l(_vm.timeList, function (item) {
                  return _c("el-option", {
                    key: item.timezoneId,
                    attrs: {
                      label: _vm.lange === "en" ? item.enLabel : item.zhLabel,
                      value: item.timezoneId,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "info-item info-elect" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.Price"), prop: "electrovalence" },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.electrovalence,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "electrovalence", $$v)
                  },
                  expression: "ruleForm.electrovalence",
                },
              }),
              _c(
                "el-select",
                {
                  staticStyle: { width: "100%" },
                  attrs: { placeholder: _vm.$t("config.PriceCode") },
                  model: {
                    value: _vm.ruleForm.monetaryunit,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "monetaryunit", $$v)
                    },
                    expression: "ruleForm.monetaryunit",
                  },
                },
                _vm._l(_vm.unitList, function (item) {
                  return _c("el-option", {
                    key: item.currencyId,
                    attrs: { label: item.symbol, value: item.symbol },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.pictures") },
            },
            [
              _c(
                "div",
                { staticClass: "item-upload" },
                [
                  _c(
                    "el-upload",
                    {
                      ref: "uploadElement",
                      class: { hide: _vm.hideUploadEdit },
                      staticStyle: { width: "450px" },
                      attrs: {
                        action: "",
                        limit: _vm.limitNum,
                        accept: ".jpg, .jpeg, .png, .JPG, .JPEG, .PNG",
                        "auto-upload": false,
                        "file-list": _vm.fileList,
                        "on-change": _vm.handleChange,
                        "on-remove": _vm.removeFiles,
                        "on-exceed": _vm.exceed,
                        "show-file-list": true,
                        "list-type": "picture-card",
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "trigger" },
                        slot: "trigger",
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip",
                        },
                        [_vm._v(_vm._s(this.$t("config.tips")))]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "info-bottom" }, [
        _c("p", { staticClass: "info-btn", on: { click: _vm.saveInfo } }, [
          _vm._v(_vm._s(this.$t("config.save"))),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }