/*
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-03 11:27:00
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-09-20 21:40:44
 */


export default {
  methods: {
    /**
     * @param {String|Array} loading: loading状态字段 必须最外层
     * @param {Promise} API: 接口方法
     * @param {Object} params: 接口参数
     * @return {}
     */
    async MixinsLoading(loading, API, params) {
      try {
        this._MixinsLoadingToStatus(loading);
        const results = await API.call(this, params);

        this._MixinsLoadingToStatus(loading, false);
        return results;
      } catch (error) {
        this._MixinsLoadingToStatus(loading, false);
        return false;
      }
    },

    _MixinsLoadingToStatus(loading, flag = true) {
      if (Array.isArray(loading)) {
        loading.forEach((val) => (this[val] = flag));
      } else {
        this[loading] = flag;
      }
    },
  },
};
