<!--
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-20 20:30:44
 * @LastEditors: jixuyu
 * @LastEditTime: 2022-06-27 16:25:47
-->
<template>
  <div class="GNTabs">
    <el-tabs
      v-model="editableTabsValue"
      type="card"
      @tab-remove="removeTab"
      @tab-click="changeTabs"
    >
      <el-tab-pane
        v-for="item in editableTabs"
        :closable="closable"
        :key="item.name"
        :label="item.title"
        :name="item.name"
      >
        <component
          :is="componentName"
          :ref="item.content"
          :childrenData="item"
          @closeTabs="closeTabs"
        ></component>
      </el-tab-pane>
      <el-tab-pane
        v-if="isAdd"
        key="0000"
        name="0000000"
        disabled
        @click="addPane"
      >
        <p slot="label" @click="addPane" class="pane-add">+</p>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
/**
 * @author: JIXUYU
 * @since: 2020-09-09
 * @router('/index')
 */

import categoryTop from "@/views/config/components/categoryTop.vue";
import categoryBottom from "@/views/config/components/categoryBottom";
import configForm from "@/views/config/components/configForm";
import configNb from "@/views/config/components/configNb";
export default {
  name: "Gn-Tab",
  props: {
    tabsList: {
      type: Array,
      default() {
        return [];
      },
    },
    isAdd: {
      type: Boolean,
      default: false,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    categoryBottom,
    categoryTop,
    configForm,
    configNb,
  },
  data() {
    const that = this;
    return {
      editableTabsValue: `${that.tabsList.length}`,
      editableTabs: that.tabsList,
      componentName: that.tabsList[0].content,
      tabIndex: 1,
      cacheList: [],
      childrenObj: that.tabsList[that.tabsList.length - 1],
      shareId: 0,
    };
  },
  watch: {},
  methods: {
    /**
     * @description: 自动关闭弹窗
     * @param {*}
     * @return {*}
     */

    closeTabs(id) {
      let name = "";
      this.editableTabs.forEach((e, i) => {
        if (e.id === id) {
          name = e.name;
        }
      });
      this.removeTab(name);
    },
    /**
     * @description:
     * @param {type}
     * @return {type}
     * @author: JIXUYU
     */
    changeTabs(val) {
      const shareId = Number(val.index);
      this.shareId = shareId;
      this.$emit("changeTabs", val);

      const filter = this.editableTabs.filter((v) => v.name === val.name);
      if (filter) {
        this.childrenObj = filter[0];
      }
      this.$refs[this.componentName][shareId] &&
        this.$refs[this.componentName][shareId].handleInitial(
          this.editableTabs[shareId]
        );
    },
    //默认加载时传入第一个tab数据
    sendFirstData() {
      const shareId = this.shareId;
      this.$refs[this.componentName][shareId] &&
        this.$refs[this.componentName][shareId].handleInitial(
          this.editableTabs[shareId]
        );
    },

    //获取对应tab的表单数据
    getSingleForm(name) {
      const ref = this.$refs[name];
      const map = ref.map((v) => v.ruleForm);
      return map;
    },
    //校验数据
    validForm(name) {
      const ref = this.$refs[name];
      const map = ref.map((v) => v.validateForm());
      return map;
    },
    /**
     * @description: 子项内容新增
     * @param {type}
     * @return {type}
     * @author: JIXUYU
     */
    addTab(val) {
      //详情 查看内容 不做修改
      this.childrenObj = null;
      this.childrenObj = val;
      this.componentName = val.content;
      let lastIndex = _.indexOf(this.cacheList, val.id);
      if (lastIndex != -1) {
        return false;
      }
      this.cacheList.push(val.id);
      let newTabName = ++this.tabIndex + "";
      const ref = this.$refs[this.componentName][this.shareId];
      this.editableTabs.push({
        title: val.title,
        name: `${val.id}`,
        content: val.content,
        id: val.id,
        row: val.row,
      });
      this.shareId = this.editableTabs.length - 1;
      this.editableTabsValue = `${val.id}`;
    },
    /**
     * @description: 标签项内容回删
     * @param {type}
     * @return {type}
     * @author: JIXUYU
     */
    removeTab(targetName) {
      let tabs = this.editableTabs;
      let otherId = "";
      this.editableTabs.forEach((item) => {
        if (targetName == item.name) {
          return (otherId = item.id);
        }
      });
      this.cacheList = this.cacheList.filter(function (item) {
        return item !== otherId;
      });
      let activeName = targetName;
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            let nextTab = tabs[index + 1] || tabs[index - 1];
            if (nextTab) {
              activeName = nextTab.name;
            }
          }
        });
      }
      this.editableTabsValue = activeName;
      this.editableTabs = tabs.filter((tab) => tab.name !== targetName);
      const filter = this.editableTabs.filter((v) => v.name === activeName);
      if (filter) {
        this.childrenObj = filter[0];
      }
      const currentTab = tabs.filter((tab) => tab.name === targetName);
      this.shareId = this.editableTabs.length - 1;

      this.$refs[this.componentName][this.shareId] &&
        this.$refs[this.componentName][this.shareId].handleInitial(
          this.editableTabs[this.shareId]
        );
      this.$emit("removeTab", currentTab);
    },
    addPane() {
      this.$emit("addPane");
    },
    addConfigForm() {
      this.$refs[this.componentName][this.shareId] &&
        this.$refs[this.componentName][this.shareId].addForm();
    },
  },
  created() {
    // console.log(this.editableTabsValue, this.tabsList);
  }

};
</script>

<style lang="scss">
@import "./style/index";
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #edfaf2 !important;
  color: #33c863;
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
}
.el-tabs--card > .el-tabs__header .el-tabs__nav {
  display: flex;
}
.is-top {
  border: none !important;
}
.el-tabs--card > .el-tabs__header .el-tabs__item {
  background-color: #fff;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
  color: #929292;
}

.pane-add {
  height: 32px;
  width: 40px;
  font-size: 20px;
  color: #33c863;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}
</style>
