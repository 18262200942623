var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "register" },
    [
      _c("div", { staticClass: "TopBar" }, [
        _c("div", { staticClass: "topbar-left" }, [
          _c("span", { staticClass: "left-front" }, [
            _vm._v(_vm._s(_vm.$t("register.title"))),
          ]),
          _c("span", { staticClass: "left-back" }),
        ]),
        _c("div", { staticClass: "topbar-right" }, [
          _c(
            "div",
            { staticClass: "right-lang" },
            [_c("lang", { staticClass: "lang" })],
            1
          ),
        ]),
      ]),
      _c("span", { staticClass: "register_title" }, [
        _vm._v(_vm._s(_vm.$t("register.registerAccount"))),
      ]),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "box_from",
          attrs: { model: _vm.ruleForm, rules: _vm.rules },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("register.userName"),
                        prop: "username",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.$t("register.setName") },
                        model: {
                          value: _vm.ruleForm.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "username", $$v)
                          },
                          expression: "ruleForm.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("register.password"),
                        prop: "password",
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          placeholder: _vm.$t("register.setPassword"),
                          "auto-complete": "new-password",
                        },
                        model: {
                          value: _vm.ruleForm.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "password", $$v)
                          },
                          expression: "ruleForm.password",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("register.email"), prop: "email" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: _vm.$t("register.setEmail") },
                        model: {
                          value: _vm.ruleForm.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "email", $$v)
                          },
                          expression: "ruleForm.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("register.Verification"),
                        prop: "captcha",
                      },
                    },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            maxlength: "4",
                            placeholder: _vm.$t("register.PEnter"),
                          },
                          model: {
                            value: _vm.ruleForm.captcha,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "captcha", $$v)
                            },
                            expression: "ruleForm.captcha",
                          },
                        },
                        [
                          _c(
                            "template",
                            { slot: "append" },
                            [
                              _c(
                                "el-button",
                                { on: { click: _vm.getVerify } },
                                [_vm._v(_vm._s(_vm.captchaButtonText))]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticClass: "form-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", round: "" },
                  on: { click: _vm.goLogin },
                },
                [_vm._v(_vm._s(_vm.$t("register.back")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "success", round: "" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("register.register")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }