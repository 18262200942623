/*
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-11-03 22:18:43
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-11-28 10:11:34
 */
import request from "@/utils/request";
/**
 * @description: 
 * @param {*} params
 * @return {*}
 * @author: JIXUYU
 */
export function stationRemove(params) {
  return request({
    url: "/station/remove",
    method: "POST",
    params: params
  });
}

export function StationLatLonList(params) {
 return request({
   url: "/station/queryStationLatLonList",
   method: "get",
   params: params
 });
}
