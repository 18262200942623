var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    { attrs: { trigger: "click" }, on: { command: _vm.changeLanguage } },
    [
      _c("div", { staticStyle: { "font-size": "22px", cursor: "pointer" } }, [
        _c("label", { staticStyle: { "font-size": "14px", color: "white" } }, [
          _vm._v(_vm._s(this.$t("login.switchLang"))),
        ]),
        _vm._v("🌍 "),
      ]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            { attrs: { command: "zh", disabled: "zh" === _vm.$i18n.locale } },
            [
              _c("img", {
                staticStyle: {
                  width: "30px",
                  height: "20px",
                  position: "relative",
                  top: "5px",
                },
                attrs: { src: require("../../assets/login/china.jpg") },
              }),
              _vm._v(" " + _vm._s(this.$t("login.china"))),
            ]
          ),
          _c(
            "el-dropdown-item",
            { attrs: { command: "en", disabled: "en" === _vm.$i18n.locale } },
            [
              _c("img", {
                staticStyle: {
                  width: "30px",
                  height: "20px",
                  position: "relative",
                  top: "5px",
                },
                attrs: { src: require("../../assets/login/usa.jpg") },
              }),
              _vm._v(" " + _vm._s(this.$t("login.english"))),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }