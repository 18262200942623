/*
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2022-05-07 10:21:06
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-08 15:35:38
 */
import * as util from "@/utils/index";

export default {
 init: function (){
     const lang = localStorage.getItem('lang')?localStorage.getItem('lang'):'zh'
     const bingKey = 'Asy9xelO7rE2EUiUsUZazUGH6CBwemERVdHu_mXXcPCxjIdDmY9Bapx6OUXQsbYc';
     const BingMapUrl = 'https://www.bing.com/api/maps/mapcontrol?setLang='+ lang +'&key=' + bingKey;
     return new Promise((resolve, reject) => {
       if(typeof Microsoft !== "undefined") {
         resolve(Microsoft);
         return true;
       }

       // 插入script脚本
       let scriptNode = document.createElement("script");
       scriptNode.setAttribute("type", "text/javascript");
       scriptNode.setAttribute("src", BingMapUrl);
       document.body.appendChild(scriptNode);

       // 等待页面加载完毕回调
       let timeout = 0;
       let interval = setInterval(() => {
         // 超时10秒加载失败
         if(timeout >= 20) {
           reject();
           clearInterval(interval);
         }
         // 加载成功
         if(typeof Microsoft !== "undefined") {
           resolve(Microsoft);
           clearInterval(interval);
         }
         timeout += 1;
       }, 500);
   });
 }
}
