import request from "@/utils/request";

export async function API(data) {
  if (data.params) {
    return request({
      url: `${data.url}`,
      method: data.method,
      params: data.params,
    });
  } else {
    return request({
      url: `${data.url}`,
      method: data.method,
      data: data.data,
    });
  }
}