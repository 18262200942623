/*
 * @Author: sun.mengmeng
 * @Date: 2021-09-28 15:33:28
 * @LastEditors: jixuyu
 * @LastEditTime: 2021-09-28 22:55:01
 * @Description: file content
 */
import request from "@/utils/request";

//获取国家信息
export function getCountry(params) {
  return request({
    url: "/global/countryList",
    method: "get",
    params: params
  });
}

//获取省份信息
export function getProvince(params) {
  return request({
    url: "/global/getProvince",
    method: "get",
    params: params
  });
}

//获取城市信息
export function getCity(params) {
  return request({
    url: "/global/getCity",
    method: "get",
    params: params
  });
}

//获取城市信息
export function getArea(params) {
  return request({
    url: "/global/getArea",
    method: "get",
    params: params
  });
}

//获取时区列表
export function getZone() {
  return request({
    url: `/dist/list/type=timezone`,
    method: "get",
  });
}
