var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Gn-Tab", {
        ref: "firstTab",
        attrs: { tabsList: _vm.tabsList, isAdd: true },
        on: { addPane: _vm.createComponent },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }