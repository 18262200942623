/*
 * @Author: sun.mengmeng
 * @Date: 2021-09-14 08:52:16
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-31 09:41:10
 * @Description: file content
 */
import Vue from "vue";
import "normalize.css/normalize.css"; // A modern alternative to CSS resets
import "@/assets/iconfont/iconfont.css";
import "@/assets/scss/common.scss";
import 'default-passive-events';
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en"; // lang i18n
import "@/styles/index.scss"; // global css
import i18n from "@/lang";
import App from "./App";
import store from "./store";
import router from "./router";
import moment from 'moment'
import "@/icons"; // icon
import "@/permission"; // permission control
import GnComponents from "@/components";
import { directive } from "./directive/btnpermissions";
import _ from "lodash";
import fabric from 'fabric';

//使用颜色插件
import vcolorpicker from "vcolorpicker";
Vue.use(vcolorpicker);
if (process.env.NODE_ENV === "production") {
  const { mockXHR } = require("../mock");
  mockXHR();
}
Vue.prototype.$moment = moment;

Vue.use(ElementUI, { locale,size:'small' });
// 如果想要中文版 element-ui，按如下方式声明
directive()
Vue.directive("loadmore", {
 bind(el, binding) {
   const SELECTWRAP_DOM = el.querySelector(
     ".el-select-dropdown .el-select-dropdown__wrap"
   );
   SELECTWRAP_DOM.addEventListener("scroll", function () {
     const CONDITION =
       this.scrollHeight - this.scrollTop - 1 <= this.clientHeight;
     if (CONDITION) {
       binding.value();
     }
   });
 },
});
Vue.use(GnComponents);
Vue.use(fabric);
Vue.config.productionTip = false;
Vue.prototype._ = _;
Vue.prototype.$baseEventBus = new Vue();

Vue.prototype.$baseConfirm = (content, title, callback1, callback2) => {
 MessageBox.confirm(content, title || "温馨提示", {
   confirmButtonText: "确定",
   cancelButtonText: "取消",
   closeOnClickModal: false,
   type: "warning", //type字段表明消息类型，可以为success，error，info和warning
 })
   .then(() => {
     if (callback1) {
       callback1();
     }
   })
   .catch(() => {
     if (callback2) {
       callback2();
     }
   });
};
new Vue({
  el: "#app",
  router,
  store,
  i18n,
  render: h => h(App)
});
