var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "addRule" },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            "label-width": "90px",
            rules: _vm.rules,
            model: _vm.props.data,
          },
        },
        [
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("roleManagement.roleName"),
                prop: "roleName",
              },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.props.data.roleName,
                  callback: function ($$v) {
                    _vm.$set(_vm.props.data, "roleName", $$v)
                  },
                  expression: "props.data.roleName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label: _vm.$t("roleManagement.roleNotice"),
                prop: "description",
              },
            },
            [
              _c("el-input", {
                attrs: { type: "textarea" },
                model: {
                  value: _vm.props.data.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.props.data, "description", $$v)
                  },
                  expression: "props.data.description",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: { label: _vm.$t("roleManagement.Resource"), prop: "tree" },
            },
            [
              _c(
                "el-scrollbar",
                { attrs: { tag: "ul" } },
                [
                  _c("el-tree", {
                    ref: "tree",
                    staticStyle: { height: "400px", "overflow-y": "scroll" },
                    attrs: {
                      "node-key": "id",
                      data: _vm.allResource,
                      props: _vm.defaultProps,
                      "default-checked-keys": _vm.defaultChecked,
                      "default-expand-all": "",
                      "show-checkbox": "",
                    },
                    on: { check: _vm.handlerCheck },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              staticClass: "cancel",
              on: {
                click: function ($event) {
                  _vm.props.visible = false
                },
              },
            },
            [_vm._v(_vm._s(this.$t("roleManagement.cancel")))]
          ),
          _c(
            "el-button",
            {
              staticClass: "confirm",
              attrs: { loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.handlerSubmit("form")
                },
              },
            },
            [_vm._v(" " + _vm._s(this.$t("roleManagement.submit")) + " ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }