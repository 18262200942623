<!--
 * @Author: jixuyu
 * @Date: 2021-09-26 22:40:18
 * @LastEditTime: 2021-11-28 10:20:25
 * @LastEditors: JIXUYU
 * @Description: 
 * @FilePath: \front\src\components\Echarts\components\chart-world.vue
 * 记得注释
-->
<template>
  <echarts :option="option" v-bind="$attrs" v-on="$listeners"></echarts>
</template>
<script>
import echarts from "./base-chart";
import "@/mock/world.js";
export default {
  name: "chart-world",
  components: {
    echarts
  },
  props: {
    animation: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    chartData: {
      type: Object,
      default() {
        return {};
      }
    },
    datas:{
       type: Array,
      default() {
        return [];
      }

    }
  },
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {
    option() {
      const self = this;
      const { xAxis, yAxis, colors, name } = this.chartData;

      // var self.datas = [
      //   { name: "安徽省", value: [117.283042, 31.86119,10]},
      //   { name: "青海省", value: [101.778916, 36.623178,10] },
      //   { name: "内蒙古自治区", value: [111.670801, 40.818311,10] }
      // ];
      const _option = {
        // backgroundColor: "#02AFDB",
        title: {
          // text: '在线设备分布',
          left: "40%",
          top: "0px",
          textStyle: {
            color: "#fff",
            opacity: 0.7
          }
        },
        visualMap: {
          show: true,
          min: 0,
          max: 200,
          left: "10%",
          top: "bottom",
          calculable: true,
          seriesIndex: [1],
          inRange: {
            color: ["#04387b", "#467bc0"] // 蓝绿
          }
        },
        dataRange: {
          show: false,
          min: 0,
          max: 1000000,
          text: ["High", "Low"],
          realtime: true,
          calculable: true,
          color: ["orangered", "yellow", "lightskyblue"]
        },
        tooltip: {
          trigger: "item"
        },
        geo: {
          show: true,
          map: "world",
          label: {
            normal: {
              show: false
            },
            emphasis: {
              show: false
            }
          },
          roam: false,
          itemStyle: {
            normal: {
              areaColor: "#023677",
              borderColor: "#1180c7"
            },
            emphasis: {
              areaColor: "#4499d0"
            }
          }
        },
        series: [
          {
            type: "map",
            mapType: "world",
            geoIndex: 0,
            aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示
            label: {
              normal: {
                show: true
              },
              emphasis: {
                show: false,
                textStyle: {
                  color: "#fff"
                }
              }
            },
            roam: true,
            itemStyle: {
              normal: {
                areaColor: "#031525",
                borderColor: "#3B5077"
              },
              emphasis: {
                areaColor: "#2B91B7"
              }
            },
            animation: false,
            data: [
             
              {
                name: "Bhutan",
                value: 716.939
              },
              {
                name: "Botswana",
                value: 1969.341
              },
              {
                name: "Central African Republic",
                value: 4349.921
              },
              {
                name: "Canada",
                value: 34126.24
              },
              {
                name: "Switzerland",
                value: 7830.534
              },
              {
                name: "Chile",
                value: 17150.76
              },
              {
                name: "China",
                value: 803591.465
              },
              {
                name: "Ivory Coast",
                value: 60508.978
              },
              {
                name: "Cameroon",
                value: 20624.343
              },
              {
                name: "Democratic Republic of the Congo",
                value: 62191.161
              },
              {
                name: "Republic of the Congo",
                value: 3573.024
              },
              {
                name: "Colombia",
                value: 46444.798
              },
              {
                name: "Costa Rica",
                value: 4669.685
              },
              {
                name: "Cuba",
                value: 11281.768
              },
              {
                name: "Northern Cyprus",
                value: 1.468
              },
              {
                name: "Cyprus",
                value: 1103.685
              },
              {
                name: "Czech Republic",
                value: 10553.701
              },
              {
                name: "Germany",
                value: 83017.404
              },
              {
                name: "Djibouti",
                value: 834.036
              },
              {
                name: "Denmark",
                value: 5550.959
              },
              {
                name: "Dominican Republic",
                value: 10016.797
              },
              {
                name: "Algeria",
                value: 37062.82
              },
              {
                name: "Ecuador",
                value: 15001.072
              },
           
            
              {
                name: "Lebanon",
                value: 4341.092
              },
              {
                name: "Liberia",
                value: 3957.99
              },
              {
                name: "Libya",
                value: 6040.612
              },
              {
                name: "Sri Lanka",
                value: 20758.779
              },
              {
                name: "Lesotho",
                value: 2008.921
              },
              {
                name: "Lithuania",
                value: 3068.457
              },
              {
                name: "Luxembourg",
                value: 507.885
              },
              {
                name: "Latvia",
                value: 2090.519
              },
              {
                name: "Morocco",
                value: 31642.36
              },
              {
                name: "Moldova",
                value: 103.619
              },
              {
                name: "Madagascar",
                value: 21079.532
              },
              {
                name: "Mexico",
                value: 117886.404
              },
              {
                name: "Macedonia",
                value: 507.885
              },
              {
                name: "Mali",
                value: 13985.961
              },
            
              {
                name: "Nepal",
                value: 26846.016
              },
              {
                name: "New Zealand",
                value: 4368.136
              },
              {
                name: "Oman",
                value: 2802.768
              },
              {
                name: "Pakistan",
                value: 173149.306
              },
              {
                name: "Panama",
                value: 3678.128
              },
              {
                name: "Peru",
                value: 29262.83
              },
              {
                name: "Philippines",
                value: 93444.322
              },
              {
                name: "Papua New Guinea",
                value: 6858.945
              },
              {
                name: "Poland",
                value: 38198.754
              },
              {
                name: "Puerto Rico",
                value: 3709.671
              },
              {
                name: "North Korea",
                value: 1.468
              },
              {
                name: "Portugal",
                value: 10589.792
              },
              {
                name: "Paraguay",
                value: 6459.721
              },
              {
                name: "Qatar",
                value: 1749.713
              },
              {
                name: "Romania",
                value: 21861.476
              },
              {
                name: "Russia",
                value: 21861.476
              },
              {
                name: "Rwanda",
                value: 10836.732
              },
            
            
              {
                name: "Zambia",
                value: 13216.985
              },
              {
                name: "Zimbabwe",
                value: 13076.978
              }
            ],
            symbolSize: 12
          },
          {
            name: "点",
            type: "scatter",
            coordinateSystem: "geo",
            zlevel: 6
          },
          {
            name: "",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: self.datas,
            symbolSize: function(val) {
              return val[2];
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke"
            },
            hoverAnimation: true,
            label: {
              normal: {
                formatter: "{b}",
                position: "left",
                show: false
              }
            },
            itemStyle: {
              normal: {
                color: "yellow",
                shadowBlur: 10,
                shadowColor: "yellow"
              }
            },
            zlevel: 10
          }
        ]
      };
      // 配置合并
      return Object.assign(_option, self.options);
    }
  },
  methods: {}
};
</script>
