<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-14 10:12:38
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-09-28 00:15:53
-->
<template>
 <div class="design">
  <el-row :gutter="5">
   <el-col :span="6">
    <station-tree/>
   </el-col>
   <el-col :span="18">
    <station-chart/>
   </el-col>
  </el-row>
 </div>
</template>

<script>
 import stationTree from './components/stationTree'
 import stationChart from './components/stationChart'
 export default {
  computed: {},
  components: {
   stationTree,
   stationChart
  },
  data() {
   return {
   }
  },
  methods: {
  },
  mounted() {
  }
 };
</script>

<style lang="scss" scoped>
 @import "./style/index.scss";
</style>