<!--
 * @Author: ji.xuyu
 * @Date: 2020-06-18 17:13:34
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-02 18:01:27
 * @Description: file content
-->
<template>
<div class="avatar_app">
  <el-dropdown @command="handleCommand">
    <div class="right-avatar">
      <img
        class="user-avatar"
        :src="avatar ? avatar : require('@/assets/login/rabbit.png')"
      />

      <el-tooltip effect="light" :content="name" placement="left-start">
        <span class="user-name">{{
          name.length > 10 ? `${name.slice(0, 6)}...` : name
        }}</span>
      </el-tooltip>
      <span class="el-dropdown-link user_out"
        ><svg-icon class="btn-icon" :iconClass="'tuichu1'"></svg-icon
      ></span>
    </div>

    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="personCenter" divided>
        <i class="el-icon-user-solid"></i>
       {{this.$t('login.person')}}
      </el-dropdown-item>
       <el-dropdown-item command="stationSet" divided>
        <i class="el-icon-s-data"></i>

         {{this.$t('login.station')}}
      </el-dropdown-item>
      <el-dropdown-item command="logout" divided>
        <i class="el-icon-switch-button"></i>
         {{this.$t('login.out')}}
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
<!--
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.visible"
      style="min-width:400px"
    >
      <components
        :is="componentName"
        :props="dialog"
        @confirm="handlerSearch"
      />
    </el-dialog> -->




</div>

</template>

<script>
import { mapGetters } from "vuex";
import Confirm from "@/mixins/Confirm";
import stationList from './stationList'

export default {
  name: "AvatarName",
  mixins: [Confirm],
  components:{stationList},
  computed: {
    ...mapGetters(["sidebar", "avatar", "name"]),
    showName() {
      const data = this.$store.getters["user/name"];
      let name = "";
      if (data.length > 10) {
        name = ``;
      }
    }
  },
  data(){
   return{
        componentName:'stationList',
        dialog: {
        title: this.$t("stationReport.PowerSwitch"),
        visible: false,
        type: "ADD",
        data: {},
      },
   }

  },
  methods: {
    /**
     * @description:
     * @param {*} command
     * @return {*}
     * @author: JIXUYU
     */
    handleCommand(command) {
      switch (command) {
        case "logout":
          this.logout();
          break;
        case "personCenter":
          this.goCenter();
          break;
        case "stationSet":
        this.setStation();
         break;

      }
    },
    /**
     * @description: 电站列表数据信息
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    setStation(){
     // this.dialog.visible=true
     this.$router.push('/stationReport')

    },
    /**
     * @description:
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    handlerSearch(){
      this.dialog.visible=false
    },
    /**
     * @description:
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    goCenter() {
      this.$router.push("PersonalMsg");
    },
    async logout() {
      const results = await this.MixinsConfirm(this.$t('login.sure'));
      if (results) {
        const fullPath = this.$route.fullPath;
        await this.$store.dispatch("user/logout").catch(err => {
          console.log(err);
        });
        sessionStorage.clear()
        this.$router.push(`/login?redirect=${fullPath}`);
      }
    }
  }
};
</script>
<style lang="scss">
.avatar_app{
 height: 60px;
}
.right-avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  .user_out {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    // background-color: #000;
    text-align: center;
    line-height: 50px;
  }
  .user-avatar {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #dddfe5;
    margin: 0 15px;
  }
  .user-logout {
    color: #70a436;
    font-size: 16px;
    margin-right: 18px;
    cursor: pointer;
  }
  .user-name {
    font-size: 14px;
    color: #fff;
  }
  .btn-icon {
    color: #fff;
    font-size: 18px;
  }
}
</style>
