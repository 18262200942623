var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "configInfo" }, [
    _c(
      "ul",
      { staticClass: "info-tab" },
      _vm._l(_vm.tabList, function (item, index) {
        return _c(
          "li",
          {
            key: index,
            class: [
              "tab-item",
              _vm.component === item.component ? "tab-active" : "",
            ],
            staticStyle: { width: "300px" },
            on: {
              click: function ($event) {
                _vm.component = item.component
              },
            },
          },
          [_vm._v(" " + _vm._s(item.name) + " ")]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "info-content" },
      [
        _c(
          "transition",
          { attrs: { name: "fade-transform", mode: "out-in" } },
          [
            _c(_vm.component, {
              ref: "configComponent",
              tag: "component",
              on: { nextStep: _vm.nextStep },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }