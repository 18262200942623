<!--
 * @Author: jixuyu
 * @Date: 2021-11-20 16:20:19
 * @LastEditTime: 2022-06-13 21:36:10
 * @LastEditors: jixuyu
 * @Description:
 * @FilePath: \front\src\components\TimeAxis\index.vue
 * 记得注释
-->
<template>
  <div class="time-axis-content">
    <div class="marking-line-box">
      <div class="marking-line" ref="markingLine" @mousedown="doClick">
        <div :key="'aa' + i" v-for="i in 25">
          <div class="marking-line-con" :title="`${i - 1}:00-${i}:00`" v-show="i%2==1">
            <span v-if="i%2==1">{{ i-1 }}:00</span>
          </div>
        </div>

        <!-- <div  class="time-axis-progress-bar">
          <div v-for="item in timeAxisArr" :key="item.endTime" :style="
              `flex: 0 0 ${item.percentage}%; ${
                item.isDisabled ? 'background-color: #2b85e4' : ''
              }`
            "></div>
        </div> -->
      </div>
      <div class="marking-map"><ChartArea :allData="allSumData"></ChartArea></div>
    </div>
    <!--三角-->
    <div class="triangle-down" ref="triangleDown" @mousedown="triangleMove"> 
      <!-- @mousedown="triangleMove" -->
      <i></i>
    </div>
    <p class="current-time">{{ lengthTime }}</p>
  </div>
</template>

<script>
import * as util from "@/utils";
import ChartArea from "@/components/TimeAxis/ChartArea"
export default {
  name: "vue-time-axis",
  components:{
    ChartArea
  },
  data () {
    return {
      timeAxisArr: [], // 时间标记数组
      time: 0, // 展示总时间
      currentPlayState: true, // 监听父组件时间传值
      debounce: null,
    };
  },
  methods: {
    // 计算占用面积
    calculationProgressBar () {
      var arr = this.timeArr;
      let num = 0;
      for (let i in arr) {
        if (i === "0") {
          num = Number(arr[i].startTime) - this.currentTime;
          this.timeAxisArr.push({
            isDisabled: false,
            percentage: num / 864,
            id: "id" + Number(i)
          });
          num = Number(arr[i].endTime) - Number(arr[i].startTime);
          this.timeAxisArr.push({
            isDisabled: true,
            percentage: num / 864,
            id: "id" + Number(i)
          });
        } else {
          num = Number(arr[i].startTime) - Number(arr[Number(i) - 1].endTime);
          this.timeAxisArr.push({
            isDisabled: false,
            percentage: num / 864,
            id: "id" + Number(i)
          });
          num = Number(arr[i].endTime) - Number(arr[i].startTime);
          this.timeAxisArr.push({
            isDisabled: true,
            percentage: num / 864,
            id: "id" + Number(i)
          });
          if (Number(i) === arr.length - 1) {
            num = 1539792000 - Number(arr[i].endTime);
            this.timeAxisArr.push({
              isDisabled: false,
              percentage: num / 864,
              id: "id" + 10000
            });
          }
        }
      }
    },
    doClick (e) {
      let num = 0;
      let odiv = this.$refs.triangleDown; // 获取目标元素
      // 鼠标按下并移动的事件
      // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置

      // let left = e.offsetX - odiv.offsetWidth / 2;
      // let left = e.offsetX ;

      num = Number(this.$refs.markingLine.offsetWidth);

      //每五分钟一个区间，并确定是哪个区间的开始时间
      let judge = function(a,b){
        // let num1 = a / 288;
        // let num2 = Math.floor(b / num1);
        // //取整
        // let num3 = Math.floor(num2*num1);
        // return num3;
        const step = a/288;
        return step * Math.floor(b/step);
      }
      let left = judge(num,e.offsetX);

      const nd = new Date();
      let playt= nd.getHours() * 3600 + nd.getMinutes() * 60; //刻度是以分钟为一刻度，24小时，每小时3600，总共为86400
      if (new Date(this.dateValue).getTime() > nd.getTime()) {
        const dv = new Date(this.dateValue);
        playt = dv.getHours() * 3600 + dv.getMinutes() * 60;
      }

      const max = (num / 86400) * playt;
      const date = util.parseTime(this.dateValue, '{y}-{m}-{d}')
      const current = util.parseTime(nd, '{y}-{m}-{d}')
      if (left > max && current == date) {
        return;
      }

      // 移动当前元素
      if (left <= num && left >= 0) {
        odiv.style.left = left-1.5 + "px";
      }
      // this.time = ((odiv.offsetLeft + 9) / num) * 86400;


      let time = ((left) / (num)) * (86400);
      this.time = judge(86400,time);

      this.$emit("exportSelTime", {
        left: left,
        time: this.time
      });
      const debounce = (this.currentPlayState = false); // 关闭时间监听
      this.debounce();
      document.onmouseup = e => {
        num = Number(this.$refs.markingLine.offsetWidth);
        time = ((left) / (num)) * 86400;
        this.time = judge(86400,time);
        // alert(this.time);
        this.currentPlayState = true; // 打开监听时间变化
        document.onmousemove = null;
        document.onmouseup = null;
      };
    },
    // 拖拽
    triangleMove (e) {
      var num = 0;
      let odiv = this.$refs.triangleDown; // 获取目标元素
      // 算出鼠标相对元素的位置
      let disX = e.clientX - odiv.offsetLeft;
      let leftCompy = 0
      document.onmousemove = e => {

        // 鼠标按下并移动的事件
        // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
        let left = e.clientX - disX;
        // let left = odiv.offsetLeft - odiv.offsetWidth / 2;
        num = Number(this.$refs.markingLine.offsetWidth);
        //超出当前时间不可移动
        let len = this.allSumData.length-1;
        const max = Math.ceil((num / 288) * len);
        const date = util.parseTime(this.dateValue, '{y}-{m}-{d}')
        const current = util.parseTime(new Date(), '{y}-{m}-{d}')
        
        if (left >= max && current == date) {
          odiv.style.left = max-1.5 + "px";
          const f5index = Math.floor(max / num * 288);
          this.time = f5index*5*60;
          return false;
        }else {
          // 移动当前元素
          if (left <= num && left >= 0) {
            odiv.style.left = left-1.5 + "px";
          }
          this.time = (Math.abs(odiv.offsetLeft) / (num)) * (86400);
        }

        // this.time = ((odiv.offsetLeft + 10) / (num / 100)) * 864;
        // this.$emit("exportSelTime", {
        //   left: odiv.offsetLeft,
        //   time: this.time
        // });

        const debounce = (this.currentPlayState = false); // 关闭时间监听
      };
      this.debounce();
      document.onmouseup = e => {
        num = Number(this.$refs.markingLine.offsetWidth);
        const f5index = Math.floor(Math.abs(odiv.offsetLeft) / num * 288);
        odiv.style.left = (f5index/288 * num)-1.5 +'px';
        this.time = f5index*5*60;
        
        this.$emit("exportSelTime", {
          left: odiv.offsetLeft,
          time: this.time
        });

        this.currentPlayState = true; // 打开监听时间变化
        document.onmousemove = null;
        document.onmouseup = null;
      };
    }
  },
  computed: {
    //当前时间，应该和竖红线时间对应，只有当this.time变化时候才执行
    lengthTime: function () {
      // 时长
      // let time = Number(this.time) * 1000;
      // let timeDate = new Date(time - 28800000);//减去8小时
      // const current =
      //   (timeDate.getHours() <= 9 ? "0" : "") +
      //   timeDate.getHours() +
      //   ":" +
      //   (timeDate.getMinutes() <= 9 ? "0" : "") + timeDate.getMinutes()
      //second转为h:i格式
      //数字对3600取模
      let h = parseInt(this.time / 3600);
      //数字对3600取余
      let s = parseInt(this.time % 3600);
      //数字对60取模
      let i = parseInt((this.time-h*3600) / 60);
      //数字必须保证两位，如果不足两位，前面补0
      h = h < 10 ? "0" + h : h;
      i = i < 10 ? "0" + i : i;
      let s1 = h + ":" + i;
      return s1;
      // return current;
    }
  },
  watch: {
    playTime: {
      handler (newVal, oldVal) {
        if (this.currentPlayState) {
          this.$nextTick(() => {
            let num = Number(this.$refs.markingLine.offsetWidth);
            let odiv = this.$refs.triangleDown; // 获取目标元素
            let playTime = newVal;
            //this.time 第一次修改
            this.time = playTime;
            this.$emit("sendTime", this.lengthTime);
            odiv.style.left = (num/288)*(playTime/300)-1.5 + "px";
          });
        }
      },
      immediate: true
    }
  },
  created () {
    this.debounce = _.debounce(() => {
      this.$emit("sendTime", this.lengthTime);
    }, 500);
  },
  mounted () {
    let timeDate = new Date();
    timeDate.setHours(0);

    timeDate.setMinutes(0);
    timeDate.setSeconds(0);
    this.calculationProgressBar();
  },
  props: {
    timeArr: {
      required: true,
      type: Array
    },
    playTime: {
      required: true,
      type: Number
    },
    currentTime: {
      required: true,
      type: Number
    },
    dateValue: {
      required: true,
      type: [String, Date]
    },
    allSumData:{
      required: true,
      type: Array,
      default: []
    }
  }
};
</script>

<style scoped lang="scss">
.time-axis-content {
  flex: 1;
  margin-top: 10px;
  user-select: none;
  height: 60px;
  width: 100%;
  background-color: #fff;
  // overflow: hidden;
  position: relative;
  cursor: pointer;
  .marking-line-box {
    width: 100%;
    height: 100%;
    position: absolute;
    .marking-line {
      background-color: transparent;
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 0;
      // overflow: hidden;
      left: 0;
      z-index: 10000;
      display: flex;
      justify-content: space-between;
      border-top: 2px solid #ccc;
      border-bottom: 2px solid #ccc;
      .marking-line-con {
        margin-top: 6px;
        flex: 1 1 100%;
        height: 30px;
        display: inline-block;
        position: relative;
        border-right: 1px solid #a6a1a1;
        box-sizing: border-box;
        span {
          position: absolute;
          top: 30px;
          right: -16px;
          color: #000;
        }
      }
    }
    .marking-map {
      position: absolute;
      z-index: 9999;
      width: 100%;
      height: 100%;
      padding-top: 5px;
      padding-bottom: 2px;
    }
    .time-axis-progress-bar {
      width: 100%;
      position: absolute;
      height: 10px;
      background-color: #cccccc;
      bottom: 0;
      left: 0;
      display: flex;
    }
  }
  .triangle-down {
    width: 0;
    height: 0;
    top: 6px;
    // border-left: 9px solid transparent;
    // border-right: 9px solid transparent;
    // border-top: 18px solid red;

    left: 0px;
    position: absolute;
    z-index: 999999;
    i {
      width: 4px;
      height: 36px;
      background-color: red;
      position: absolute;
      top: -10px;
      left: 2px;
      right: 0;
    }
  }
  .current-time {
    position: absolute;
    color: #333;
    right: -64px;
    top: 16px;
  }
}
</style>
