/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {number} time
 * @param {string} option
 * @returns {string}
 */
export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return this.$t('otherMain.JustNow');
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + this.$t('otherMain.MinutesAgo');
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + this.$t('otherMain.HoursBefore');
  } else if (diff < 3600 * 24 * 2) {
    return this.$t('otherMain.OneDayBefore');
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      this.$t('otherMain.Month') +
      d.getDate() +
      this.$t('otherMain.Day') +
      d.getHours() +
      this.$t('otherMain.Hour') +
      d.getMinutes() +
      this.$t('otherMain.Minute')
    );
  }
}

/**
 * @param {string} url
 * @returns {Object}
 */
function getRouterPath(data) {
  let list = [];
  data.forEach((v) => {
    if (v.children) {
      list = list.concat(getRouterPath(v.children));
    } else {
      list.push(v.path);
    }
  });
  return list;
}
export function param2Obj(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
    decodeURIComponent(search)
    .replace(/"/g, '\\"')
    .replace(/&/g, '","')
    .replace(/=/g, '":"')
    .replace(/\+/g, " ") +
    '"}'
  );
}

/**
 * @param {string} 获取当前用户所有的权限路由
 * @returns {Object}
 */
export function getRouterPathList(data) {
  const router = data;
  let newRouterList = [];
  router.forEach((v) => {
    if (v.children) {
      let list = getRouterPath(v.children);
      newRouterList = newRouterList.concat(list);
    } else {
      newRouterList.push(v.path);
    }
  });
  return newRouterList;
}

/***
 *  存取sessionStorage
 * @param key
 * @param value
 */
export const writeSession = function (key, value) {
  if (value) {
    value = JSON.stringify(value);
  }
  sessionStorage.setItem(key, value);
};

/***
 *  读取sessionStorage
 * @param key
 * @param value
 */
export const readSession = function (key) {
  let value = sessionStorage.getItem(key);
  if (value && value !== "undefined" && value !== "null") {
    return JSON.parse(value);
  }
  return null;
};
/***
 *  移除sessionStorage
 * @param key
 * @param value
 */
export const removeSession = function (key) {
  key && sessionStorage.removeItem(key);
};
/***
 * localStorage
 * @param key
 * @param value
 */
export const writeLocal = function (key, value) {
  if (value) {
    value = JSON.stringify(value);
  }
  localStorage.setItem(key, value);
};

/***
 * localStorage
 * @param key
 * @param value
 */
export const readLocal = function (key) {
  let value = localStorage.getItem(key);
  if (value && value !== "undefined" && value !== "null") {
    return JSON.parse(value);
  }
  return null;
};

/***
 * localStorage
 * @param key
 * @param value
 */
export const removeLocal = function (key) {
  key && localStorage.removeItem(key);
};

/**
 * @description: 四舍五入保留两位小数
 * @param {type}
 * @return {type}
 */
export const keepTwoDecimalFull = function (num) {
  let result = parseFloat(num);
  if (isNaN(result)) {
    return false;
  }
  result = Math.round(num * 100) / 100;
  let s_x = result.toString();
  let pos_decimal = s_x.indexOf(".");
  if (pos_decimal < 0) {
    pos_decimal = s_x.length;
    s_x += ".";
  }
  while (s_x.length <= pos_decimal + 2) {
    s_x += "0";
  }
  return s_x;
};

// 深拷贝
export const deepcopy = function (source) {
  if (!source) {
    return source;
  }
  let sourceCopy = source instanceof Array ? [] : {};
  for (let item in source) {
    sourceCopy[item] =
      typeof source[item] === "object" ? deepcopy(source[item]) : source[item];
  }
  return sourceCopy;
};

//过滤数组中重复部分

export const filterArray = function (arr) {
  let uniques = [];
  let stringify = {};
  for (let i = 0; i < arr.length; i++) {
    var keys = Object.keys(arr[i]);
    keys.sort(function (a, b) {
      return Number(a) - Number(b);
    });
    let str = "";
    for (let j = 0; j < keys.length; j++) {
      str += JSON.stringify(keys[j]);
      str += JSON.stringify(arr[i][keys[j]]);
    }
    if (!stringify.hasOwnProperty(str)) {
      uniques.push(arr[i]);
      stringify[str] = true;
    }
  }
  uniques = uniques;
  return uniques;
};

/**
 * @description: 秒转化位时分秒
 */
export const formatSeconds = function (value) {
  let theTime = parseInt(value); // 秒
  let theTime1 = 0; // 分
  let theTime2 = 0; // 小时
  if (theTime > 60) {
    theTime1 = parseInt(theTime / 60);
    theTime = parseInt(theTime % 60);
    if (theTime1 > 60) {
      theTime2 = parseInt(theTime1 / 60);
      theTime1 = parseInt(theTime1 % 60);
    }
  }
  return {
    hour: theTime2,
    minute: theTime1,
    second: theTime,
  }
  // let result = "" + parseInt(theTime) + "秒";
  // if (theTime1 > 0) {
  //   result = "" + parseInt(theTime1) + "分" + result;
  // }
  // if (theTime2 > 0) {
  //   result = "" + parseInt(theTime2) + "小时" + result;
  // }
  // return result;
}

/**
 * --
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    if (key === "a") {
      return ` 星期${["日", "一", "二", "三", "四", "五", "六"][value]}`;
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

export function diffObject(obj1, obj2) {
  var o1 = obj1 instanceof Object;
  var o2 = obj2 instanceof Object;
  if (!o1 || !o2) {
    return obj1 === obj2;
  }

  if (Object.keys(obj1).length !== Object.keys(obj2).length) {
    return false;
  }

  for (var attr in obj1) {
    var t1 = obj1[attr] instanceof Object;
    var t2 = obj2[attr] instanceof Object;
    if (t1 && t2) {
      return diff(obj1[attr], obj2[attr]);
    } else if (obj1[attr] !== obj2[attr]) {
      return false;
    }
  }
  return true;
}

//保留小数，无四舍五入
export const toFixed = function (num, decimal) {
  num = num.toString();
  let index = num.indexOf(".");
  if (index !== -1) {
    num = num.substring(0, decimal + index + 1);
  } else {
    num = num.substring(0);
  }
  return parseFloat(num).toFixed(decimal);
};

//获取订单状态
export const getOrderStateValue = function (code) {
  let orderStateList = readSession("svf-order-state") || [];
  let item =
    orderStateList &&
    orderStateList.length > 0 &&
    orderStateList.find((item) => {
      return item.orderStateCode == code;
    });
  let value = (item && item.orderStateName) || "";
  return value;
};

//数组分割
export const splitArray = function (arr, len) {
  let a_len = arr.length;
  var result = [];
  for (let i = 0; i < a_len; i += len) {
    result.push(arr.slice(i, i + len));
  }
  return result;
};

//随机获取数组中的几个元素
export const getRandomArrayElements = function (arr, count) {
  var shuffled = arr.slice(0),
    i = arr.length,
    min = i - count,
    temp,
    index;
  while (i-- > min) {
    index = Math.floor((i + 1) * Math.random());
    temp = shuffled[index];
    shuffled[index] = shuffled[i];
    shuffled[i] = temp;
  }
  return shuffled.slice(min);
};
//将数字转换为万为单位
export const formatNumber = function (number) {
  const num = Number(number);
  if (num == 0) {
    return num;
  } else if (num > 1 && num < 10000) {
    return num;
  } else {
    return `${(num / 10000).toFixed(2)}万`;
  }
};


//生成唯一标识
export const generateId = function () {
  const random = Math.floor(Math.random() * 10000);
  const id = new Date().getTime() + random;
  return id;
};

/**
 * 值为空时返回默认值
 * @param {Object} val
 * @param {Object} def
 */
export const defaultValue = function (val, def) {
  if(val===undefined || val===null || val===''){
    if(def===undefined || def===null)
      return "";
    else
      return def;
  }else{
    return val;
  }
};

/**
 * 序号转字母
 * @param {Object} num
 */
export const numberToLetter = function(num){
  if(num<0) return null;
  let letter='';
  num--;
  do {
      if (letter.length > 0) {
          num--;
      }
      letter = String.fromCharCode(num % 26 + 65) + letter;
      num = (num - num % 26) / 26;
  } while (num > 0);

  return letter;
};

/**
 * 字母转序号
 * @param {Object} letter
 */
export const letterToNumber = function (letter) {
        let length = letter.length;
        let num = 0;
        let number = 0;
        for(let i = 0; i < length; i++) {
            let ch = letter.charCodeAt(length - i - 1);
            num = ch - 65 + 1;
            num *= Math.pow(26, i);
            number += num;
        }
        return number;
    }

/**
 * 是否为双输入
 * @param {*} mac
 */
export const isDoubleHoneybee = function(macid){
  let doubleValues = ['2','4','5','6'];
  if(doubleValues.includes(macid.substring(0,1))){
    return true
  }
  return false;
}
