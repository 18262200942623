var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "status-list" },
    [
      _c("Gn-Tabs", {
        attrs: { datas: _vm.tabList, firstTab: _vm.component },
        on: { changeTabs: _vm.changeTabs },
      }),
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [_c(_vm.component, { tag: "component" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }