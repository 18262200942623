/*
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-10-10 12:20:02
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-12-28 11:10:07
 */
import request from "@/utils/request";

export function stationlayout(params) {
  return request({
    url: "/stationlayout/tree",
    method: "get",
    params,
  });
}

export function getCurcve(params) {
 return request({
   url: "/home/getCurcve",
   method: "post",
   data:params,
 });
}
