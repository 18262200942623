var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "conrtlBox" },
    [
      !_vm.child
        ? _c("div", { staticClass: "conrtlChlid" }, [
            _c(
              "div",
              {
                staticClass: "left",
                on: {
                  click: function ($event) {
                    return _vm.handleSet("closed")
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/design/control-off.png"),
                    alt: "",
                  },
                }),
                _c("p", { staticClass: "closed" }, [
                  _vm._v(_vm._s(_vm.$t("control.notiClosed"))),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "right",
                on: {
                  click: function ($event) {
                    return _vm.handleSet("open")
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/design/control-on.png"),
                    alt: "",
                  },
                }),
                _c("p", { staticClass: "open" }, [
                  _vm._v(_vm._s(_vm.$t("control.notiOpen"))),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _vm.child
        ? _c("div", { staticClass: "conrtlChlid" }, [
            _c(
              "div",
              {
                staticClass: "left",
                on: {
                  click: function ($event) {
                    return _vm.handleSet("closed")
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/design/control-off.png"),
                    alt: "",
                  },
                }),
                _c("p", { staticClass: "closed" }, [
                  _vm._v(_vm._s(_vm.$t("control.notiClosed"))),
                ]),
              ]
            ),
            _c(
              "div",
              {
                staticClass: "right",
                on: {
                  click: function ($event) {
                    return _vm.handleSet("open")
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/design/control-on.png"),
                    alt: "",
                  },
                }),
                _c("p", { staticClass: "open" }, [
                  _vm._v(_vm._s(_vm.$t("control.notiOpen"))),
                ]),
              ]
            ),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialog.title,
            visible: _vm.dialog.visible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "ruleForm",
              staticClass: "box_from",
              attrs: { model: _vm.ruleForm, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("control.ptcodeR"),
                    prop: "resertCode",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: _vm.$t("control.PEnter") },
                    model: {
                      value: _vm.ruleForm.resertCode,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "resertCode", $$v)
                      },
                      expression: "ruleForm.resertCode",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: _vm.$t("control.ptcode") } },
                [
                  _c(
                    "el-input",
                    {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.imgSrc,
                        callback: function ($$v) {
                          _vm.imgSrc = $$v
                        },
                        expression: "imgSrc",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "append" },
                        [
                          _c("el-button", { on: { click: _vm.getVerify } }, [
                            _vm._v(_vm._s(_vm.$t("control.angin"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { staticClass: "form-btn" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", round: "" },
                      on: {
                        click: function ($event) {
                          _vm.dialog.visible = false
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("control.cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "success", round: "" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("control.confirm")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }