<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-20 21:55:40
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-09-20 21:55:41
-->

<template>
    <span :class="['WySpan', 'WySpan-' + type]" @click="handlerClick" v-permit='value' >
        <!-- Write the template from here  -->
        <slot></slot>
    </span>
</template>

<script>

/**
 * @description: 
 * @param {type} 
 * @return {type} 
 * @author: JIXUYU
 */
export default {
    name: "GnSpan",

    props: {
        type: {
            type: String,
            default: 'primary'
        },
         value: {
            type: String,
            default: 'add'
        }
    },

    methods: {
        handlerClick() {
          if(this.type=='disabled'){
            return 
          }
            this.$emit('click');
        }
    }
}
</script>
<style lang="scss" scoped>
  @import './style/index';
</style>


