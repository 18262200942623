<template>
  <div class="UserRoles">
    <!-- Write the template from here  -->
    <!-- 操作搜索头部 -->
    <div class="ConfigurationMenu-header">
      <div  @click="openAddDialog" v-permit="'add'" class="add">
       
          <i class="el-icon-plus"></i>
           {{ addRole }}
    
      </div>
      <el-button type="danger" @click="handleDel" class="add_btn">{{$t('roleManagement.detete')}}</el-button>  
      <el-input
        class="enter_inp"
        v-model="searchName"
        @keyup.native.enter="handlerSearch"
        :placeholder="rolesName"
        clearable
        prefix-icon="el-icon-search"
      />
    </div>
    <!-- 操作表格区域 -->

    <GnGrid
      ref="grid"
      :url="getRoleListsAPI"
      :params="gridOptions"
      @selection-change="tableSelectionChange"
       style="width: 100%"
      :render-content="gridRenderContent"
      class="UserRoles-grid"
      :bodyParser="bodyParser"
    />
    <el-drawer
      :title="dialog.title"
      :visible.sync="dialog.visible"
      :wrapperClosable="false"
      size="30%"
      direction="rtl"
    >
      <components
        :is="componentName"
        :props="dialog"
        @confirm="handlerSearch"
      />
    </el-drawer>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import AddRuleDiaog, { InterfaceParams } from "./components/AddRuleDialog";
import DialogLogic from "@/mixins/DialogLogic";
import Confirm from "@/mixins/Confirm";
import rowCloum from "@/mixins/rowCloum";

export default {
  name: "UserRoles",
  mixins: [DialogLogic, Confirm, rowCloum],
  components: { AddRuleDiaog },

  data() {
    return {
      gridOptions: {

      },
      idList:[],
      searchName: "",
      addRole: this.$t('roleManagement.creatRole'),
      rolesName: this.$t('roleManagement.Penter'),
      componentName: "",
      getRoleListsAPI: "/sys/role/list",
      bodyParser: false, //false: params方式 true: data方式
      dialog: {
        title: this.$t('roleManagement.addRole'),
        visible: false,
        type: "ADD",
        data: Object.assign({}, InterfaceParams),
      },
    };
  },

  methods: {
    ...mapActions("UserRoles", ["deleteRoleAPI"]),
    // 表格渲染部分
    gridRenderContent() {
      return [
        { label: this.$t('roleManagement.selectAll'), type: 'selection' },
        { label: this.$t('roleManagement.roleName'), prop: "roleName" },
        {
          label:this.$t('roleManagement.roleNotice'),
          render: (scope) => (
            <span>{this.MixinsClounm(scope.row.description)}</span>
          ),
        },
        {
          label:this.$t('roleManagement.action'),
          render: (scope) =>
              <div>
                <el-button
                  type="primary"
                  onClick={() => this.openEditorDialog(scope)}
                  value="edit"
                >
                {this.$t('roleManagement.edit')}
                </el-button>
                   <el-button
                  type="danger"
                  onClick={() => this.deleteGridItem(scope.row.id)}
                  value="del"
                >
                {this.$t('roleManagement.detete')}

                </el-button>
 
              </div>
   
        },
      ];
    },
    /**
     * @description: 
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */    
    tableSelectionChange(){
     this.idList = this.$refs["grid"].getSelectedIds();

    },
    /**
     * @description: 批量删除
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */    
    handleDel(){
     if(!this.idList.length){
       this.$message.success(this.$t('roleManagement.PSelect'))
     }else{
      let roleIds=this.idList.join(',')
      this.deleteGridItem(roleIds)

     }
     
  

    },

    handlerSearch(flag = true) {
      this.gridOptions.roleName = this.searchName;
      this.$refs["grid"].tableInitial(flag);
    },

    openAddDialog() {
      this.componentName = "AddRuleDiaog";
      this.MixinsDialogLogic(this.dialog, this.$t('roleManagement.addRole'), "ADD", {});
    },

    openEditorDialog({ row }) {
      this.componentName = "AddRuleDiaog";
      this.MixinsDialogLogic(this.dialog, this.$t('roleManagement.editRole'), "MODIFY", row);
    },

    /**
     * 删除单条数据操作
     * @param {Object}
     */
    async deleteGridItem(val) {
      const results = await this.MixinsConfirm();
      if (results) {
        await this.deleteRoleAPI({ids:val});
        // 不需要重置page=1
        this.handlerSearch(false);
      }
    },
  },
};
</script>
<style lang="scss">
@import "./style/index.scss";
</style>
