<!--
 * @Author: sun.mengmeng
 * @Date: 2021-09-14 13:44:49
 * @LastEditors: jixuyu
 * @LastEditTime: 2021-09-14 23:57:31
 * @Description: file content
-->
<script>
export default {
  name: "MenuItem",
  functional: true,
  props: {
    icon: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    iconShow: {
      type: Boolean,
      default: false
    }
  },
  render(h, context) {
    const { icon, title,iconShow } = context.props;
    const vnodes = [];

    if (iconShow) {
      vnodes.push(<span class={["iconfont svg-icon" ,`icon-${icon}`]}></span>);
      
    }else {
       vnodes.push(<svg-icon class={["svg-icon"]} icon-class={icon} />);
    }

    if (title) {
      vnodes.push(<span slot="title">{title}</span>);
    }
    return vnodes;
  }
};
</script>

<style scoped>
.sub-el-icon {
  color: currentColor;
  width: 1em;
  height: 1em;
}
.svg-icon {
  font-size: 20px;
}
</style>
