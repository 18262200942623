<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2022-04-14 17:33:02
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-04-21 11:28:19
-->

<template>
  <echarts :option="option" v-bind="$attrs" v-on="$listeners"></echarts>
</template>
<script>
import echarts from "./base-chart";

export default {
  name: "chart-bar",
  components: {
    echarts,
  },
  props: {
    animation: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    chartData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  watch: {},
  computed: {
    option() {
      const self = this;
      const { xAxis, yAxis, colors, name } = this.chartData;

      const _option = {
        title: {
          text: name,
          textStyle: {
            color: "#252733",
            fontSize: 16,
          },
          padding: 20,
          left: -10,
          top:-15
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999",
            },
          },
        },
        grid: {
          left: "10%",
          right: "5%",
          bottom: "10%",
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
            },
            data: xAxis,
            axisPointer: {
              type: "shadow",
            },
            nameTextStyle: {
              // 坐标轴名称样式
              color: " #7A7A7A",
              fontSize: 10,
            },
          },
        ],

        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
            name:'kWh',
            nameTextStyle: {
              color: " #7A7A7A",
              fontSize: 10
            },
            axisLine: {
              show: true,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            type: "bar",
            barWidth: "11px",
            itemStyle: {
              color: {
                type: "linear",
                x: 0, //右
                y: 0, //下
                x2: 1, //左
                y2: 1, //上
                colorStops: [
                  {
                    offset: 1,
                    color: colors[0],
                  },
                  {
                    offset: 0.5,
                    color: colors[1],
                  },
                ],
                globalCoord: true,
              },
              // 图形的形状
              barBorderRadius: [11, 11, 0, 0],
            },
            data: yAxis,
          },
        ],
      };
      // 配置合并
      return Object.assign(_option, self.options);
    },
  },
  methods: {},
};
</script>
