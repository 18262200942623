var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "set-config set-flex" },
    [
      _c(
        "div",
        { staticClass: "info-label", staticStyle: { "margin-bottom": "20px" } },
        [
          _c("p", { staticClass: "label-title" }, [
            _vm._v(_vm._s(_vm.$t("config.setOptimizer"))),
          ]),
          _c(
            "p",
            {
              staticClass: "label-icon",
              staticStyle: { "margin-top": "10px" },
              on: {
                click: function ($event) {
                  return _vm.addForm()
                },
              },
            },
            [
              _c("el-button", { staticClass: "iconfont icon-plus" }, [
                _vm._v(_vm._s(_vm.$t("config.NewOptimizer"))),
              ]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "block", staticStyle: { "margin-bottom": "20px" } },
        [
          _c("el-image", {
            ref: "myImg",
            staticStyle: { width: "100px", height: "100px", cursor: "pointer" },
            attrs: {
              src: require("@/assets/dev/2_01.jpg"),
              "preview-src-list": [
                require("@/assets/dev/2_01.jpg"),
                require("@/assets/dev/2_02.jpg"),
              ],
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleClickItem($event)
              },
            },
          }),
          _c("el-image", {
            ref: "myImg1",
            staticStyle: {
              width: "100px",
              height: "100px",
              "margin-left": "10px",
              cursor: "pointer",
            },
            attrs: {
              src: require("@/assets/dev/2_02.jpg"),
              "preview-src-list": [
                require("@/assets/dev/2_02.jpg"),
                require("@/assets/dev/2_01.jpg"),
              ],
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.handleClickItem($event)
              },
            },
          }),
        ],
        1
      ),
      _c(
        "ul",
        { staticClass: "flex-tab" },
        _vm._l(_vm.nbList, function (item, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "tab-item",
              class: [_vm.nbActive === index ? "tab-active" : ""],
              on: {
                click: function ($event) {
                  return _vm.nbClick(item, index)
                },
              },
            },
            [
              _c("span", { staticClass: "item-span" }, [
                _vm._v(
                  _vm._s(_vm.$t("config.inverterName")) + _vm._s(item.title)
                ),
              ]),
            ]
          )
        }),
        0
      ),
      _c(
        "ul",
        { staticClass: "flex-tab flex-margin" },
        [
          _vm._l(_vm.tabsList, function (item, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "tab-item",
                class: [_vm.tabActive === index ? "tab-active" : ""],
                on: {
                  click: function ($event) {
                    return _vm.tabClick(item, index)
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "el-icon-close item-icon",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.tabDelete(item, index)
                    },
                  },
                }),
                _vm._v(" " + _vm._s(item.title) + " "),
              ]
            )
          }),
          _c("li", { staticClass: "tab-plus", on: { click: _vm.tabPlus } }, [
            _vm._v("+"),
          ]),
        ],
        2
      ),
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "configForm flex-margin",
          attrs: { model: _vm.ruleForm, "label-width": "86px" },
        },
        _vm._l(_vm.ruleForm.formList, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "collect-item" },
            [
              _c(
                "el-form-item",
                { staticClass: "item-first" },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "first-select",
                      attrs: {
                        placeholder: _vm.$t("config.Pselect"),
                        disabled: item.disabled,
                      },
                      on: {
                        change: function ($event) {
                          return _vm.changeType(item, index)
                        },
                      },
                      model: {
                        value: item.selectType,
                        callback: function ($$v) {
                          _vm.$set(item, "selectType", $$v)
                        },
                        expression: "item.selectType",
                      },
                    },
                    _vm._l(_vm.selectType, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.name, value: item.type },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "ul",
                { staticClass: "item-content" },
                _vm._l(item.renderList, function (item1, index1) {
                  return _c(
                    "li",
                    {
                      key: index1,
                      staticClass: "item-second",
                      on: {
                        change: function ($event) {
                          return _vm.changeMac(item, item1, index, index1)
                        },
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "second-flex",
                          attrs: {
                            label: "MAC ID：",
                            prop:
                              "formList." +
                              index +
                              ".renderList." +
                              index1 +
                              ".mac",
                            rules: [
                              {
                                required: true,
                                message: _vm.$t("config.PMAc"),
                                trigger: ["blur", "change"],
                                validate: _vm.validateComfirmPwd,
                              },
                            ],
                          },
                        },
                        [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "light",
                                content: _vm.$t("config.MacNotExist"),
                                placement: "right",
                                disabled: item1.show || item1.disabled,
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  disabled: item1.disabled,
                                  placeholder: _vm.$t("config.PMacIdImage"),
                                },
                                on: {
                                  blur: function ($event) {
                                    return _vm.testMac(
                                      item1.mac,
                                      item1,
                                      "formList." +
                                        index +
                                        ".renderList." +
                                        index1 +
                                        ".mac",
                                      item,
                                      index
                                    )
                                  },
                                  change: function ($event) {
                                    return _vm.getMac(item, item1)
                                  },
                                },
                                model: {
                                  value: item1.mac,
                                  callback: function ($$v) {
                                    _vm.$set(item1, "mac", $$v)
                                  },
                                  expression: "item1.mac",
                                },
                              }),
                            ],
                            1
                          ),
                          item.selectType === "double"
                            ? _c("span", { staticClass: "second-mark" }, [
                                _vm._v(_vm._s(-(Number(index1) + 1))),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "el-form-item el-form-item--small" },
                        [
                          _c(
                            "div",
                            { staticClass: "el-form-item__content" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  attrs: {
                                    content: _vm.$t("config.clickMe"),
                                    placement: "right",
                                    effect: "light",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "iconfont el-icon-question",
                                    staticStyle: {
                                      "margin-left": "5px",
                                      color: "cornflowerblue",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.question($event)
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          staticClass: "second-flex",
                          attrs: {
                            label: _vm.$t("config.Components"),
                            prop:
                              "formList." +
                              index +
                              ".renderList." +
                              index1 +
                              ".id",
                            rules: [
                              {
                                required: true,
                                message: _vm.$t("config.PComponents"),
                                trigger: ["change"],
                              },
                            ],
                          },
                        },
                        [
                          item.selectType === "single"
                            ? _c(
                                "el-select",
                                {
                                  staticClass: "first-select",
                                  attrs: {
                                    disabled: item1.disabled,
                                    placeholder: _vm.$t("config.Pselect"),
                                  },
                                  model: {
                                    value: item1.id,
                                    callback: function ($$v) {
                                      _vm.$set(item1, "id", $$v)
                                    },
                                    expression: "item1.id",
                                  },
                                },
                                _vm._l(_vm.typeList, function (item2, index2) {
                                  return _c("el-option", {
                                    key: index2,
                                    attrs: {
                                      label:
                                        (item2.Manufacturer === undefined
                                          ? "--"
                                          : item2.Manufacturer) +
                                        "(" +
                                        item2.Modulepower +
                                        "W)",
                                      value: item2.Id,
                                    },
                                  })
                                }),
                                1
                              )
                            : _c(
                                "el-select",
                                {
                                  staticClass: "first-select",
                                  attrs: {
                                    disabled:
                                      index1 == 0
                                        ? item1.disabled
                                        : item1.disabled && item1.dubleDisabled,
                                    placeholder: _vm.$t("config.Pselect"),
                                  },
                                  model: {
                                    value: item1.id,
                                    callback: function ($$v) {
                                      _vm.$set(item1, "id", $$v)
                                    },
                                    expression: "item1.id",
                                  },
                                },
                                _vm._l(_vm.typeList, function (item2, index2) {
                                  return _c("el-option", {
                                    key: index2,
                                    attrs: {
                                      label:
                                        (item2.Manufacturer === undefined
                                          ? "--"
                                          : item2.Manufacturer) +
                                        "(" +
                                        item2.Modulepower +
                                        "W)",
                                      value: item2.Id,
                                    },
                                  })
                                }),
                                1
                              ),
                        ],
                        1
                      ),
                      _c("el-form-item", { staticClass: "item-width" }, [
                        _c("div", { staticClass: "width-btn" }, [
                          _c(
                            "p",
                            {
                              staticClass: "btn-delete btn-common",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteItem(
                                    item,
                                    item1,
                                    index,
                                    index1
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("config.delete")) + " "
                              ),
                            ]
                          ),
                          _c(
                            "p",
                            {
                              staticClass: "btn-add btn-common",
                              on: {
                                click: function ($event) {
                                  return _vm.addForm()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " + _vm._s(_vm.$t("config.insert")) + " "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          )
        }),
        0
      ),
      _c("div", { staticClass: "info-bottom" }, [
        _c(
          "p",
          {
            staticClass: "info-btn",
            on: {
              click: function ($event) {
                return _vm.saveData(true)
              },
            },
          },
          [_vm._v(_vm._s(_vm.$t("config.save")))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }