<!--
 * @Author: jixuyu
 * @Date: 2021-09-19 21:22:25
 * @LastEditTime: 2022-04-26 18:55:13
 * @LastEditors: JIXUYU
 * @Description: 
 * @FilePath: \front\src\views\config\components\configInfo.vue
 * 记得注释
-->
<template>
  <div class="configInfo">
    <ul class="info-tab">
      <li
        v-for="(item, index) of tabList"
        :key="index"
        @click="component = item.component"
        :class="['tab-item', component === item.component ? 'tab-active' : '']"
        style="width: 300px;"
      >
        {{ item.name }}
      </li>
    </ul>
    <div class="info-content">
      <transition name="fade-transform" mode="out-in">
        <component ref="configComponent" :is="component" @nextStep="nextStep"></component>
      </transition>
    </div>
   
  </div>
</template>

<script>
import setConfig from "./setConfig.vue";
import setFlex from "./setFlex.vue";
import uploadConfig from "./uploadConfig.vue";
import { setGroup } from "@/api/config";
export default {
  components: { uploadConfig, setConfig ,setFlex},
  // 定义属性
  data() {
    return {
      component: "setFlex",
      // tabList: [
      //   {
      //     name: '方式-1',
      //     component: "setFlex"
      //   },
      //   {
      //     name: '方式-2',
      //     component: "uploadConfig"
      //   }
      // ]
    };
  },
  computed: {
    tabList(){
     return  [
        {
          name: this.$t('config.Method1'),
          component: "setFlex"
        },
        {
          name: this.$t('config.Method2'),
          component: "uploadConfig"
        }
      ]
    }




  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    saveConfig() {
      if (this.component === "setConfig") {
        const ref = this.$refs["configComponent"];
        const arr = ref && ref.getSingleForm("configNb");
        console.log('[ arr ]', arr)
      }
    },
    nextStep() {
      this.$emit("nextStep", 4);
    }
  },
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
