/*
 * @Author: jixuyu
 * @Date: 2021-09-14 23:18:17
 * @LastEditTime: 2021-11-21 10:26:32
 * @LastEditors: JIXUYU
 * @Description:
 * @FilePath: \front\src\components\index.js
 * 记得注释
 */
import Lang from "./Lang";
import Echarts from "./Echarts";
import CommonSearch from "./CommonSearch";
import GnTab from "./GnTab";
import GnGrid from "./GnGrid";
import GnSpan from "./GnSpan";
import GnTabs from "./GnTabs";



import TimeAxis from './TimeAxis' // 导入组件



export default {
  // 插件的初始化, 插件给你提供的全局的功能, 都可以在这里配置
  install(Vue) {
    // 进行组件的全局注册
    Vue.component("Lang", Lang);
    Vue.component("chart-plot", Echarts);
    Vue.component("common-search", CommonSearch);
    Vue.component("Gn-Tab", GnTab);
    Vue.component("GnGrid", GnGrid);
    Vue.component("Gn-span", GnSpan);
    Vue.component("Gn-Tabs", GnTabs);
    Vue.component("time-axis", TimeAxis);

  }
};
