<!--
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-25 19:52:10
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-24 15:32:23
-->
<template>
 <div class="register">
  <div class="TopBar">
   <div class="topbar-left">
    <span class="left-front">{{$t('register.title')}}</span
       ><span class="left-back"></span>
   </div>
   <div class="topbar-right">
    <div class="right-lang">
     <lang class="lang"></lang>
    </div>
   </div>
  </div>
  <span class="register_title">{{$t('register.registerAccount')}}</span>
  <el-form ref="ruleForm" :model="ruleForm" :rules="rules" class="box_from">
   <el-row :gutter="20">
    <el-col :span="12">
     <el-form-item :label="$t('register.userName')" prop="username">
      <el-input v-model="ruleForm.username" :placeholder="$t('register.setName')" />
     </el-form-item>
     <el-form-item :label="$t('register.password')" prop="password">
      <el-input type="password" v-model="ruleForm.password" :placeholder="$t('register.setPassword')" auto-complete="new-password"></el-input>
     </el-form-item>
         </el-col>
    <el-col :span="12">
     <el-form-item :label="$t('register.email')" prop="email">
      <el-input v-model="ruleForm.email" :placeholder="$t('register.setEmail')"></el-input>
     </el-form-item>
     <el-form-item :label="$t('register.Verification')" prop="captcha">
      <el-input v-model="ruleForm.captcha" maxlength="4" :placeholder="$t('register.PEnter')">
        <template slot="append">
           <el-button @click="getVerify">{{captchaButtonText}}</el-button>
        </template>
      </el-input>
     </el-form-item>
    </el-col>
   </el-row>
   <el-form-item class="form-btn">
    <el-button @click="goLogin" type="primary" round>{{$t('register.back')}}</el-button>
    <el-button @click="submitForm('ruleForm')" type="success" round>{{$t('register.register')}}</el-button>
   </el-form-item>
  </el-form>
 </div>
</template>

<script>
 import {
  toEmailCaptcha,
  doRegister,
  isExist
 } from "@/api/register";
 export default {
  name: "register",
  components: {},
  data() {
   return {
    lange: localStorage.getItem('lang')||'en',
    eableCaptcha: true,
    captchaTimeout: 0,
    captchaButtonText: this.$t('register.getCaptcha'),
    ruleForm: {
     username: "",
     password: "",
     email: "",
     captcha:'',
     captchaBean: {
      captcha:'',
      sessionId:'',
    timeout:'',
      createTime:''
     }
    },
    rules: {
     username: [{
       required: true,
       message: this.$t('register.pSetName'),
       trigger: "blur"
      },
      {
       min: 1,
       max: 16,
       message: this.$t('register.max16'),
       trigger: "blur"
      },
{
       validator: this.validateAccount,
       trigger: "blur"
      }
     ],
     password: [{
       required: true,
       message: this.$t('register.PSetPassword'),
       trigger: "blur"
      },
      {
       validator: this.validatePass,
       trigger: "blur"
      },
     ],
          email: [{
       required: true,
       message: this.$t('register.pSetEmail'),
       trigger: "blur"
      },
      {
       type: 'email',
       message: this.$t('addRules.BeEmail'),
              trigger: "blur"
      },
      {
       validator: this.validateEmail,
       trigger: "blur"
      }
     ],
     captcha: [{
       required: true,
       message: this.$t('register.PenterCode'),
       trigger: "blur"
      },
      {
       len: 4,
       message: this.$t('stationReport.errorCode'),
       trigger: ["blur"]
      },
     ],
         },
   };
  },
  methods: {
   /**
    * @description: 密码验证规则
    * @param {type}
    * @return:
    */
   validatePass(rule, value, callback) {
    const re = /((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{6,16}$/;
    if (re.test(value)) {
     callback();
    } else {
     callback(this.$t('addRules.setCodeNum'));
    }
   },
   /**
    *
    * @param {*} rule
    * @param {*} value
    * @param {*} callback
    */
   async validateAccount(rule, value, callback) {
    const {
     data
    } = await isExist(value)
    if (data!==false) {
     callback(this.$t('register.usernameIsExist'))
    } else {
     callback();
        }
   },
   /**
    * @description:
    * @param {*} rule
    * @param {*} value
    * @param {*} callback
    * @return {*}
    * @author: JIXUYU
    */
   async validateEmail(rule, value, callback) {
    const {
     data
    } = await isExist(value)
    if (data!==false) {
     callback(this.$t('register.emailIsExist'))
    } else {
     callback();
    }
   },
      /**
    * @description: 获取验证码
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   getVerify() {
    if (this.ruleForm.email && this.ruleForm.email!=='') {
      if (this.eableCaptcha) {
        toEmailCaptcha(this.ruleForm.email).then(res => {
          if (res && res.code===200 && res.data) {
            this.eableCaptcha = false;
            const {data} = res;
            this.ruleForm.captchaBean = {
              captcha: '',
              sessionId: data.sessionId,
              timeout: data.timeout,
              createTime: data.createTime
            }
            this.captchaTimeout = data.timeout;
            const _this = this;
            const inter = setInterval(function(){
              if (_this.captchaTimeout === 0) {
                _this.captchaButtonText = _this.$t('register.getCaptcha');
                _this.eableCaptcha = true;
                clearInterval(inter);
              } else {
                _this.captchaButtonText = _this.captchaTimeout + _this.$t('register.sendCaptchaAgain');
                _this.captchaTimeout--;
              }
            }, 1000);
          }
        })
      } else {
        this.$message.error(this.captchaTimeout + (this.$t('register.sendCaptchaAgain')));
      }
    } else {
      this.$message.error(this.$t('register.setEmail'));
   }
   },
   /**
    * @description: 注册
    * @param {type}
    * @return:
    */
   submitForm(formName) {
    let _this = this;
    this.$refs[formName].validate(async(valid) => {
     if (valid) {
      const params = _this.ruleForm
params.captchaBean.captcha = params.captcha;
      console.log(params);
      await doRegister(params).then((res) => {
       if (res.code === 200 ) {
        _this.$message.success(this.$t('register.resSucess'));
        setTimeout(() => {
         _this.$router.push("/Login");
        }, 1000);
       }
      });
     } else {
      return false;
     }
    });
   },
   //跳转登录
   goLogin() {
    this.$router.push("/Login");
   },
  },
  created() {

  },
  mounted() {

  }
 };
</script>

<style scoped lang="scss">
 @import "./style/index.scss";
</style>
