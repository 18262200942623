<!--
 * @Author: sun.mengmeng
 * @Date: 2021-09-15 14:41:12
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-11-03 23:04:46
 * @Description: file content
-->
<template>
 <div class="categoryItem">
   <el-row :gutter="10">
     <el-col  v-for="(item, index) of cateList" :key="index"  :span='item.col'>
      <div class="item-square">
      <p class="square-icon">
        <span :class="['iconfont icon-green', `icon-${item.icon}`]"></span>
      </p>
      <div class="square-text">
        <p class="text-unit">{{ item.name }} ({{ item.unit }})</p>
        <p class="text-value">{{ item.value }}</p>
      </div>
      </div>
     </el-col>

   </el-row>
  </div>
</template>

<script>
/**
 * @author: sunmengmeng
 * @since: 2021-09-15
 * @router('/categoryItem')
 */
export default {
  name: "categoryItem",
  components: {},
  props: {
    cateList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
     
    };
  },

};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
