var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avatar_app" },
    [
      _c(
        "el-dropdown",
        { on: { command: _vm.handleCommand } },
        [
          _c(
            "div",
            { staticClass: "right-avatar" },
            [
              _c("img", {
                staticClass: "user-avatar",
                attrs: {
                  src: _vm.avatar
                    ? _vm.avatar
                    : require("@/assets/login/rabbit.png"),
                },
              }),
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "light",
                    content: _vm.name,
                    placement: "left-start",
                  },
                },
                [
                  _c("span", { staticClass: "user-name" }, [
                    _vm._v(
                      _vm._s(
                        _vm.name.length > 10
                          ? _vm.name.slice(0, 6) + "..."
                          : _vm.name
                      )
                    ),
                  ]),
                ]
              ),
              _c(
                "span",
                { staticClass: "el-dropdown-link user_out" },
                [
                  _c("svg-icon", {
                    staticClass: "btn-icon",
                    attrs: { iconClass: "tuichu1" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-dropdown-menu",
            { attrs: { slot: "dropdown" }, slot: "dropdown" },
            [
              _c(
                "el-dropdown-item",
                { attrs: { command: "personCenter", divided: "" } },
                [
                  _c("i", { staticClass: "el-icon-user-solid" }),
                  _vm._v(" " + _vm._s(this.$t("login.person")) + " "),
                ]
              ),
              _c(
                "el-dropdown-item",
                { attrs: { command: "stationSet", divided: "" } },
                [
                  _c("i", { staticClass: "el-icon-s-data" }),
                  _vm._v(" " + _vm._s(this.$t("login.station")) + " "),
                ]
              ),
              _c(
                "el-dropdown-item",
                { attrs: { command: "logout", divided: "" } },
                [
                  _c("i", { staticClass: "el-icon-switch-button" }),
                  _vm._v(" " + _vm._s(this.$t("login.out")) + " "),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }