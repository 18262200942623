/*
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-20 21:36:20
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-10-19 22:21:53
 */

import request from "@/utils/request";

/**
 * @description: 用户新增
 * @param {*} data
 * @return {*}
 * @author: JIXUYU
 */
export function addUser(data) {
  return request({
    url: "/sys/user/save",
    method: "post",
    data,
  });
}
/**
 * @description: 用户编辑
 * @param {*} data
 * @return {*}
 * @author: JIXUYU
 */
export function editUser(data) {
 return request({
   url: "/sys/user/update",
   method: "post",
   data,
 });
}

/**
 * @description: 用户详情
 * @param {*} data
 * @return {*}
 * @author: JIXUYU
 */
export function userDetail(data) {
 return request({
   url: "/sys/user/detail",
   method: "get",
   data,
 });
}

/**
 * @description: 用户删除
 * @param {*} params
 * @return {*}
 * @author: JIXUYU
 */
export function deleteUser(params) {
 return request({
   url: "/sys/user/remove",
   method: "POST",
   params:params
 });
}



export function editPsw(data) {
 return request({
   url: "",
   method: "POST",
   data,
 });
}


export function editPersonData(data) {
 return request({
   url: "/user/updateInfo",
   method: "POST",
   data:data
 });
}
export function editPassowrd(data) {
 return request({
   url: "/user/updatePwd",
   method: "POST",
   data,
 });
}





export function getRoleList(params) {
 return request({
   url: "/sys/role/list",
   method: "get",
   params:params,
 });
}


export function getStations(params){
  return request({
    url:"/sys/user/stations",
    method: "get",
    params: params
  })
}
