<!--
 * @Author: jixuyu
 * @Date: 2021-09-24 23:08:40
 * @LastEditTime: 2022-04-26 15:55:26
 * @LastEditors: JIXUYU
 * @Description: 
 * @FilePath: \front\src\views\status\components\beeComponent.vue
 * 记得注释
-->
<template>
  <div class="bee-component">
    <GnGrid
      ref="grid"
       :url="getRoleListsAPI"
      :params="gridOptions"
      style="width: 100%"
      :render-content="gridRenderContent"
      class="UserRoles-grid"
      :bodyParser="bodyParser"
    />
  </div>
</template>

<script>
export default {
  components: {},
  // 定义属性
  data() {
    return {
      gridOptions: {},
      getRoleListsAPI:'/status/beehive',
      bodyParser: true,
     
    };
  },
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    gridRenderContent() {
      return [
        { label: "MACID", prop: "id" },
        { label: "Version", prop: "version" },
        { label: this.$t('status.updateTime'), prop: "modified" },
    
        {
          label: this.$t('status.status'),
          render: scope => <span class={scope.row.onStatus == 0?'bee-green':'bee-status'}>{scope.row.onStatusName}</span>
        }
      ];
    }
  },
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import '../style/index.scss'
</style>
