<!--
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-10-10 11:38:38
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-25 16:40:38
-->
<template>
   <div class="stationList">
        <GnGrid
      ref="grid"
      :url="getStationListsAPI"
      :params="gridOptions"
      :methods="'get'"
      @row-click='handleClick'
       style="width:100%"
      :render-content="gridRenderContent"
      class="UserRoles-grid"
    />

   </div>
</template>

<script>
import Confirm from "@/mixins/Confirm";
import rowCloum from "@/mixins/rowCloum";
import { switchStation } from "@/api/user";
import * as util from "@/utils";

/**
  * @author: JIXUYU
  * @since: 2021-10-10
  * @router('/stationList')
  */
export default {
  name:"stationList",
  components : {},
  mixins: [ Confirm, rowCloum],
  data () {
    return {
     getStationListsAPI:'/station/list',
     gridOptions:{

     },

    }
  },
  methods : {
   /**
    * @description: 电站列表数据渲染
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
    gridRenderContent() {
      return [
        { label: this.$t("stationReport.stationName"),
          render: (scope) => (
            <span>{this.MixinsClounm(scope.row.name)}</span>
          ),
        },
         { label: this.$t("stationReport.city"),
          render: (scope) => (
            <span>{this.MixinsClounm(scope.row.city)}</span>
          ),
        },
         { label: this.$t("stationReport.country"),
          render: (scope) => (
            <span>{this.MixinsClounm(scope.row.country)}</span>
          ),
        },
        { label: this.$t("stationReport.state"),
            render: (scope) => (
            <span>{scope.row.installed?this.$t("stationReport.HasBeenInstalled"):this.$t("stationReport.NotInstalled")}</span>
          ),
        },

      ];
    },
    /**
     * @description: 电站切换
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
   async handleClick(data){
     const results = await this.MixinsConfirm(this.$t("stationReport.Switch"));
      if (results) {
        switchStation({stationId:data.id}).then((resp) => {
          if (resp.code === 200) {
            util.writeSession('station', data);
            this.$emit('confirm')
          }
        });
      }


    },

  },
  created () {},
  mounted () {}
}
</script>

<style lang="scss" scoped>

</style>
