var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm",
      staticClass: "configForm",
      attrs: { model: _vm.ruleForm, "label-width": "86px" },
    },
    _vm._l(_vm.ruleForm.formList, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "collect-item" },
        [
          _c(
            "el-form-item",
            { staticClass: "item-first" },
            [
              _c(
                "el-select",
                {
                  staticClass: "first-select",
                  attrs: { placeholder: _vm.$t("config.Pselect") },
                  on: {
                    change: function ($event) {
                      return _vm.changeType(item, index)
                    },
                  },
                  model: {
                    value: item.selectType,
                    callback: function ($$v) {
                      _vm.$set(item, "selectType", $$v)
                    },
                    expression: "item.selectType",
                  },
                },
                _vm._l(_vm.selectType, function (item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.name, value: item.type },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "ul",
            { staticClass: "item-content" },
            _vm._l(item.renderList, function (item1, index1) {
              return _c(
                "li",
                { key: index1, staticClass: "item-second" },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "second-flex",
                      attrs: {
                        label: "MAC ID：",
                        prop:
                          "formList." +
                          index +
                          ".renderList." +
                          index1 +
                          ".mac",
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("config.PMAc"),
                            trigger: ["blur"],
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "light",
                            content: _vm.$t("config.MacNotExist"),
                            placement: "right",
                            disabled: item1.show,
                          },
                        },
                        [
                          _c("el-input", {
                            on: {
                              blur: function ($event) {
                                return _vm.testMac(
                                  item1.mac,
                                  item1,
                                  "formList." +
                                    index +
                                    ".renderList." +
                                    index1 +
                                    ".id"
                                )
                              },
                            },
                            model: {
                              value: item1.mac,
                              callback: function ($$v) {
                                _vm.$set(item1, "mac", $$v)
                              },
                              expression: "item1.mac",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      staticClass: "second-flex",
                      attrs: {
                        label: _vm.$t("config.Components"),
                        prop:
                          "formList." + index + ".renderList." + index1 + ".id",
                        rules: [
                          {
                            required: true,
                            message: _vm.$t("config.PComponents"),
                            trigger: ["change"],
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "first-select",
                          attrs: { placeholder: _vm.$t("config.Pselect") },
                          model: {
                            value: item1.id,
                            callback: function ($$v) {
                              _vm.$set(item1, "id", $$v)
                            },
                            expression: "item1.id",
                          },
                        },
                        _vm._l(_vm.typeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: {
                              label:
                                item.Manufacturer +
                                "(" +
                                item.Modulepower +
                                "W)",
                              value: item.Id,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-form-item", { staticClass: "item-width " }, [
                    _c("div", { staticClass: "width-btn" }, [
                      _c(
                        "p",
                        {
                          staticClass: "btn-delete btn-common",
                          on: {
                            click: function ($event) {
                              return _vm.deleteItem(item, item1, index, index1)
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(this.$t("config.delete")) + " ")]
                      ),
                      _c(
                        "p",
                        {
                          staticClass: "btn-add btn-common",
                          on: {
                            click: function ($event) {
                              return _vm.addForm()
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(this.$t("config.insert")) + " ")]
                      ),
                    ]),
                  ]),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }