<!--
 * @Author: jixuyu
 * @Date: 2021-09-20 19:46:52
 * @LastEditTime: 2022-08-04 23:28:55
 * @LastEditors: jixuyu
 * @Description: 
 * @FilePath: \front\src\views\config\components\setConfig.vue
 * 记得注释
-->
<template>
  <div class="set-config">
    <div class="info-label">
      <p class="label-title">{{ this.$t("config.setOptimizer") }}</p>
      <p class="label-icon">
        <span
          class="iconfont icon-add_circle icon-plus"
          @click="addForm"
        ></span>
      </p>
    </div>
    <!-- <div class="config-content" v-if="nbList.length > 0">
      <Gn-Tab ref="initTab" :tabsList="nbList" :closable="false" :isAdd="false">
      </Gn-Tab>
    </div> -->

    <el-tabs v-model="activeIndex" :before-leave="beforeLeave" type="card">
      <el-tab-pane
        v-for="(item, index) of nbList"
        :key="index"
        :name="`${index}`"
      >
        <p slot="label">{{ item.title }}</p>
        <config-nb ref="initTab"></config-nb>
      </el-tab-pane>
    </el-tabs>
    <div class="info-bottom">
      <p class="info-btn" @click="getSingleForm">
        {{ this.$t("config.save") }}
      </p>
    </div>
  </div>
</template>

<script>
import { stationDetail } from "@/api/config";
import ConfigNb from "./configNb";
import * as util from "@/utils";
export default {
  components: { ConfigNb },
  // 定义属性
  data() {
    return {
      activeIndex: "0",
      nbList: [
        // {
        //   title: "逆变器-NB01",
        //   name: "1",
        //   content: "configNb",
        //   id: "",
        //   row: null
        // },
        //  {
        //   title: "逆变器-NB02",
        //   name: "2",
        //   content: "configNb",
        //   id: "",
        //   row: null
        // }
      ],

      currentIndex: 1,
      map: new Map(),
    };
  },
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    beforeLeave(active, old) {
      let boolean = true;
      //为 1 时表示还未进行保存操作，为2时为已进行保存
      if (
        !this.map.get(`station-${old}`) ||
        this.map.get(`station-${old}`) == 1
      ) {
        this.$confirm(this.$t("config.beSave"), this.$t('common.warning'), {
          confirmButtonText: this.$t("config.confirm"),
          cancelButtonText: this.$t("config.cancel"),
          type: "warning",
        })
          .then(() => {
            this.map.set(`station-${old}`, 2);
            //保存数据
          })
          .catch(() => {
            console.log("[ 999 ]", 999);
            boolean = false;
          });
      }
      if (this.map.get(`station-${active}`) !== 2) {
        this.map.set(`station-${active}`, 1);
      }
      return boolean;
    },

    //新增优化器表单
    addForm() {
      const ref = this.$refs["initTab"][Number(this.activeIndex)];
      ref && ref.addForm();
    },

    //获取对应tab的表单数据
    getSingleForm() {
      const ref = this.$refs["initTab"][Number(this.activeIndex)];
      const arr = ref.getSingleForm();
      return arr;
    },
    //回显电站设置
    getStationDetail() {
      const stationId = util.readSession("stationId");
      if (!stationId) return;
      const params = {
        stationId: stationId,
      };
      stationDetail(params).then((resp) => {
        if (resp.code === 200) {
          //逆变器-组串集合-组件集合
          if (resp.data) {
            const { inverters, pvmoduletypes } = resp.data;
            util.writeSession("pvmoduletypes", pvmoduletypes);
            if (inverters.length > 0) {
              this.nbList = [];
              this.nbList = inverters.map((v, index) => {
                return {
                  title: v.Label,
                  name: `${index + 1}`,
                  content: "configNb",
                  id: v.Id,
                  row: v,
                };
              });
            }
          }
        }
      });
    },
  },
  created() {
    this.getStationDetail();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
.el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #70a436 !important;
  color: #f5f7fa;
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
}
</style>
