<!--
 * @Author: sun.mengmeng
 * @Date: 2021-09-15 14:41:27
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-04-14 17:25:30
 * @Description: file content
-->
<template>
  <el-row class="categoryEchart" :gutter="10">
    <el-col v-for="(item, index) of echartsList" :key="index" class="echart-item" :span="item.col" >
      <el-date-picker
        class="sub-text"
        v-model="item.date"
        v-if='item.show'
        @change="event => dateChange(event, item)"
        :type="item.type"
        :value-format="item.formate"
        :placeholder="date"
      >
      </el-date-picker>
      <chart-plot :chartType="item.chartType" :chartData="item.data"></chart-plot>
    </el-col>
  </el-row>
</template>

<script>
/**
 * @author: sunmengmeng
 * @since: 2021-09-15
 * @router('/categoryEchart')
 */
export default {
  name: "categoryEchart",
  components: {},
  props: {
    echartsList: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      date: this.$t("curve.choiceDate"),
    };
  },
  methods: {
    dateChange(date, data) {
      this.$emit("dateChange", {
        date: date,
        type: data.type
      });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";

.dashboard {
  .categoryEchart{ 
    .echart-item{
      div{
        top: 8px !important;
      }
    }
  }
}
</style>
