<!--
 * @Author: sun.mengmeng
 * @Date: 2021-04-27 15:29:40
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-04-14 17:33:52
 * @Description: file content
-->

<template>
  <component :is="chartType" v-bind="$attrs" v-on="$listeners"></component>
</template>
<script>
import ChartLine from "./components/chart-line";
import ChartDouble from "./components/chart-double";
import ChartWorld from "./components/chart-world";
import ChartSelf from "./components/chart-self";
import ChartBar from "./components/chart-bar";


export default {
  name: "chart-plot",
  components: {
    ChartLine, //折线图
    ChartWorld, //世界地图
    ChartDouble,
    ChartSelf,
    ChartBar//柱状图
  },
  props: {
    chartType: {
      // 图表类型
      type: String,
      default: "ChartLine"
    }
  },

  methods: {},
  moutend() {}
};
</script>
