var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "common-search" }, [
    _c("p", { staticClass: "main-left" }, [
      _c("span", { staticClass: "left-name" }, [_vm._v(_vm._s(_vm.title))]),
      _c("span", { staticClass: "iconfont icon-list_alt left-icon" }),
    ]),
    _c(
      "div",
      { staticClass: "main-right" },
      [
        _c("el-input", {
          attrs: {
            placeholder: _vm.date,
            "suffix-icon": "el-input__icon el-icon-search",
          },
          model: {
            value: _vm.searchValue,
            callback: function ($$v) {
              _vm.searchValue = $$v
            },
            expression: "searchValue",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }