/*
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-09 16:57:20
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-11-01 19:23:07
 */

import API from '@/utils/request'
import { Message } from 'element-ui'
import { ALLRESOURCE } from './action-type'

export default {
  //新增角色
 async addRoleAPI (store, params) {
    try {
      const { code, data } = await API({
        url: '/sys/role/save',
        method: 'post',
        data:params
      });
      if (code == 200) return Message({ type: 'success', message: '操作成功' });
      ;
    } catch (error) {
      Message({ type: 'error', message: error });
      throw error;
    }

  },

  // 获取单个角色信息

  getRolesAPI (store, id) {

  },

  // 更新单个角色信息

 async updateRolesAPI (store, params) {
    try {
      const { code, data }= await API({
        url: '/sys/role/update',
        method: 'post',
        data:params
      });
      if (code == 200) return  Message({ type: 'success', message: '操作成功'  });
      throw message;
    } catch (error) {
      Message({ type: 'error', message: error });
      throw error;
    }
 },
   // 删除角色

   async deleteUseAPI (store, params) {
    try {
   const {code,data,msg}=await API({
        url: '/sys/user/remove',
        method: 'post',
        params: params
      });
      if (code == 200) return Message({ type: 'success', message: '操作成功' });
      ;
    } catch (error) {
      Message({ type: 'error', message: error });
      throw error;
    }

  },

  // 删除角色

  async deleteRoleAPI (store, params) {
    try {
   const {code,data,msg}=await API({
        url: '/sys/role/remove',
        method: 'post',
        params: params
      });
      if (code == 200) return Message({ type: 'success', message: '操作成功' });
      ;
    } catch (error) {
      Message({ type: 'error', message: error });
      throw error;
    }

  },

  // 获取所有资源信息

  async getAllResourceAPI ({ state, commit }) {
    try {
      const { code, data, msg } = await API({
        url: '/sys/menu/getMenusByRoleId',
        method: 'get'
      });
      if (code == 200) return commit(ALLRESOURCE, data);
      
    } catch (error) {
      Message({ type: 'error', message: error });
      throw error;
    }
  },

  //获取角色资源权限关联信息
  async getRoleResourceAPI (store, id) {
    try {
      const { code, data, msg } = await API({
          url: `/sys/role/detail`,
        method: 'get',
        params: {
         id:id
        }
      });
      if(code==200) return data;

  } catch (error) {
      Message({ type: 'error', message: error });
      throw error;
  }

  },
  // 新增/更新公司信息
  async updateCompanyAPI (store, params) {
    try {
      const { code, data, msg } = await API({
        url: '/user/company/add',
        method: 'post',
        data:params
      });
      if (code == 200) return  Message({ type: 'success', message: '操作成功'  });
      throw message;
    } catch (error) {
      Message({ type: 'error', message: error });
      throw error;
    }

  },

}