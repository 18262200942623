/*
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-03 11:27:00
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-02 13:05:45
 */

export default {
  methods: {
      /**
       * confirm
       * @return Promise<String> | Boolean
       */
      async MixinsConfirm(text=this.$t('login.beSure'),center=false) {
          try {
              return await this.$confirm(text, this.$t('login.tip'), {
                  confirmButtonText: this.$t('login.confirm'),
                  cancelButtonText: this.$t('login.cancel'),
                  type: 'warning',
                  center: center
              });
          } catch (error) {
              // 异常错误问题
              return false;
          }
      }
  }
}