var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "design" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 5 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [_c("station-tree")], 1),
          _c("el-col", { attrs: { span: 18 } }, [_c("station-chart")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }