<!--
import { import } from '@babel/types';
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-11-14 11:30:47
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-05-02 09:33:28
-->
<template>
   <div class="allControl">
    <conrtl-box :idList="'FFFFFFFF'"/>
   </div>
</template>

<script>
import conrtlBox from './conrtlBox'
/**
  * @author: JIXUYU
  * @since: 2021-11-14
  * @router('/allControl')
  */
export default {
  name:"allControl",
  components : {conrtlBox},
  props : {},
  data () {
    return {
    }
  },

}
</script>

<style lang="scss" scoped>
@import '../style/index';
</style>
