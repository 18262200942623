var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("config.EditOptimizerSpecification") } },
            [
              _c(
                "el-link",
                { attrs: { href: "/doc/modeB.pdf", target: "_blank" } },
                [
                  _c("el-image", {
                    staticStyle: { width: "100px", cursor: "pointer" },
                    attrs: { src: require("@/assets/design/modeB.png") },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("config.EditOptimizerTemplate") } },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    plain: "",
                    icon: "el-icon-download",
                  },
                  on: { click: _vm.download },
                },
                [_vm._v(_vm._s(_vm.$t("config.download")))]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: _vm.$t("config.excelLabel") } },
            [
              _c(
                "el-upload",
                {
                  attrs: {
                    drag: "",
                    action: _vm.upload(),
                    "before-upload": _vm.beforeUpload,
                    "on-success": _vm.uploadSuccess,
                    "on-error": _vm.uploadError,
                    accept: ".xlsx",
                  },
                },
                [
                  _c("i", { staticClass: "el-icon-upload" }),
                  _c("div", { staticClass: "el-upload__text" }, [
                    _vm._v(_vm._s(_vm.$t("config.tipsExcelUpload1"))),
                    _c("em", [
                      _vm._v(_vm._s(_vm.$t("config.tipsExcelUpload2"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v(_vm._s(_vm.$t("config.tipsExcel")))]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }