<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-28 00:10:47
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-07 13:31:57
-->
<template>
 <div class="chartLeft" ref="chartLeft">
  <chart-plot :chartType="'ChartSelf'" :datas="lineData" v-loading='loading'></chart-plot>
 </div>
</template>

<script>
 import {
  getCurcve
 } from '@/api/curve'
 import * as util from "@/utils";
 /**
  * @author: JIXUYU
  * @since: 2021-09-28
  * @router('/chartLeft')
  */
 export default {
  name: "chartLeft",
  props: {
   dateTime: {
    type: String,
    default: ''
   },
   childName: {
    type: String,
    default: ''
   }
  },
  data() {
   return {
    groupId: [], //组串ID
    honeybeeId: [], //优化器ID
    type: '', // 类型
    loading:false,
    lineData: {
     legendData: [
     ],
     xData: [
     ],
     name:'W',
     seriesData: [
     ],
    },
   }
  },
  watch: {
   dateTime: {
    deep: true,
    handler () {
     if (util.readSession('honeybeeId')&&util.readSession('honeybeeId').length>0) {
       this.getLeftData()
     } else {
       this.$message.error(this.$t('curve.Pset'))
     }
    }
   }
  },
  methods: {
   /**
    * @description: 
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   dateChange() {
    this.getLeftData()
   },
   /**
    * @description: 
    * @param {*}
    * @return {*}
    * @author: JIXUYU
    */
   getLeftData() {
    this.lineData = {
     legendData: [],
     xData: [],
     name:'W',
     seriesData: []
    };
    this.loading=true
    const params = {
     dataType: 0,
     time: this.dateTime,
     honeybeeId: util.readSession('honeybeeId'),
     stationId: util.readSession("userInfo").stationId
    }
    getCurcve(params).then((res) => {
     if (res.code == 200 && res.data) {
      let result = res.data
      let xData= result.filter(item=>item.xdata!=[])
      this.lineData.xData=xData[0].xdata
       result.forEach((el, index) => {
        this.lineData.legendData.push(el.name)
        this.lineData.seriesData.push({
         data: el.data,
         type: "line",
         name: el.name,
        }, )
       })
      this.loading=false
     }
    })
   }
  },
 mounted () {
   console.log(util.readSession('honeybeeId'))
   if (util.readSession('honeybeeId')!=null||(util.readSession('honeybeeId')&&util.readSession('honeybeeId').length>0)) {
    this.getLeftData()
   }
   this.$baseEventBus.$off('checkTree')
   this.$baseEventBus.$on("checkTree", (val) => {
    console.log(val)
    if (val.honeybeeId.length > 0 && util.readSession('beShow')===true) {
     this.honeybeeId = val.honeybeeId
     this.getLeftData()
    } else {
     this.lineData = {
      legendData: [],
      xData: [],
      name:'W',
      seriesData: []
     }
    }
   })
  }
 }
</script>

<style lang="scss" scoped>
 @import '../style/index';
</style>