/*
 * @Author: jixuyu
 * @Date: 2021-09-14 20:49:23
 * @LastEditTime: 2021-10-31 17:39:06
 * @LastEditors: jixuyu
 * @Description: 
 * @FilePath: \front\src\router\index.js
 * 记得注释
 */
import Vue from "vue";
import Router from "vue-router";
import i18n from "@/lang";

Vue.use(Router);

/* Layout */
import Layout from "@/layout";

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
  {
    path: "/login",
    component: () => import("@/views/login/index"),
    hidden: true
  },

  {
    path: "/404",
    component: () => import("@/views/404"),
    hidden: true
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register"),
    meta: {
      title: i18n.t('login.register'),
      icon: ""
    },
    hidden: true
 },

 {
  path: "/PersonalMsg",
  component: Layout,
  redirect: "/PersonalMsg",
  name: "个人中心",
  hidden: true,
  children: [
   {
    path: "/PersonalMsg",
    component: () => import("@/views/PersonalMsg"),
    meta: {
     title: i18n.t('login.person'),
     icon: ""
    }
   }
  ]
 },
  // 404 page must be placed at the end !!!
  // {
  //   path: "*",
  //   redirect: "/404",
  //   hidden: true
  // }
];

const createRouter = () =>
  new Router({
    // mode: 'history', // require service support
    scrollBehavior: () => ({
      y: 0
    }),
    routes: constantRoutes
  });

const router = createRouter();

export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

export default router;
