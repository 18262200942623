var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "inverterInfo",
        },
        [
          _c(
            "div",
            { staticClass: "info-category" },
            [
              _c("div", { staticClass: "info-label" }, [
                _c("p", { staticClass: "label-title" }, [
                  _vm._v(_vm._s(_vm.tabTitle)),
                ]),
                _c("p", { staticClass: "label-icon" }, [
                  _c("span", {
                    staticClass: "iconfont icon-add_circle icon-plus",
                    on: { click: _vm.createComponent },
                  }),
                ]),
              ]),
              _c("Gn-Tab", {
                ref: "firstTab",
                attrs: { tabsList: _vm.tabsList },
                on: { removeTab: _vm.removeTab, changeTabs: _vm.changeTabs },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "info-category" },
            [
              _c("div", { staticClass: "info-label" }, [
                _c("p", { staticClass: "label-title" }, [
                  _vm._v(_vm._s(_vm.secondTabTitle)),
                ]),
                _c("p", { staticClass: "label-icon" }, [
                  _c("span", {
                    staticClass: "iconfont icon-add_circle icon-plus",
                    on: { click: _vm.createSecond },
                  }),
                ]),
              ]),
              _c("Gn-Tab", {
                ref: "secondTab",
                attrs: { tabsList: _vm.secondList },
                on: {
                  removeTab: _vm.removeTab,
                  changeTabs: _vm.changeSecondTabs,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "info-bottom" }, [
            _c(
              "p",
              { staticClass: "info-btn", on: { click: _vm.saveInverter } },
              [_vm._v(_vm._s(_vm.$t("config.save")))]
            ),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }