var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "station", staticClass: "stationChart" },
    [
      _c(
        "div",
        { staticClass: "station_top" },
        [
          _c("el-date-picker", {
            staticClass: "date",
            attrs: {
              type: _vm.dateType,
              "value-format": _vm.formateValue,
              "picker-options": _vm.expireTimeOption,
              placeholder: this.$t("curve.choiceDate"),
            },
            model: {
              value: _vm.dateTime,
              callback: function ($$v) {
                _vm.dateTime = $$v
              },
              expression: "dateTime",
            },
          }),
        ],
        1
      ),
      _c("Gn-Tabs", {
        attrs: { datas: _vm.tabList, firstTab: _vm.component },
        on: { changeTabs: _vm.changeTabs },
      }),
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [
          _c(_vm.component, {
            ref: "childName",
            tag: "component",
            attrs: { dateTime: _vm.dateTime, childName: _vm.component },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }