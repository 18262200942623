import LayoutLogicBase from "@/logic/LayoutLogicBase";

export default class LayoutPhysicalHoneybee extends LayoutLogicBase{
  constructor(canvas) {
    super(canvas);

    this.name = 'LayoutPhysicalHoneybee';
  }

  /**
   * 数据结构如下
   * {
   * 		"stationId": "f9d7dd7c5cc74efcba42632",
   * 		"inverters": [{
   * 			"Id": 1,
   * 			"Tilt": 0.00,
   * 			"Label": "KSTN",
   * 			"Mppts": "1",
   * 			"Xaxis": 327.91276693391194,
   * 			"Yaxis": 93,
   * 			"COMURL": "",
   * 			"RegionId": 0,
   * 			"MaxPower": "3000.00",
   * 			"TypeInfo": "",
   * 			"ListCount": 0,
   * 			"Manufacturer": "",
   * 			"PreListMoudler": 0,
   * 			"Angle": 0
   * 		},
   * 	  {
   * 			"Id": 1,
   * 			"Tilt": 0.00,
   * 			"Label": "KSTN",
   * 			"Mppts": "1",
   * 			"Xaxis": 327.91276693391194,
   * 			"Yaxis": 93,
   * 			"COMURL": "",
   * 			"RegionId": 0,
   * 			"MaxPower": "3000.00",
   * 			"TypeInfo": "",
   * 			"ListCount": 0,
   * 			"Manufacturer": "",
   * 			"PreListMoudler": 0,
   * 			"Angle": 0
   * 		}],
   * 		"pvpanels": [{
   * 			"power": 145.3,
   * 			"current": 4.8,
   * 			"voltage": 30.9,
   * 			"temperature": "24",
   * 			"twoOutPutFlag": 1,
   * 			"powerPercentage": 0,
   * 			"inputID": 2,
   * 			"Tilt": 0.00,
   * 			"Angle": 0,
   * 			"Label": "60032585_1",
   * 			"Xaxis": 385,
   * 			"Yaxis": 347,
   * 			"GroupId": 1,
   * 			"Location": "A1",
   * 			"RegionId": 0,
   * 			"HoneybeeMac": "60032585",
   * 			"HoneybeeOutput": 1,
   * 			"PVModuleTypeId": 1
   * 		}, {
   * 			"power": 145.0,
   * 			"current": 4.8,
   * 			"voltage": 30.8,
   * 			"temperature": "24",
   * 			"twoOutPutFlag": 1,
   * 			"powerPercentage": 0,
   * 			"inputID": 2,
   * 			"Tilt": 0.00,
   * 			"Angle": 0,
   * 			"Label": "60032585_2",
   * 			"Xaxis": 535,
   * 			"Yaxis": 347,
   * 			"GroupId": 1,
   * 			"Location": "A2",
   * 			"RegionId": 0,
   * 			"HoneybeeMac": "60032585",
   * 			"HoneybeeOutput": 2,
   * 			"PVModuleTypeId": 1
   * 		}, {
   * 			"power": 0.0,
   * 			"current": 0.0,
   * 			"voltage": 36.1,
   * 			"temperature": "25",
   * 			"twoOutPutFlag": 1,
   * 			"powerPercentage": 0,
   * 			"inputID": 2,
   * 			"Tilt": 0.00,
   * 			"Angle": 0,
   * 			"Label": "60002914_1",
   * 			"Xaxis": 310,
   * 			"Yaxis": 347,
   * 			"GroupId": 1,
   * 			"Location": "A3",
   * 			"RegionId": 0,
   * 			"HoneybeeMac": "60002914",
   * 			"HoneybeeOutput": 1,
   * 			"PVModuleTypeId": 1
   * 		}]
   *取出上面对象中，key为inverters和pvpanels中，最大的Xaxis和Yaxis以及最小的Xaxis和Yaxis
   */
  maxmin(data) {
    /**
     * inverters结构如下
     * [{"Xaxis": 327,"Yaxis": 93},{"Xaxis": 327,"Yaxis": 93}]
     * 找出inverters中最大的Xaxis和Yaxis以及最小的Xaxis和Yaxis
     */
    function getMaxMin(data) {
      let maxX = data[0].Xaxis;
      let maxY = data[0].Yaxis;
      let minX = data[0].Xaxis;
      let minY = data[0].Yaxis;

      data.forEach((item) => {
        if (item.Xaxis > maxX) {
          maxX = item.Xaxis;
        }
        if (item.Yaxis > maxY) {
          maxY = item.Yaxis;
        }
        if (item.Xaxis < minX) {
          minX = item.Xaxis;
        }
        if (item.Yaxis < minY) {
          minY = item.Yaxis;
        }
      });

      return {
        maxX: maxX,
        maxY: maxY,
        minX: minX,
        minY: minY
      }
    }
    let maxMin = getMaxMin(data.inverters);
    let maxMin2 = getMaxMin(data.pvpanels);

    //取出maxMin和maxMin2中最大的Xaxis和Yaxis以及最小的Xaxis和Yaxis
    let maxX = maxMin.maxX > maxMin2.maxX ? maxMin.maxX : maxMin2.maxX;
    let maxY = maxMin.maxY > maxMin2.maxY ? maxMin.maxY : maxMin2.maxY;
    let minX = maxMin.minX < maxMin2.minX ? maxMin.minX : maxMin2.minX;
    let minY = maxMin.minY < maxMin2.minY ? maxMin.minY : maxMin2.minY;
    return {
      maxX: maxX,
      maxY: maxY,
      minX: minX,
      minY: minY
    }
  }

  /**
   * 将maxmin放入this.canvas中
   * 根据this.canvas的宽度和高度，计算出将放入时候的缩放比
   */
  //将this.maxmin()放入this.canvas中，根据this.canvas的宽度和高度，计算出将放入时候的缩放比
  zoom(data) {
    let maxMin = this.maxmin(data);
    let maxX = maxMin.maxX;
    let maxY = maxMin.maxY;
    let minX = maxMin.minX;
    let minY = maxMin.minY;

    let width = this.canvas.width;
    let height = this.canvas.height;

    let zoomX = 1;
    if(width < maxX){
      zoomX = width / (maxX+this.honeybee.width/2);
    }
    let zoomY = 1;
    if(height < maxY){
      zoomY = height / (maxY+this.honeybee.height/2);
    }
    let zoom = zoomX > zoomY ? zoomY : zoomX;

    return {
      zoom: zoom,
      minX: minX,
      minY: minY
    }
  }

  fillCanvas(data) {
    //使用clone的data数据
    this.zoomPoint = new fabric.Point(
      0,
      0
    );
    let {zoom} = this.zoom(data);
    this.canvas.zoomToPoint(this.zoomPoint, zoom);
    return zoom;
  }


}
