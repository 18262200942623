<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-27 23:24:03
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-01-06 17:31:42
-->
<template>
 <div class="GnTabs">
  <ul class="list-tab">
   <li v-for="(item, index) of datas" :key="index" @click="handleClick(item.component)" class="tab-item" :class="[firstTab === item.component ? 'item-active' : '']">
     {{ item.name }}
   </li>
  </ul>
 </div>
</template>

<script>
 /**
  * @author: JIXUYU
  * @since: 2021-09-27
  * @router('/GnTabs')
  */
 export default {
  name: "GnTabs",
  components: {},
  props: {
   datas: {
    type: Array,
    default () {
     return []
    }
   },
   firstTab: {
    type: String,
    default: ''
   }
  },
  data() {
   return {
   }
  },
  methods: {
   handleClick(val){
    this.$emit('changeTabs',val)
   }
  },
 }
</script>

<style lang="scss" scoped>
 @import './style/index';
</style>