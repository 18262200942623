<!--
 * @Author: jixuyu
 * @Date: 2021-09-19 21:21:54
 * @LastEditTime: 2022-08-04 23:33:39
 * @LastEditors: jixuyu
 * @Description:
 * @FilePath: \front\src\views\config\components\collectInfo.vue
 * 记得注释
-->
<template>
  <div>
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="82px"
      class="collectInfo"
    >
      <div class="info-label" style="margin-bottom: 20px">
        <p class="label-title">{{ this.$t("config.SummarySet") }}</p>
        <p class="label-icon">
          <span
            @click="addForm"
            class="iconfont icon-add_circle icon-plus"
          ></span>
        </p>
      </div>
      
      <div class="block" style="margin-bottom: 20px">
        <el-image 
        ref="myImg1"
        style="width: 100px; height: 100px; margin-left: 10px;cursor:pointer;"
        :src="require('@/assets/dev/3_02.jpg')"
        :preview-src-list="[require('@/assets/dev/3_02.jpg')]"
        @click.stop="handleClickItem"/>
      </div>

      <el-form-item
        v-for="(item, index) of ruleForm.formList"
        :key="index"
        label="Mac ID："
        :prop="`formList.${index}.idName`"
        :rules="[
          {
            required: true,
            message: $t('config.UnitId'),
            trigger: ['blur'],
          },
          {
            min: 1,
            max: 8,
            message: $t('config.PCode'),
            trigger: 'change',
          },
        ]"
      >
        <el-tooltip
          effect="light"
          :content="$t('config.notExist')"
          placement="right"
          :disabled="item.show"
        >
          <el-input
            @blur="testMac(item.idName, item, `formList.${index}.idName`, index)"
            v-model="item.idName"
            class="info-input"
            :placeholder="$t('config.PMacIdImage')"
          ></el-input>
        </el-tooltip>

        <span
          @click="deleteForm(item, index)"
          class="iconfont icon-delete input-red"
        ></span>
        <el-tooltip :content="$t('config.clickMe')" placement="right" effect="light">
          <i
            @click.stop="question"
            class="iconfont el-icon-question"
            style="margin-left:5px; color:cornflowerblue;cursor:pointer;"
          ></i>
        </el-tooltip>
      </el-form-item>
    </el-form>
    <div class="info-bottom">
      <p class="info-btn" @click="saveInfo">{{ this.$t("config.save") }}</p>
    </div>
  </div>
</template>

<script>
import {
  setCollect,
  stationDetail,
  testUnitId,
  delBeehiveWithLayoutById,
} from "@/api/config";
import * as util from "@/utils";
export default {
  components: {},
  props: {
    detailData: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  // 定义属性
  data() {
    return {
      ruleForm: {
        formList: [
          {
            type: "add",
            idName: "",
          },
        ],
      },
      isTrueMac: true,
    };
  },
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //验证macId是否合法
    testMac(mac, item, prop, index) {
      item.show = true;
      const stationId = util.readSession("stationId");
      if (!stationId) return;
      //检查是否存在重复填写
      for(let i = 0; i < this.ruleForm.formList.length; i++){
        const formItem = this.ruleForm.formList[i]
        if(formItem.idName==mac && i!=index){
          item.show = false;
          this.isTrueMac = false;
          this.$message.error(this.$t("config.PenterAgin"));
          this.$refs["ruleForm"].validateField([prop], (errorMessage) => {
            console.log("[ data ]", errorMessage);
          });
          setTimeout(function(){
            item.show = true;
          },5000)
          return
        }
      }

      const params = {
        unitId: mac,
        stationId: stationId,
      };
      testUnitId(params).then((resp) => {
        if (!resp.data) {
          item.show = false;
          this.isTrueMac = false;
          this.$message.error(this.$t("config.PenterAgin"));
          this.$refs["ruleForm"].validateField([prop], (errorMessage) => {
            console.log("[ data ]", errorMessage);
          });
          setTimeout(function(){
            item.show = true;
          },5000)
        } else {
          this.isTrueMac = true;
          this.$refs["ruleForm"].validateField([prop]);
        }
      });
    },
    //新增表单
    addForm() {
      const json = {
        type: "edit",
        idName: "",
        show: true,
      };
      this.ruleForm.formList.push(json);
    },
    //删除
    deleteForm(item, index) {
      // const stationId = util.readSession("stationId");
      // if (!stationId) return;
		  // if(item.idName!==undefined && item.idName!==null  && item.idName!==""){
			// this.$confirm(this.$t("config.beDeleteBeehive"), this.$t('common.warning'), {
			//   confirmButtonText: this.$t("config.confirm"),
			//   cancelButtonText: this.$t("config.cancel"),
			//     type: "warning",
			//   }).then(() => {
			//     delBeehiveWithLayoutById({ stationId: stationId, beehiveId: item.idName });
			// 	  this.ruleForm.formList.splice(index, 1);
			//   }).catch(() => {});
      // }else{
      // 	this.ruleForm.formList.splice(index, 1);
      // }
      //保存的时候会一起删除，不用在这里删除
			this.ruleForm.formList.splice(index, 1);
    },
    //保存
    saveInfo() {
      const stationId = util.readSession("stationId");
      if (!stationId) return;
      if (!this.isTrueMac) {
        this.$message.error(this.$t("config.UnitEror"));
        return;
      }
      const { formList } = this.ruleForm;
      const map = formList.map((v) => v.idName);
      const params = {
        stationId: stationId,
        unitIds: map.join(","),
      };
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          setCollect(params).then((resp) => {
            if (resp.code === 200) {
              this.$emit("nextStep", 3);
            }
          });
        } else {
          return false;
        }
      });
    },
    //回显电站设置
    getStationDetail() {
      const stationId = util.readSession("stationId");
      //  const stationId = util.readSession("addStation") === true ? null : util.readSession("userInfo").stationId;
      if (!stationId) return;
      const params = {
        stationId: stationId,
        step: 3,
      };
      stationDetail(params).then((resp) => {
        if (resp.code === 200) {
          //逆变器-组串集合-组件集合
          if (resp.data) {
            const { unitIds } = resp.data;
            if(unitIds!==undefined){
              const split = unitIds.split(",");
              this.ruleForm.formList = [];
              split.forEach((v) => {
                this.ruleForm.formList.push({
                type: "edit",
                idName: v,
                show: true,
                });
              });
            }
          }
        }
      });
    },
    handleClickItem(){
      this.$nextTick(()=>{
        // 获取遮罩层dom
        let domImageMask = document.querySelector(".el-image-viewer__mask");
        if (!domImageMask) {
          return;
        }
        domImageMask.addEventListener("click", () => {
            // 点击遮罩层时调用关闭按钮的 click 事件
            document.querySelector(".el-image-viewer__close").click();
        });
      })
    },
    question(){
      this.$refs.myImg.clickHandler();
      this.handleClickItem();
    }
  },
  created() {
    this.getStationDetail();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../style/index";
</style>
