<!--
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-14 18:11:17
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-25 17:03:44
-->
<template>
  <el-dropdown trigger="click" @command="changeLanguage">
    <div style="font-size:22px;cursor: pointer">
     <label style="font-size:14px; color: white;">{{this.$t('login.switchLang')}}</label>🌍
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="zh" :disabled="'zh'=== $i18n.locale ">
    <img src="../../assets/login/china.jpg" style="width:30px;height:20px;position: relative;
    top: 5px;"> {{this.$t('login.china')}}</el-dropdown-item>
      <el-dropdown-item command="en" :disabled="'en'=== $i18n.locale ">
    <img src="../../assets/login/usa.jpg" style="width:30px;height:20px;position: relative;top: 5px;">
    {{this.$t('login.english')}}</el-dropdown-item>
       <!-- <el-dropdown-item command="ja" :disabled="'ja'=== $i18n.locale ">
      {{this.$t('login.janpan')}}</el-dropdown-item> -->
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import * as util from "@/utils/index";

export default {
 methods: {
    changeLanguage(lang) {
    this.$i18n.locale = lang
      util.writeSession('lang', lang)
      localStorage.setItem('lang',lang)
      this.$message.success(this.$t('login.sucess'))
      // this.$router.push("/Login")
      // this.reload()
      this.$router.go(0);
    }
  },
 mounted () {
  if (!localStorage.getItem('lang')) {
    localStorage.setItem('lang','en')
  }
  }
}
</script>
