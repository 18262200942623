var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "GnTabs" }, [
    _c(
      "ul",
      { staticClass: "list-tab" },
      _vm._l(_vm.datas, function (item, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "tab-item",
            class: [_vm.firstTab === item.component ? "item-active" : ""],
            on: {
              click: function ($event) {
                return _vm.handleClick(item.component)
              },
            },
          },
          [_vm._v(" " + _vm._s(item.name) + " ")]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }