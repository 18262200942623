/*
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-03 11:27:00
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-09-20 21:40:40
 */

export default {
  methods: {
      /**
       * confirm
       * @return Promise<String> | Boolean
       */
    MixinsClounm (row) {
      return row?row:'--'
          
      }
  }
}