var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "set-config" },
    [
      _c("div", { staticClass: "info-label" }, [
        _c("p", { staticClass: "label-title" }, [
          _vm._v(_vm._s(this.$t("config.setOptimizer"))),
        ]),
        _c("p", { staticClass: "label-icon" }, [
          _c("span", {
            staticClass: "iconfont icon-add_circle icon-plus",
            on: { click: _vm.addForm },
          }),
        ]),
      ]),
      _c(
        "el-tabs",
        {
          attrs: { "before-leave": _vm.beforeLeave, type: "card" },
          model: {
            value: _vm.activeIndex,
            callback: function ($$v) {
              _vm.activeIndex = $$v
            },
            expression: "activeIndex",
          },
        },
        _vm._l(_vm.nbList, function (item, index) {
          return _c(
            "el-tab-pane",
            { key: index, attrs: { name: "" + index } },
            [
              _c("p", { attrs: { slot: "label" }, slot: "label" }, [
                _vm._v(_vm._s(item.title)),
              ]),
              _c("config-nb", { ref: "initTab", refInFor: true }),
            ],
            1
          )
        }),
        1
      ),
      _c("div", { staticClass: "info-bottom" }, [
        _c("p", { staticClass: "info-btn", on: { click: _vm.getSingleForm } }, [
          _vm._v(" " + _vm._s(this.$t("config.save")) + " "),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }