var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "UserRoles" },
    [
      _c(
        "div",
        { staticClass: "ConfigurationMenu-header" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "permit",
                  rawName: "v-permit",
                  value: "add",
                  expression: "'add'",
                },
              ],
              staticClass: "add",
              on: { click: _vm.openAddDialog },
            },
            [
              _c("i", { staticClass: "el-icon-plus" }),
              _vm._v(" " + _vm._s(_vm.addRole) + " "),
            ]
          ),
          _c(
            "el-button",
            {
              staticClass: "add_btn",
              attrs: { type: "danger" },
              on: { click: _vm.handleDel },
            },
            [_vm._v(_vm._s(_vm.$t("roleManagement.detete")))]
          ),
          _c("el-input", {
            staticClass: "enter_inp",
            attrs: {
              placeholder: _vm.rolesName,
              clearable: "",
              "prefix-icon": "el-icon-search",
            },
            nativeOn: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handlerSearch($event)
              },
            },
            model: {
              value: _vm.searchName,
              callback: function ($$v) {
                _vm.searchName = $$v
              },
              expression: "searchName",
            },
          }),
        ],
        1
      ),
      _c("GnGrid", {
        ref: "grid",
        staticClass: "UserRoles-grid",
        staticStyle: { width: "100%" },
        attrs: {
          url: _vm.getRoleListsAPI,
          params: _vm.gridOptions,
          "render-content": _vm.gridRenderContent,
          bodyParser: _vm.bodyParser,
        },
        on: { "selection-change": _vm.tableSelectionChange },
      }),
      _c(
        "el-drawer",
        {
          attrs: {
            title: _vm.dialog.title,
            visible: _vm.dialog.visible,
            wrapperClosable: false,
            size: "30%",
            direction: "rtl",
          },
          on: {
            "update:visible": function ($event) {
              return _vm.$set(_vm.dialog, "visible", $event)
            },
          },
        },
        [
          _c(_vm.componentName, {
            tag: "components",
            attrs: { props: _vm.dialog },
            on: { confirm: _vm.handlerSearch },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }