/*
 * @Author: sun.mengmeng
 * @Date: 2021-09-14 08:52:16
 * @LastEditors: sun.mengmeng
 * @LastEditTime: 2021-09-14 09:25:20
 * @Description: file content
 */
import defaultSettings from "@/settings";

const title =
  defaultSettings.title || "江苏集能易新能源技术有限公司｜WINNER Plant";

export default function getPageTitle(pageTitle) {
  if (pageTitle) {
    return `${pageTitle} - ${title}`;
  }
  return `${title}`;
}
