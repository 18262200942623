/*
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-03 11:27:00
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-09-20 21:40:50
 */

export default {
  methods: {
    /**
     *
     * 弹框传参逻辑公用处理
     * @param {Object} model=this.dialog
     * @param {*} title
     * @param {*} type
     * @param {*} params
     */
    MixinsDialogLogic(model, title, type, params) {
      if (!title) throw `title is must be`;
      if (!type) throw `type is must be ADD|MODIFY`;
      model.visible = true;
      model.title = title;
      model.type = type;
      model.data &&
        Object.keys(model.data).forEach((key) => {
          model.data[key] = params[key];
        });
    },
  },
};
