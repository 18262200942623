<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-14 10:12:38
 * @LastEditors: jixuyu
 * @LastEditTime: 2021-09-28 21:00:35
-->
<template>
  <div class="dashboard">
    <date-select></date-select>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import dateSelect from "./components/dateSelect.vue";

export default {
  name: "Dashboard",
  components: { dateSelect },
  computed: {
    ...mapGetters(["name"])
  },
  data() {
    return {
    };
  }
};
</script>

<style lang="scss" scoped>
@import "./style/index.scss";
</style>
