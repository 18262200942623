/*
 * @Description: 
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-20 22:00:52
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-09-20 22:31:05
 */

import Vue from 'vue'
    
/**权限指令**/

/**
 * @export 自定义指令
 */
export function directive() {
  Vue.directive('permit', {
    inserted(el, binding, vnode) {      
      // !vnode.context.$route.meta.permissions.includes(binding.value) ? el.parentNode.removeChild(el) : {}
    }
  })
}



