var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      directives: [
        {
          name: "permit",
          rawName: "v-permit",
          value: _vm.value,
          expression: "value",
        },
      ],
      class: ["WySpan", "WySpan-" + _vm.type],
      on: { click: _vm.handlerClick },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }