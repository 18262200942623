var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "childControl" },
    [
      _c("conrtl-box", { attrs: { idList: _vm.idList, child: true } }),
      _c("GnGrid", {
        ref: "grid",
        staticClass: "UserRoles-grid",
        attrs: {
          url: _vm.getListsAPI,
          params: _vm.gridOptions,
          methods: "get",
          defaultHeight: 500,
          tableStyle: _vm.tableStyle,
          "render-content": _vm.gridRenderContent,
        },
        on: {
          "select-change": _vm.selectAll,
          select: _vm.handleSelectionChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }