<!--
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2020-08-31 15:45:14
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-22 10:06:30
-->
<template>
  <div class="PersonalMsg">
    <el-row :gutter="10" class="msg-col">
      <el-col :span="8">
        <el-form label-width="150px" :rules="rules" ref="form" :model="ruleFrom">

          <el-form-item :label="$t('PersonalMsg.userName')" prop="username">
            <el-input v-model="ruleFrom.username"  disabled/>
          </el-form-item>
          <el-form-item :label="$t('PersonalMsg.Certified')" prop="email">
            <el-input v-model="ruleFrom.email" />
          </el-form-item>
          <el-form-item :label="$t('PersonalMsg.postcode')">
            <el-input  v-model="ruleFrom.postcode" />
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="6">
        <!-- <el-upload
          class="person-upload"
          :before-upload="beforeUploadImg"
          :headers="headers"
          :show-file-list="false"
          :action="action"
          :data="formData"
          :on-change="handleChangeImg"
          accept=".jpg,.jpeg,.png,.JPG,.JPEG"
        >
          <div class="upload-image" v-if="url">
            <img class="person_avatar" :src="url" />
          </div>
          <div class="upload-content" v-else>
            <p>
              <svg-icon class="icon" :iconClass="'shangchuantupian'"></svg-icon>
            </p>
            <p class="text">上传头像</p>
          </div>

          <p class="upload-tip">支持.jpg/.png/.jpeg格式</p>
        </el-upload> -->
      </el-col>
    </el-row>
    <div class="PersonalMsg-btn" style="margin-bottom: 20px">
      <el-button @click="ResertPsd" class="cancel">{{$t('PersonalMsg.reset')}}</el-button>
      <el-button
        :loading="loading"
        @click="handlerSubmit('form')"
        class="confirm"
      >
    {{$t('PersonalMsg.save')}}
      </el-button>
    </div>
    <el-drawer
      :title="dialog.title"
      :visible.sync="dialog.visible"
      size="30%"
      direction="rtl"
      :wrapperClosable="false"
    >
      <components :is="componentName" :props="dialog" />
    </el-drawer>
  </div>
</template>
<script>
/**
 * @author: JIXUYU
 * @since: 2020-08-31
 * @router('/PersonalMsg')
 */
import Add from "./components/add";
import {
  RulesTelphone,
  RulesEmail,
  RulesName,
  // RulesRole,
  RulesPassword,
} from "@/mixins/Rules";
import {
  editPersonData
} from "@/api/userManagement";
import {
 getInfo
} from "@/api/user";
import * as util from "@/utils";
import Load from "@/mixins/Load";
// import md5 from "js-md5";
// import { Base64 } from "js-base64";
const target = process.env.VUE_APP_BASE_API;
const env = process.env.NODE_ENV;
export default {
  name: "PersonalMsg",
  components: { Add },
  mixins: [Load],
  data() {

    return {
      action: target + "/oss/file/upload/base",
      headers: {

      },
      url: "",
      formData: {
        source: 3,
      },
      loading: false,
      ruleFrom: {
        username: "",
        email: "",
        postcode:''
      },
      componentName: "add",
      userData: util.readSession("userInfo"),
      rules: {
        username: RulesName,
        email: RulesEmail,
        // postcode: RulesTelphone,
        fullName: RulesName,
      },
      dialog: {
        title: this.$t("userManagement.reset"),
        visible: false,
        type: "ADD",
      },
    };
  },
  methods: {
    //图片上传限制
    beforeUploadImg(file) {
      const tokenData = util.readSession("UserInfo");
      const identify = md5(
        `cloud-pc:${tokenData.meb_userId}:'/oss/file/upload/batch'`
      );
      const random = Math.floor(Math.random() * 10000);
      const id = new Date().getTime() + random;
      const ide = `${identify}${new Date().getTime()}${id}`;
      const md = `${ide}:${tokenData.meb_userId}:${tokenData.access_token}`;
      this.headers["Content-MD5"] = Base64.encode(md);
      const arr = file.name.split(".");
      const name = arr[arr.length - 1];
      const files = ["png", "jpg", "jpeg"];
      if (!files.includes(name)) {
        this.$message({
          message: this.$t("roleManagement.TheUploadedFileFormatIsIncorrect"),
          type: "warning",
        });
        return false;
      }
    },
    //上传头像
    handleChangeImg(file, fileList) {
      if (file.status === "success") {
        if (file.response.code === 200) {
          const { data } = file.response;
          this.url = data[0].originalUrl;
          this.updateDetail();
        }
      }
    },
    /**
     * @description: 更改信息
     * @param {type}
     * @return {type}
     */
    async updateDetail() {
      const params = Object.assign(this.ruleFrom, {
      });
      const results = await this.MixinsLoading(
        "loading",
        editPersonData,
        params
      );
      if (results.code === 200) {
        this.loading = false;
        this.getDetail();
        this.$message.success(this.$t("userManagement.ModifySuccessfully"));
        const fullPath = this.$route.fullPath;
        // this.$router.push(`/login?redirect=${fullPath}`);
      }
    },
    /**
     * @description:  个人信息保存
     * @param {type}
     * @return {type}
     * @author: JIXUYU
     */
    handlerSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.updateDetail();
        } else {
          return false;
        }
      });
    },
    /**
     * @description:  密码重置
     * @param {type}
     * @return {type}
     * @author: JIXUYU
     */
    ResertPsd() {
      this.dialog.visible = true;
    },
    /**
     * @description: 获取用户详情
     * @param {type}
     * @return {type}
     */

    getDetail() {
      const params = {
       token:util.readSession("plant-token"),
      };
      getInfo(params).then((resp) => {
        const { data, code } = resp;
        if (code === 200 && data) {
          for (let key of Object.keys(this.ruleFrom)) {
            this.ruleFrom[key] = resp.data[key];
          }
        }
      });
    },
  },
  mounted() {},
  created() {
    this.getDetail();
  },
};
</script>

<style lang="scss" scoped>
@import "./style/index";
</style>
