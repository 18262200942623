<!--
 * @Author: jixuyu
 * @Date: 2021-09-20 15:48:34
 * @LastEditTime: 2022-04-26 17:21:28
 * @LastEditors: JIXUYU
 * @Description:
 * @FilePath: \front\src\views\config\components\categoryBottom.vue
 * 记得注释
-->
<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm1"
    label-width="200px"
    class="category-top"
  >
    <div class="top-item">
      <el-form-item :label="$t('config.manufacturer')" prop="Manufacturer" class="item-flex">
        <el-input v-model="ruleForm.Manufacturer" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item :label="$t('config.modelType')" class="item-flex">
        <el-input v-model="ruleForm.Connectortype"></el-input>
      </el-form-item>
    </div>
    <div class="top-item">
      <el-form-item
        :label="$t('config.comPower')"
        prop="Modulepower"
        class="item-width"
      >
        <el-input v-model="ruleForm.Modulepower" maxlength="5"></el-input>
      </el-form-item>
    </div>
  </el-form>
</template>

<script>
import { ModulePower, RuleManufacturer } from "@/utils/validate";
export default {
  components: {},
  props: {
    childrenData: {
      type: Object,
      default() {
        return null;
      }
    }
  },
  // 定义属性
  data() {
    return {
      rules: {
        Manufacturer: RuleManufacturer,
        Modulepower: ModulePower
      },
      ruleForm: {
        Id:"",
        Connectortype: "",
        Manufacturer: "",
        Modulepower: ""
      }
    };
  },
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    handleInitial(val) {
      if (val.row) {
        for (let key of Object.keys(this.ruleForm)) {
          this.ruleForm[key] = val.row[key];
        }
      }
    },
      //校验
    validateForm() {
      let boolean = false
      this.$refs['ruleForm1'].validate(valid => {
        if (valid) {
         boolean = true
        } else {
          boolean = false;
        }
      });
      return boolean
    }
  },
  created() {
    if (this.childrenData.row) {
      for (let key of Object.keys(this.ruleForm)) {
        this.ruleForm[key] = this.childrenData.row[key];
      }
    }
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
