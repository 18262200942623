/*
 * @Author: sun.mengmeng
 * @Date: 2021-09-14 08:52:16
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-08 17:53:17
 * @Description: file content
 */
import router from "./router";
import store from "./store";
import { Message } from "element-ui";
import NProgress from "nprogress"; // progress bar
import Layout from '@/layout' // 引入Layout
const _import = require('./router/_import_' + process.env.NODE_ENV) 
import "nprogress/nprogress.css"; // progress bar style
import getPageTitle from "@/utils/get-page-title";
const TokenKey = "plant-token";
import * as util from "@/utils/index";
NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ["/login","/404","/register"]; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();

  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = util.readSession(TokenKey);

  if (hasToken) {
    if (to.path === "/login") {
      // if is logged in, redirect to the home page
      next({ path: "/" });
      NProgress.done();
    } else {
      const hasGetUserInfo = store.getters.name;
      if (hasGetUserInfo) {
        next();
      } else {
       try {
        await store.dispatch('user/setAllRoutes')
        console.log('------')
          // get user info
         await store.dispatch("user/getInfo");
          // **在这里做动态路由**
          if (store.getters.menus.length < 1) {
           global.antRouter = []
           next()
        }
        // 接口返回
        const menus = filterAsyncRouter(store.getters.menus) // 过滤路由
        console.log(menus)
         router.addRoutes(menus) // 动态添加路由
         global.antRouter = menus // 将路由数据传递给全局变量，做侧边栏菜单渲染工作
         next({ ...to, replace: true })
       } catch (err) {
        console.log(err)
          // remove token and go to login page to re-login
          await store.dispatch("user/resetToken");
          Message.error("Has Error");
          next(`/login?redirect=${to.path}`);
          NProgress.done();
        }
      }
    }
  } else {
    /* has no token*/

    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`);
      NProgress.done();
    }
  }
});

router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});


/**
 * @description: 遍历后台传来的路由字符串，转换为组件对象
 * @param {*} asyncRouterMap
 * @return {*}
 * @author: JIXUYU
 */
function filterAsyncRouter (asyncRouterMap) {
 const accessedRouters = asyncRouterMap.filter(route => {
  try{
   if (route.component) {
   if (route.component === "Layout") { // Layout组件特殊处理
    route.component = Layout
   } else {
    route.component = _import(route.component) // 导入组件
   }
  }
  if (route.children && route.children.length) {
   route.children = filterAsyncRouter(route.children)
  }
  return true
  } catch (eror) {
   console.log(eror)
   
 }
 })
 return accessedRouters
}

