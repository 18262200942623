/*
 * @Author: sun.mengmeng
 * @Date: 2021-09-14 13:44:49
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-11-03 22:18:28
 * @Description: file content
 */
import request from "@/utils/request";

export function login(data) {
  return request({
    url: "/login",
    method: "post",
    data,
  });
}

export function getInfo(token) {
  return request({
    url: "/user/getInfo",
    method: "get",
    params: { token },
  });
}

export function logout() {
  return request({
    url: "/logout",
    method: "post",
  });
}



export function getRouterList() {
 return request({
   url: "/sys/menu/tree",
   method: "post",
 });
}



/**
 * @description: 电站切换
 * @param {*} params
 * @return {*}
 * @author: JIXUYU
 */
export function switchStation(params) {
 return request({
   url: "/user/switchStation",
  method: "post",
  params
 });
}




/**
 * @description:  重置密码的验证码获取
 * @param {*} data
 * @return {*}
 * @author: JIXUYU
 */
export function sendEmail(data) {
 return request({
   url: "/findPwd/send",
  method: "post",
  data
 });
}


/**
 * @description: 重置密码提交
 * @param {*} data
 * @return {*}
 * @author: JIXUYU
 */
export function submitCode(data) {
 return request({
   url: "/findPwd/submit",
  method: "post",
  data
 });
}