/*
 * @Author: jixuyu
 * @Date: 2021-11-14 14:23:07
 * @LastEditTime: 2022-03-21 16:52:12
 * @LastEditors: jixuyu
 * @Description: 
 * @FilePath: \front\src\api\design.js
 * 记得注释
 */
import request from "@/utils/request";

//电站逆变器组串关系tree
export function allData(params) {
  return request({
    url: "/stationlayout/tree",
    method: "get",
  });
}

//获取优化器时间段的数据
export function getAllTimeData(params) {
  return request({
    url: "/stationlayout/getHoneybeeLayoutDateLineData",
    method: "get",
    params: params
  });
}

