var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "GNTabs" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-remove": _vm.removeTab, "tab-click": _vm.changeTabs },
          model: {
            value: _vm.editableTabsValue,
            callback: function ($$v) {
              _vm.editableTabsValue = $$v
            },
            expression: "editableTabsValue",
          },
        },
        [
          _vm._l(_vm.editableTabs, function (item) {
            return _c(
              "el-tab-pane",
              {
                key: item.name,
                attrs: {
                  closable: _vm.closable,
                  label: item.title,
                  name: item.name,
                },
              },
              [
                _c(_vm.componentName, {
                  ref: item.content,
                  refInFor: true,
                  tag: "component",
                  attrs: { childrenData: item },
                  on: { closeTabs: _vm.closeTabs },
                }),
              ],
              1
            )
          }),
          _vm.isAdd
            ? _c(
                "el-tab-pane",
                {
                  key: "0000",
                  attrs: { name: "0000000", disabled: "" },
                  on: { click: _vm.addPane },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "pane-add",
                      attrs: { slot: "label" },
                      on: { click: _vm.addPane },
                      slot: "label",
                    },
                    [_vm._v("+")]
                  ),
                ]
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }