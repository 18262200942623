<!--
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-09 16:57:21
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-08 16:26:10
-->
<template>
  <section>
    <el-form
      label-width="150px"
      :rules="rules"
      ref="ruleForm"
      :model="ruleForm"
      class="addUserDialog"
    >
      <el-form-item :label="$t('register.userName')" prop="username">
        <el-input v-model="ruleForm.username" :readonly="props && props.type === 'MODIFY'" />
      </el-form-item>
      <el-form-item :label="$t('register.surname')" prop="firstname">
        <el-input v-model="ruleForm.firstname" />
      </el-form-item>
        <el-form-item :label="$t('register.chanName')" prop="lastname">
        <el-input v-model="ruleForm.lastname" />
      </el-form-item>
      <el-form-item :label="$t('register.naromlRole')" prop="roleId">
        <el-select
          v-model="ruleForm.roleId"
          :placeholder="$t('register.Prole')"
          v-loadmore='loadMoreRole'
          style="width: 100%"
        >
          <el-option
            v-for="item of roleList"
            :key="item.id"
            :label="item.roleName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('register.email')" prop="email">
        <el-input v-model="ruleForm.email" />
      </el-form-item>

      <el-form-item
        :label="$t('PersonalMsg.initalPasw')"
        prop="password"
        v-if="props && props.type === 'ADD'"
      >
        <el-input type="password" v-model="ruleForm.password" auto-complete="new-password"/>
      </el-form-item>
      <el-form-item
        :label="$t('register.ConfirmPassword')"
        prop="confimPassword"
        v-if="props && props.type === 'ADD'"
      >
        <el-input type="password" v-model="ruleForm.confimPassword" auto-complete="new-password"/>
      </el-form-item>

      <el-form-item
        :label="$t('PersonalMsg.initalPasw')"
        v-if="props && props.type === 'MODIFY'"
      >
        <el-input v-model="ruleForm.modifyPassword" auto-complete="new-password"/>
      </el-form-item>
      <el-form-item :label="$t('stationReport.stationList')">
        <el-select
            v-model="ruleForm.stationIds"
            multiple
            filterable
            remote
            reserve-keyword
            :placeholder="$t('stationReport.SearchPowerStation')"
            :remote-method="remoteMethod"
            :loading="loading1"
            style="width: 100%">
            <el-option
              v-for="item in stations"
              :key="item.id"
              :label="item.name+'|'+item.city"
              :value="item.id">
            </el-option>
          </el-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button @click="props.visible = false" class="cancel">{{this.$t('stationReport.cancel')}}</el-button>
      <el-button
        :loading="loading"
        class="confirm"
        @click="handlerSubmit('ruleForm')"
      >
        {{this.$t('roleManagement.submit')}}
      </el-button>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  getStations,
  getRoleList,
  deleteUser,
  addUser,
  editUser,
} from "@/api/userManagement";
import {
  RulesTelphone,
  RulesEmail2,
  // RulesResource,
  // RulesRole,
  // RulesName,
  RulesPassword,
  RulesUserName,
} from "@/mixins/Rules";
// import md5 from "js-md5";
export const InterfaceParams = {
  id: null,
  firstname: "",
  username: "",
  lastname:'',
  email: "",
  mobile: "",
  roleId: "",
  password: "",
  confimPassword: "",
  modifyPassword:""
};
export default {
  props: {
    props: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const valPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('addRules.Powassd')));
      } else {
        if (this.ruleForm.confimPassword !== "") {
          this.$refs.ruleForm.validateField("confimPassword");
        }
        if (value.length > 16) {
          callback(new Error(this.$t('addRules.nextCode')));
        } else if (value.length < 6) {
          callback(new Error(this.$t('addRules.nextCode')));
        } else {
        }
        const match = RulesPassword[0].pattern;
        if (match.test(value)) {
          callback();
        } else {
          callback(new Error(this.$t('addRules.nextNum')));
        }
      }
    };
    const valConfimPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('addRules.PowassdAgen')));
      } else if (value !== this.ruleForm.password) {
        callback(new Error(this.$t('addRules.NoSure')));
      } else {
        callback();
      }
    };
    const valModifyPassword = (rule, value, callback)=>{
      if (value== undefined || value === "") {
        callback();
      }else{
        if (value.length > 16) {
          callback(new Error(this.$t('addRules.nextCode')));
        } else if (value.length < 6) {
          callback(new Error(this.$t('addRules.nextCode')));
        } else {
        }
        const match = RulesPassword[0].pattern;
        if (match.test(value)) {
          callback();
        } else {
          callback(new Error(this.$t('addRules.nextNum')));
        }
      }
    }

    return {
      loading: false,
      loading1: false,
      id: "",
      roleList: [],
      stations: [],
      currentRole:1,
      currentStation:1,
      rules: {
        username: RulesUserName,
        firstname: RulesUserName,
        lastname:RulesUserName,
        roleId:RulesUserName,
        email: RulesEmail2,
        mobile: RulesTelphone,
        password: [
          {
            validator: valPassword,
            trigger: ["blur", "change"],
            required: true,
          },
        ],
        confimPassword: [
          {
            validator: valConfimPassword,
            trigger: ["blur", "change"],
            required: true,
          },
        ],
        modifyPassword: [
          {
            validator: valModifyPassword,
            trigger: ["blur", "change"]
          },
        ]
      },
      ruleForm: {
        firstname: "",
        username: "",
        lastname:'',

        email: "",
        // companyId: "",
        roleId: "",
        password: "",
        confimPassword: "",
        stationIds: []
      },
    };
  },

  watch: {
    "props.visible": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal && this.props.type === "ADD") {
          this.$refs["ruleForm"] && this.$refs["ruleForm"].resetFields();
          Object.keys(this.ruleForm).forEach((key) => {
            this.ruleForm[key] = "";
          });
        } else {
          this.$refs["ruleForm"] && this.$refs["ruleForm"].resetFields();
          Object.keys(this.ruleForm).forEach((key) => {
            if (key !== "password") {
              this.ruleForm[key] = this.props.data[key];
            }
          });
        }
        this.remoteMethod('', true);
      },
    },
  },
  created() {
    this.updateRoleList();
  },
  mounted() {

  },
  methods: {
        /**
     * @description: 数组滤重，去除重复数据
     * @param {type}
     * @return {type}
     */
    filterArray(arr) {
      let brokeData = {};
      const filter = arr.reduce((cur, next) => {
        brokeData[next.id] ? "" : (brokeData[next.id] = true && cur.push(next));
        return cur;
      }, []);

      return filter;
    },
    /**
     * @description: 加载更多
     * @param {*}
     * @return {*}
     * @author: JIXUYU
     */
    loadMoreRole() {
      this.currentRole += 1;
      this.updateRoleList();
    },
    /**
     * @description: 获取角色列表
     * @param {type}
     * @return {type}
     */
    updateRoleList() {
      const params = {
        current:  this.currentRole,
        size: 10,
      };
      getRoleList(params).then((resp) => {
        if (resp.code === 200 && resp.data) {
          const arr = [...this.roleList, ...resp.data.records];
          this.roleList = this.filterArray(arr);
        }
      });
    },
    /**
     * @description: 参数转换
     * @param {*} data
     * @return {*}
     * @author: JIXUYU
     */
    handleEditParams(data) {
      let json = {
        username: data.username,
        firstname: data.firstname,
        lastname:data.lastname,
        password:data.password,
        email: data.email,
        roleId: data.roleId,
        stationIds: data.stationIds
      };
      if(this.props && this.props.type === 'MODIFY'){
        json.password = data.modifyPassword;
      }
      return json;
    },
    /**
     * @description: 提交
     * @param {type}
     * @return {type}
     */
    handlerSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.props && this.props.data.id) {
            const json = Object.assign(this.handleEditParams(this.ruleForm), {
              id: this.props.data.id,
            });
            if(json.stationIds===null || json.stationIds.length===0){
              this.$confirm(this.$t("config.doDeleteUserStations"), this.$t('common.warning'), {
                confirmButtonText: this.$t("config.confirm"),
                cancelButtonText: this.$t("config.cancel"),
                  type: "warning",
                }).then(() => {
                  editUser(json).then((resp) => {
                    if (resp.code === 200) {
                      this.$refs[formName].resetFields();
                      this.props.visible = false;
                      this.$emit("confirm", false);
                    }
                  });
                }).catch(() => {});
            }else{
              editUser(json).then((resp) => {
                if (resp.code === 200) {
                  this.$refs[formName].resetFields();
                  this.props.visible = false;
                  this.$emit("confirm", false);
                }
              });
            }
          } else {
            const params = this.handleEditParams(this.ruleForm);
            addUser(params).then((resp) => {
              if (resp.code === 200) {
                this.$refs[formName].resetFields();
                this.props.visible = false;
                this.$emit("confirm", false);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    /**
     * 获取用户电站以供选择
     * @param {Object} queryStr
     */
    remoteMethod(queryStr, bool) {
      this.loading1 = true;
      let userId = '';
      if(this.props && this.props.data.id){
        userId = this.props.data.id;
      }
      const params = {
        current: this.currentStation,
        size: 100,
        queryStr: queryStr.trim(),
        userId: userId
      };
      getStations(params).then((resp) => {
        if (resp.code === 200 && resp.data) {
          this.stations = resp.data.records;
          if(bool){
            this.ruleForm.stationIds=[];
            this.stations.forEach((v,i)=>{
              if(v.installed){
                this.ruleForm.stationIds.push(v.id);
              }
            });
          }
        }
        this.loading1 = false;
      }).catch(()=>{
          this.loading1 = false;
      });
    }
  },
};
</script>
<style lang="scss" scoped>
.dialog-footer {
  text-align: center;
}
</style>
