<!--
 * @Author: sun.mengmeng
 * @Date: 2021-09-17 13:57:06
 * @LastEditors: jixuyu
 * @LastEditTime: 2021-09-24 23:02:02
 * @Description: file content
-->
<template>
  <div class="common-search">
    <p class="main-left">
      <span class="left-name">{{title}}</span
      ><span class="iconfont icon-list_alt left-icon"></span>
    </p>
    <div class="main-right">
      <el-input
        :placeholder="date"
        suffix-icon="el-input__icon el-icon-search"
        v-model="searchValue"
      >
      </el-input>
    </div>
  </div>
</template>

<script>
/**
 * @author: sunmengmeng
 * @since: 2021-09-17
 * @router('/index')
 */
import i18n from "@/lang";
export default {
  name: "index",
  components: {},
  props: {
    title: {
      type: String,
      default: i18n.t("stationReport.stationList")
    }
  },
  data() {
    return {
      date: this.$t("stationReport.SearchPowerStation"),
      searchValue:''
    };
  },
  watch: {},
  computed: {},
  methods: {},
  created() {},
  mounted() {}
};
</script>

<style lang="scss" scoped>
.common-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  .main-left {
    .left-name {
      font-size: 20px;
      color: #252733;
    }

    .left-icon {
      color: #70a436;
      margin-left: 8px;
      font-size: 18px;
    }
  }

  .main-right {
    width: 400px;
  }
}
</style>
