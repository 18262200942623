var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "bee-component" },
    [
      _c("GnGrid", {
        ref: "grid",
        staticClass: "UserRoles-grid",
        staticStyle: { width: "100%" },
        attrs: {
          url: _vm.getRoleListsAPI,
          params: _vm.gridOptions,
          "render-content": _vm.gridRenderContent,
          bodyParser: _vm.bodyParser,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }