/*
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-25 19:54:12
 * @LastEditors: JIXUYU
 * @LastEditTime: 2021-11-14 21:33:06
 */
import request from "@/utils/request";
import axios from "axios";
const baseUrl = process.env.VUE_APP_BASE_API;

/**
 * @description: 获取验证码
 * @param {*} data
 * @return {*}
 * @author: JIXUYU
 */
export function captcha(data) {
  return axios({
    url: `${baseUrl}/captcha`,
    method: "get",
    // responseType: "arraybuffer",
    params:data,
  });
}
/**
 * @description: 验证验证码
 * @param {*} data
 * @return {*}
 * @author: JIXUYU
 */
export function verify(data) {
 return request({
   url: "/verify",
   method: "POST",
   data:data,
 });
}

/**
 * @description: 注册
 * @param {*} data
 * @return {*}
 * @author: JIXUYU
 */
 export function register(data) {
  return request({
    url: "/register",
    method: "post",
    data:data,
  });
 }


/**
 * @description: 获取时区
 * @param {*} data
 * @return {*}
 * @author: JIXUYU
 */
export function getTimeZone() {
 return request({
   url: "/dist/timezone",
   method: "get",
 });
}
/**
 * 获取货币
 * @returns
 */
export function getCurrency() {
  return request({
    url: "/dist/currency",
    method: "get"
  })
}
/**
 * @description: 控制开关
 * @param {*} data
 * @return {*}
 * @author: JIXUYU
 */
 export function cmdControl(data) {
  return request({
    url: "/status/cmdControl",
    method: "post",
    data:data,
  });
 }

/**
 * 用户名是否存在
 * @param {*} value
 * @returns
 */
 export function isExist(value) {
  return request({
    method: 'get',
    url: '/app2/register/isExist',
    params: {
      'value': value
    }
  });
 }

 /**
  * 注册
  * @param {*} data
  * @returns
  */
export function doRegister(data) {
  return request({
    url: "/app2/register/doRegister",
    method: "post",
    data:data,
  })
}

/**
 * 发送邮箱验证码
 * @param {*} email
 * @returns
 */
export function toEmailCaptcha(email) {
  return request({
    method: 'get',
    url: '/app2/captcha/toEmail',
    params: {
      'email': email
    }
  })
}



