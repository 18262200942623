/*
 * @Description:
 * @Version: 1.0
 * @Autor: JIXUYU
 * @Date: 2021-09-03 11:27:00
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-08 21:26:01
 */

import i18n from "@/lang";

// 必填字段
export const RULESREQUIRE = [
 {
   required: true,
   message: i18n.t("rules.TheContentCannotBeEmpty"),
   trigger: ["blur", 'change'],
 },

];

// 字段长度小于64
export const RULESREQUIRENUMBER = [
 {
   min: 1,
   max: 64,
   message: i18n.t("rules.TheValueContainsAMaximunOf64Characters")
 }
];
// 字段长度小于16
export const RULESREQUIREMIN = [
 {
   min: 1,
   max: 16,
   message: i18n.t("rules.TheContentCannotExceed16Characters")
 }
];


// 内容必须为数字
export const NUMBERREQUEST = [
 {
   type: "number",
   message: i18n.t("rules.TheContentMustBeNumeric"),
 },
];

// 内容必须为英文+数字pattern
export const ENGNUMBERPATTERN = [
 {
   pattern: /^[\d|a-zA-Z]+$/,
   message: i18n.t("rules.TheContentMustBeLettersAndNumbers"),
 },
];

// 内容必须为数字
export const RulesNumber = [
 {
   required: true,
   pattern: /^100$|^(\d|[1-9]\d)$/,
   message: i18n.t("rules.TheContentMustBeNumeric"),
   trigger: ["blur", "change"],
 },
];

export const RulesTelphone = [
{
  pattern: /^1[34578]\d{9}$/,
  message: i18n.t("rules.PleaseFillInTheCorrectCellPhoneNumber"),
  required: true,
  trigger: ["blur", "change"],
},
];

export const RulesEmail2 = [
{
  pattern: /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,
  message: i18n.t("rules.PleaseFillInTheCorrectEmailAddress"),
  trigger: ["blur", "change"],
},
{
  min: 0,
  max: 64,
  message: i18n.t("rules.ContainsMoreThan64Characters"),
  trigger: "change"
},
];

export const RulesEmail = [
{
  pattern: /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/,
  message: i18n.t("rules.PleaseFillInTheCorrectEmailAddress"),
  required: true,
  trigger: ["blur", "change"],
},
{
  min: 1,
  max: 64,
  message: i18n.t("rules.ContainsMoreThan64Characters"),
  trigger: "change"
},
];

export const RulesName = [
{
  required: true,
  message: i18n.t("rules.PleaseEnterTheCorrectFormat"),
  trigger: ["blur", "change"],
  // pattern: /^[a-zA-Z0-9_\-\u4e00-\u9fa5]+$/,
},
{
  min: 1,
  max: 32,
  message: i18n.t("rules.TheValueContainsMoreThan32Characters"),
  trigger: "change"
},
];

export const RulesResource = [
{
  required: true,
  message: i18n.t("rules.PleaseEnterContent"),
  trigger: ["blur", "change"],
},
];

export const RulesCompany = [
{
  required: true,
  message: i18n.t("rules.PleaseSelectCompany"),
  trigger: "change"
},
];

export const RulesRole = [
{
  required: true,
  message: i18n.t("rules.PleaseSelectARoleType"),
  trigger: "change"
},
];

export const RulesTagsId = [
{
  pattern: /^\d{1,}$/g,
  message: i18n.t("rules.TheIdCanOnlyBeANumber"),
  required: true,
  trigger: "change",
},
];
export const RulesSchemeVersion = [
{ required: true, message: i18n.t("rules.TheContentCannotBeEmpty"), trigger: "change" },
{ min: 1, max: 32, message: i18n.t("rules.TheValueContainsMoreThan32Characters"), trigger: "change" },
{
  required: true,
  message: i18n.t("rules.PleaseEnterTheCorrectFormat"),
  trigger: ["blur", "change"],
  pattern: /^(?![0-9]+$)(?![` ~!@#$%^&*()\+=<>?:"、{}|,.\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】；‘'，。]+$)/,
},
];
export const RulesTagsName = [
{ required: true, message: i18n.t("rules.PleaseEnterTheLabelName"), trigger: "change" },
{ min: 1, max: 16, message: i18n.t("rules.ContainsMoreThan16Characters"), trigger: "change" },
{
  required: true,
  message: i18n.t("rules.PleaseEnterTheCorrectFormat"),
  trigger: ["blur", "change"],
  pattern: /^(?![0-9]+$)(?![` ~!@#$%^&*()\+=<>?:"、{}|,.\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】；‘'，。]+$)/,
},
];

export const RulesProjectTypeName = [
{ required: true, message: i18n.t("rules.PleaseEnterContent"), trigger: "change" },
{ min: 1, max: 16, message: i18n.t("rules.ContainsMoreThan16Characters"), trigger: "change" },
{
  required: true,
  message: i18n.t("rules.PleaseEnterTheCorrectContent"),
  trigger: ["blur", "change"],
  pattern: /^(?![0-9]+$)(?![` ~!@#$%^&*()\+=<>?:"、{}|,.\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】；‘'，。]+$)/,
},
];

export const RulesProjectExplain = [
{
  required: false,
  message: i18n.t("rules.PleaseEnterTheCorrectFormat"),
  trigger: ["blur", "change"],
  pattern: /^(?![0-9]+$)(?![` ~!@#$%^&*()\+=<>?:"、{}|,.\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】；‘'，。]+$)/,
},
];

export const RulesCommon = [
{
  required: true,
  message: i18n.t("rules.TheContentCannotBeEmpty"),
  trigger: ["blur", "change"],
},
];

export const RulesPicture = [
{ required: true, message: i18n.t("rules.PleaseSelectA"), trigger: "change" },
];

export const RulesModel = [
{
  required: true,
  message: i18n.t("rules.PleaseEnterAModelName"),
  trigger: ["blur", "change"],
},
{ min: 1, max: 32, message: i18n.t("rules.ContainsMoreThan16Characters"), trigger: "change" },
];

export const RulesVersion = [
{
  required: true,
  message: i18n.t("rules.PleaseEnterTheVersionNumber"),
  trigger: ["blur", "change"],
},
{ min: 1, max: 16, message: i18n.t("rules.ContainsMoreThan16Characters"), trigger: "change" },
];

export const RulesDes = [
{
  required: true,
  message: i18n.t("rules.PleaseEnterTheModelDescription"),
  trigger: ["blur", "change"],
},
{ min: 1, max: 64, message: i18n.t("rules.ContainsMoreThan64Characters"), trigger: "change" },
];

export const RulesEnv = [
{ required: true, message: i18n.t("rules.PleaseSelectTheTrainingEnvironment"), trigger: "change" },
];

export const RulesEntry = [
{ required: true, message: i18n.t("rules.PleaseEnterTheAlgorithmPath"), trigger: ["blur", "change"] },
];

export const RulesDataset = [
{ required: true, message: i18n.t("rules.PleaseSelectTheTrainingSet"), trigger: ["change"] },
];
export const RulesScheme = [
{ required: true, message: i18n.t("rules.PleaseSelectReviewers"), trigger: ["change"] },
];
export const RulesPlan = [
{ required: true, message: i18n.t("rules.PleaseSelectAPlan"), trigger: ["change"] },
];
export const RulesPassReaseon = [
{ required: false, message: i18n.t("rules.PleaseInputYourReviewComments"), trigger: ["change"] },
{ min: 1, max: 64, message: i18n.t("rules.TheValueContainsAMaximunOf64Characters"), trigger: "change" },
];
export const RulesSchemeName = [
{
  required: true,
  message: i18n.t("rules.PleaseEnterASchemaName"),
  trigger: ["blur", "change"],
},
{ min: 1, max: 16, message: i18n.t("rules.ContainsMoreThan16Characters"), trigger: "change" },
];
///^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$\\S{6,16}$/
export const RulesPassword = [
{
  pattern: /^(?![a-zA-z]+$)(?!\d+$)(?![!@#$%^&*]+$)[a-zA-Z\d!@#$%^&*]+$/,
  message: i18n.t("rules.ThePasswordIsACombinationOfLettersDigitsAndCharacters"),
  required: true,
  trigger: "change",
},
];

export const RulesProcessName = [
{
  required: true,
  message: i18n.t("rules.PleaseEnterTheCorrectName"),
  trigger: ["blur", "change"],
  pattern: /^[a-zA-Z0-9_\-\u4e00-\u9fa5]+$/,
},
{ min: 1, max: 16, message: i18n.t("rules.ContainsMoreThan16Characters"), trigger: "change" },
];

export const RulesProcessDes = [
{
  required: true,
  message: i18n.t("rules.PleaseEnterCorrectInstructions"),
  trigger: ["blur", "change"],
  pattern: /^[a-zA-Z0-9_\-\u4e00-\u9fa5]+$/,
},
{ min: 1, max: 16, message: i18n.t("rules.ContainsMoreThan16Characters"), trigger: "change" },
];

export const RulesProcessPath = [
{
  required: true,
  message: i18n.t("rules.EnterTheCorrectPath"),
  trigger: ["blur", "change"],
  pattern: /^([A-Za-z]{1}:\/|^\/)([\w]*\/)*\w+$/,
},
{ min: 1, max: 128, message: i18n.t("rules.TheValueContainsMoreThan128Characters"), trigger: "change" },
];

export const RulesCommonName = [
{
  required: true,
  message: i18n.t("rules.PleaseEnterTheCorrectContent"),
  trigger: ["blur", "change"],
  pattern: /^(?![0-9]+$)(?![` ~!@#$%^&*()\+=<>?:"、{}|,.\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】；‘'，。]+$)/,
},
{ min: 1, max: 16, message: i18n.t("rules.ContainsMoreThan16Characters"), trigger: "change" },
];

export const RulesCompanyName = [
{
  required: true,
  message: i18n.t("rules.PleaseEnterTheCorrectContent"),
  trigger: ["blur", "change"],
  pattern: /^(?![0-9]+$)(?![` ~!@#$%^&*()\+=<>?:"、{}|,.\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】；‘'，。]+$)/,
},
{ min: 1, max: 64, message: i18n.t("rules.ContainsMoreThan64Characters"), trigger: "change" },
];

export const RulesUserName = [
{
  required: true,
  message: i18n.t("rules.PleaseEnterTheCorrectContent"),
  trigger: ["blur", "change"],
  pattern: /^(?![0-9]+$)(?![` ~!@#$%^&*()\+=<>?:"、{}|,.\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】；‘'，。]+$)/,
},
{ min: 1, max: 32, message: i18n.t("rules.TheValueContainsMoreThan32Characters"), trigger: "change" },
];

export const RulesArray = [
{
  type: "array",
  required: true,
  message: i18n.t("rules.PleaseSelectAtLeastOne"),
  trigger: "change",
},
];

export const RulesDescript = [
{
  required: false,
  message: i18n.t("rules.PleaseEnterCorrectInstructions"),
  trigger: ["blur", "change"],
  pattern: /^(?![0-9]+$)(?![` ~!@#$%^&*()\+=<>?:"、{}|,.\/;'\\[\]·~！@#￥%……&*（）——\+={}|《》？：“”【】；‘'，。]+$)/,
},
{ min: 1, max: 256, message: "长度不超过256个字符", trigger: "change" },
];

export const RulesEdition = [
{
  required: true,
  message: i18n.t("rules.PleaseEnterTheCorrectContent"),
  trigger: ["blur", "change"],
  pattern: /^[a-zA-Z0-9_.\-]+$/,
},
{ min: 1, max: 16, message: i18n.t("rules.ContainsMoreThan16Characters"), trigger: "change" },
];
