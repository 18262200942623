<!--
 * @Author: sun.mengmeng
 * @Date: 2021-08-30 16:08:03
 * @LastEditors: JIXUYU
 * @LastEditTime: 2022-08-24 22:14:29
 * @Description: file content
-->
<template>
  <echarts :option="option" v-bind="$attrs" v-on="$listeners"></echarts>
</template>
<script>
import echarts from "./base-chart";

export default {
  name: "chart-double",
  components: {
    echarts
  },
  props: {
    animation: {
      type: Boolean,
      default: true
    },
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    chartData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    option() {
    const self = this;
    let lengData=[]
    const { xAxis, yAxis, name, } = this.chartData;
    let colorList=[{color1:"#fccb05",color2:"#f5804d"},{color1:"#BEF4D0",color2:"#70a436"},{color1:"#de6e6a",color2:"#de7e7a"}]
    if (Array.isArray(yAxis)) {
    yAxis.forEach((item, index) => {
     lengData.push(item.name)
   item=Object.assign(item,{
       type: "bar",
       barWidth: "11px",
       itemStyle: {
        color: {
         type: "linear",
         x: 0, //右
         y: 0, //下
         x2: 1, //左
         y2: 1, //上
         colorStops: [
          {
           offset: 1,
           color: colorList[index].color1
          },
          {
           offset: 0.5,
           color: colorList[index].color2
          }
         ],
         globalCoord: true
        },
        // 图形的形状
        barBorderRadius: [11, 11, 0, 0]
       }
      })
     });
    }
    console.log(yAxis)
     let series =yAxis
      const _option = {
        title: {
          text: name,
          textStyle: {
            color: "#252733",
            fontSize: 16
          },
          padding: 20,
          left: -10,
          top: -15
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            crossStyle: {
              color: "#999"
            }
          }
        },
        legend: {
          data: lengData?lengData:[],
          left: '15%',
          top: 30,
          textStyle: {
            color: "#666666"
          },
          itemWidth: 15,
          itemHeight: 10,
          itemGap: 25
        },

        grid: {
          left: "10%",
          right: "5%",
          bottom: "10%"
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            data: xAxis,
            axisPointer: {
              type: "shadow"
            },
            nameTextStyle: {
              // 坐标轴名称样式
              color: " #7A7A7A",
              fontSize: 10
            }
          }
        ],

        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: "{value}"
            },
            name:'kWh',
            nameTextStyle: {
              color: " #7A7A7A",
              fontSize: 10
            },
            axisLine: {
              show: true
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: series
      };
      // 配置合并
      return Object.assign(_option, self.options);
    }
  },
  methods: {}
};
</script>
