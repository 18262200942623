var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    {
      ref: "ruleForm1",
      staticClass: "category-top",
      attrs: { model: _vm.ruleForm, rules: _vm.rules, "label-width": "200px" },
    },
    [
      _c(
        "div",
        { staticClass: "top-item" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: {
                label: _vm.$t("config.manufacturer"),
                prop: "Manufacturer",
              },
            },
            [
              _c("el-input", {
                attrs: { maxlength: "20" },
                model: {
                  value: _vm.ruleForm.Manufacturer,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "Manufacturer", $$v)
                  },
                  expression: "ruleForm.Manufacturer",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              staticClass: "item-flex",
              attrs: { label: _vm.$t("config.modelType") },
            },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.Connectortype,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "Connectortype", $$v)
                  },
                  expression: "ruleForm.Connectortype",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "top-item" },
        [
          _c(
            "el-form-item",
            {
              staticClass: "item-width",
              attrs: { label: _vm.$t("config.comPower"), prop: "Modulepower" },
            },
            [
              _c("el-input", {
                attrs: { maxlength: "5" },
                model: {
                  value: _vm.ruleForm.Modulepower,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "Modulepower", $$v)
                  },
                  expression: "ruleForm.Modulepower",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }