<!--
 * @Author: jixuyu
 * @Date: 2021-09-21 08:30:29
 * @LastEditTime: 2022-04-26 17:29:20
 * @LastEditors: JIXUYU
 * @Description: 
 * @FilePath: \front\src\views\config\components\configForm.vue
 * 记得注释
-->
<template>
  <el-form
    :model="ruleForm"
    ref="ruleForm"
    label-width="86px"
    class="configForm"
  >
    <div
      v-for="(item, index) of ruleForm.formList"
      :key="index"
      class="collect-item"
    >
      <el-form-item class="item-first">
        <el-select
          v-model="item.selectType"
          class="first-select"
          :placeholder=" $t('config.Pselect')"
          @change="changeType(item, index)"
        >
          <el-option
            v-for="(item, index) in selectType"
            :key="index"
            :label="item.name"
            :value="item.type"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <ul class="item-content">
        <li
          class="item-second"
          v-for="(item1, index1) of item.renderList"
          :key="index1"
        >
          <el-form-item
            class="second-flex"
            label="MAC ID："
            :prop="`formList.${index}.renderList.${index1}.mac`"
            :rules="[
              {
                required: true,
                message:$t('config.PMAc'),
                trigger: ['blur']
              }
            ]"
          >
            <el-tooltip
              effect="light"
              :content="$t('config.MacNotExist')"
              placement="right"
              :disabled="item1.show"
            >
              <el-input
                @blur="
                  testMac(
                    item1.mac,
                    item1,
                    `formList.${index}.renderList.${index1}.id`
                  )
                "
                v-model="item1.mac"
              ></el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item
            class="second-flex"
            :label="$t('config.Components')"
            :prop="`formList.${index}.renderList.${index1}.id`"
            :rules="[
              {
                required: true,
                message: $t('config.PComponents'),
                trigger: ['change']
              }
            ]"
          >
            <el-select
              v-model="item1.id"
              class="first-select"
              :placeholder="$t('config.Pselect')"
            >
              <el-option
                v-for="(item, index) in typeList"
                :key="index"
                :label="`${item.Manufacturer}(${item.Modulepower}W)`"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="item-width ">
            <div class="width-btn">
              <p
                class="btn-delete btn-common"
                @click="deleteItem(item, item1, index, index1)"
              >
                {{this.$t('config.delete')}}
              </p>
              <p class="btn-add btn-common" @click="addForm()"> {{this.$t('config.insert')}}
</p>
            </div>
          </el-form-item>
        </li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import { testMacId } from "@/api/config";
import * as util from "@/utils";
export default {
  components: {},
  props: {},
  // 定义属性
  data() {
    return {
      // selectType: [
      //   {
      //     name: $t('config.Single'),
      //     type: "signle"
      //   },
      //   {
      //     name: $t('config.Double'),
      //     type: "double"
      //   }
      // ],
      typeList: [],
      ruleForm: {
        formList: [
          {
            type: "add",
            selectType: "single",
            renderList: [
              {
                mac: "",
                id: "",
                mainKey: true,
                show: true,
                id:''
              }
            ]
          }
        ]
      }
    };
  },
  computed: {

    selectType(){
    return  [
        {
          name: $t('config.Single'),
          type: "signle"
        },
        {
          name: $t('config.Double'),
          type: "double"
        }
      ]


    }
 




  },
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {
    //验证macId是否合法
    testMac(mac, item, prop) {
      const params = {
        macId: mac
      };
      testMacId(params).then(resp => {
        if (!resp.data) {
          item.show = false;
          this.$refs["ruleForm"].validateField([prop]);
          setTimeout(function(){
            item.show = true;
          },5000);
        }
      });
    },
    //新增表单
    addForm() {
      const json = {
        type: "add",
        selectType: "single",
        renderList: [
          {
            id: "",
            component: "",
            mainKey: true
          }
        ]
      };
      this.ruleForm.formList.push(json);
    },
    //删除
    deleteItem(item, item1, index, index1) {
      if (item1.mainKey) {
        this.ruleForm.formList.splice(index, 1);
      } else {
        this.ruleForm.formList[index].renderList.splice(index1, 1);
      }
    },
    //选择不同类型
    changeType(item, index) {
      if (item.selectType === "double") {
        const json = {
          id: "",
          component: "",
          mainKey: false
        };
        this.ruleForm.formList[index].renderList.push(json);
      } else {
        const json = {
          id: "",
          component: "",
          mainKey: true
        };
        this.ruleForm.formList[index].renderList = [json];
      }
    },
    handleInitial() {}
  },
  created() {
    const pvmoduletypes = util.readSession("pvmoduletypes");
    this.typeList = pvmoduletypes;
  },
  mounted() {}
};
</script>

<style lang="scss" scoped>
@import "../style/index.scss";
</style>
