var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "categoryItem" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 10 } },
        _vm._l(_vm.cateList, function (item, index) {
          return _c("el-col", { key: index, attrs: { span: item.col } }, [
            _c("div", { staticClass: "item-square" }, [
              _c("p", { staticClass: "square-icon" }, [
                _c("span", {
                  class: ["iconfont icon-green", "icon-" + item.icon],
                }),
              ]),
              _c("div", { staticClass: "square-text" }, [
                _c("p", { staticClass: "text-unit" }, [
                  _vm._v(_vm._s(item.name) + " (" + _vm._s(item.unit) + ")"),
                ]),
                _c("p", { staticClass: "text-value" }, [
                  _vm._v(_vm._s(item.value)),
                ]),
              ]),
            ]),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }