<!--
 * @Author: sun.mengmeng
 * @Date: 2021-04-27 15:30:04
 * @LastEditors: pan.aiyan
 * @LastEditTime: 2021-09-08 14:39:50
 * @Description: file content
-->
<template>
  <div class="chart-all">
    <div
      class="all"
      :ref="chartId"
      :id="chartId"
      :style="{
        height: height,
        width: width,
      }"
    ></div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  name: "base-chart",
  props: {
    id: {
      type: String,
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "100%",
    },
    type: {
      type: String,
      default: "",
    },
    option: {
      // echar的图表配置
      type: Object,
    },
    lengendClick: {
      type: String,
      default: "",
    },
    chartData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      MyEcharts: null, // echar对象
      chartId: "", // id
      isNull: true,
    };
  },
  watch: {
    // 监听属性变化
    option: {
      handler(newVal, oldVal) {
        // 判断图表是否已经存在
        if (this.MyEcharts) {
          this.MyEcharts.resize();
          // 刷新图表
          if (newVal) {
            this.updateChart(newVal);
          } else {
            this.updateChart(oldVal);
          }
        } else {
          // 初始化图表
          this.initChart();
        }
      },
      deep: true, // 对象内部属性的监听，关键。
    },
  },
  beforeCreate() {},
  created() {
    // 如果没有id 那么随机生成
    this.chartId = this.id
      ? this.id
      : `echars-${Math.ceil(Math.random() * 100000)}-${new Date().getTime()}`;
  },
  mounted() {
    this.$nextTick(() => {
      // 初始化图标
      this.initChart();
    });
  },
  destroyed() {
    // 清理
    window.removeEventListener("resize", this.resizeChart);
    this.MyEcharts && this.MyEcharts.clear();
  },
  methods: {
    /**
     * @description: 初始化图表
     * @param {type}
     * @return:
     */
    initChart() {
      let _this = this;
      if (!this.$refs[this.chartId]) {
        return;
      }
      this.MyEcharts = echarts.init(this.$refs[this.chartId]);
      // 适用于大数据量的切换时图表绘制错误,先清空在重绘
      this.MyEcharts.clear();
      // 设置配置
      //设置echarts的色彩
      this.option.color = ["#92c354"];
      this.MyEcharts.setOption(this.option, true);

      // 窗口变动的时候页面也变动
      window.addEventListener("resize", this.resizeChart);

      //点击事件
      if (this.type === "bar") {
        this.MyEcharts.on("click", (params) => {
          this.$emit("handleClick", params);
        });
      }
      if (this.lengendClick) {
        this.MyEcharts.on("legendselectchanged", (obj) => {
          _this.$emit("lengendClick", obj);
        });
      }
    },
    /**
     * @description: 重置当前图表
     * @param {type}
     * @return:
     */
    resizeChart() {
      _.debounce(() => {
        this.MyEcharts && this.MyEcharts.resize();
      }, 100)();
    },
    /**
     * @description: 更新图表
     * @param {type}
     * @return:
     */
    updateChart(val) {
      this.MyEcharts.clear();
      this.MyEcharts.setOption(val, true);
    },
    province(val) {
      echarts.registerMap("jiangxi", val);
    },
  },
};
</script>
<style lang="scss" scoped>
.chart-all {
  height: 100%;
  width: 100%;
  position: relative;
  .all {
    height: 100%;
    width: 100%;
  }
}
</style>
